<template>
	<div v-if="show"> 
		<div class="userModel"> 
			<div class="cont">
				<div class="logo">
					<img class="img" src="https://cdn.dayujianli.com/static/miniapp/images/logo_full.png"/>
				</div> 
				<div class="text">为了更好的浏览体验，请先进行账户登陆</div>
				 
				<div class="action" v-if="userAgent=='wechat'">
					<div class="btn" hover-class="hover-class" hover-stay-time="50">
						微信帐号授权登陆 
						<button open-type="getUserInfo" @click="getUserProfile" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;"></button> 
					</div> 
				</div>
				<div class="formlogin" v-if="loginType=='mobile'">
					 <div class="form">
						 <div class="line">
							<Field type="text" v-model="mobile" label="手机号" style="border:1px solid #f5f5f5;" maxLength="11" colon label-width="50" placeholder="请输入手机号"/>
						 </div>
						 <div class="line">
							<Field type="text" v-model="smsCode" label="验证码" style="border:1px solid #f5f5f5;" maxLength="11" colon label-width="50" placeholder="请输入验证码">
							<template #button>
								 <div class="smsBtn" @click="smsSend()" v-if="smsLimit==0">
									 发送验证码
								 </div>
								 <div class="wait" v-if="smsLimit>0">
									 {{smsLimit}}秒可重发
								 </div>
							</template>
							 
							</Field>
						 </div> 
						 <div class="btns"> 
							<div class="btn" @click="mobileLogin()">确认登陆</div>
						</div>
					 </div> 
				</div>
				<div class="formlogin" v-if="loginType=='username'">
					 <div class="form">
						 <div class="line">
							<Field type="text" v-model="mobile" label="用户名" style="border:1px solid #f5f5f5;"   colon label-width="50" placeholder="请输入用户名"/>
						 </div>
						 <div class="line">
							<Field type="password" v-model="smsCode" label="密码" style="border:1px solid #f5f5f5;"   colon label-width="50" placeholder="请输入密码"/>
						 </div> 
						 <div class="btns"> 
							 <div class="btn" @click="usernameLogin()">确认登陆</div>
						</div>
					 </div> 
				</div>
				<div class="third" v-if="loginType=='wx'">
					<div class="btn" @click="wxLogin()">微信帐号授权登陆</div> 
				</div>
				<div class="third" v-if="loginType=='alipay'">
					<div class="btn" @click="alipayLogin()">支付宝帐号授权登陆</div> 
				</div>
				<div class="third" v-if="loginType=='douyin'">
					<div class="btn" @click="douyinLogin()">抖音帐号授权登陆</div> 
				</div>
				<div class="others">
					<div class="line">
						<div class="l"></div>
						<div class="ltext">其他登陆方式</div>
					</div>
					<div class="btns">
						<div class="btn" @click="loginTypeChange('mobile')" v-if="loginType!='mobile'">
							<div class="icon">
								<Icon name="phone" size="36"></Icon>
							</div>
							<div class="tip">手机</div>
						</div>
						<div class="btn" @click="loginTypeChange('username')" v-if="loginType!='username'">
							<div class="icon">
								<Icon name="manager" size="36"></Icon>
							</div>
							<div class="tip">帐号</div>
						</div>
						<div class="btn" @click="loginTypeChange('alipay')" v-if="loginType!='alipay'">
							<div class="icon">
								<Icon name="alipay" size="36" ></Icon>
							</div>
							<div class="tip">支付宝</div>
						</div>
						<div class="btn" @click="loginTypeChange('douyin')" v-if="loginType!='douyin'">
							<div class="icon">
								<Icon name="video" size="36"></Icon>
							</div>
							<div class="tip">抖音</div>
						</div>
						<div class="btn" @click="loginTypeChange('wx')" v-if="loginType!='wx'">
							<div class="icon">
								<Icon name="wechat" size="36"></Icon>
							</div>
							<div class="tip">微信</div>
						</div>
					</div> 
				</div>
				<div  class="no" @click="close">取消登陆</div>  
			</div> 
		</div>
	</div>
</template>

<script> 
	import Api from "@/service/api.js" 
	import { Toast,Icon,Field,PasswordInput,NumberKeyboard  } from 'vant';
	export default {
		components:{Icon,Field,PasswordInput,NumberKeyboard} ,
		name: 'login',
		props:{
			title:{	//显示的内容
				type: String,
				default: '为了更好的浏览体验，快去授权登录吧'
			},
			authorize:{	//是否授权登录
				type: Boolean,
				default: true
			},
			ad:{	//广告
				type: Boolean,
				default: false
			},
			btnText:{	//按钮文字
				type: String,
				default: '授权登录'
			},
			closeText:{	//关闭文笔
				type: String,
				default: '暂时不用'
			},
			show:{
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				userAgent:'other',
				isMobile:true,
				loginType:'mobile',
				smsLimit:0,
				smsTimer:null,
				mobile:'',
				smsCode:'',
				smsId:false 
			};
		},
		mounted(){
			 this.Init();
		},
		methods: {
			loginTypeChange(type){
				if(type=='wx'){
					if(this.thirdPlatform!=='wx'){
						Toast.fail("仅支持微信内使用");
						return false;
					}
				}
				if(type=='alipay'){
						if(this.thirdPlatform!=='alipay'){
						Toast.fail("仅支持支付宝内使用");
						return false;
					}
				}
				if(type=='douyin'){
						if(this.thirdPlatform!=='douyin'){
						Toast.fail("仅支持抖音内使用");
						return false;
					}
				}
				this.loginType = type;
			},  
			Init(){
				let state = this.$route.query.state;
				let code = this.$route.query.code;
				if(state=='IS_WX_LOGIN'){
					//进行微信授权login
					this.wxLoginDo(code); 
				}
				if(state=='GET_WX_OPENID'){
					//进行微信授权login
					this.wxGetOpenId(code); 
				}
				var ua = window.navigator.userAgent.toLowerCase();
				if (/micromessenger/.test(ua)) {
					this.loginType = 'wx';
					this.thirdPlatform = 'wx';
				}  
				if (/alipayclient/.test(ua)) {
					this.loginType = 'alipay';
					this.thirdPlatform = 'alipay'; 
				}
				if (/aweme/.test(ua)) {
					this.loginType = 'douyin';
					this.thirdPlatform = 'douyin'; 
				} 
			}, 
			close(){
				this.$emit('close');
			},
			async wxGetOpenId(code){
				let r = await Api.query("/open/user/wxopenid",{appId:Api.APPID,code:code});  
				if(r){
					this.$router.replace({path:this.$route.path});
					localStorage.setItem('USER_OPENID',r.openId);
					this.show = false;
				}
			},  
			async wxLoginDo(code){
				let r = await Api.query("/open/user/loginwx",{appId:Api.APPID,code:code});
				if(!r){
					Toast.fail('登陆失败');
					this.$router.replace({path:this.$route.path});
					console.log(this.$route);
				}else{
					localStorage.setItem('USER_OPENID',r.openId);
					localStorage.setItem('USER_TOKEN',r.token);
					localStorage.setItem('USER_INFO',JSON.stringify(r.user));
					this.$router.replace({path:this.$route.path});
					this.success();
					this.show = false;
				} 
			},  
			async wxLogin(){  
				let uri = encodeURIComponent(window.location.href);
				window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+Api.APPID+"&redirect_uri="+uri+"&response_type=code&scope=snsapi_userinfo&state=IS_WX_LOGIN#wechat_redirect"
			},
			async mobileLogin(){
				if(!this.smsId){
					Toast.fail('请先获取验证码');
					return false;
				}
				if(!(/^1[3-9][0-9]{9}$/.test(this.mobile))){
					Toast.fail('请输入正确的手机号码');
					return false;
				}
				if(this.smsCode.length<4){
					Toast.fail('请输入正确验证码');
					return false;
				}
				let r = await Api.query("/open/user/loginSms",{mobile:this.mobile,smsId:this.smsId,code:this.smsCode});
				if(r){
					localStorage.setItem('USER_TOKEN',r.token);
					localStorage.setItem("USER_INFO",r.user);
					Toast.success('登陆成功');
					this.$emit('close');
				}else{
					Toast.fail('登陆失败');
				}
			},
			async smsLimitTimer(){
				var that =this;
				if(this.smsLimit>0){
					this.smsLimit = this.smsLimit-1;
					setTimeout(function (){
						 that.smsTimer = that.smsLimitTimer();
					},1000);
				}else{
					clearTimeout(that.smsTimer);
				}
			},
			async smsSend(){ 
				if(!(/^1[3-9][0-9]{9}$/.test(this.mobile))){
					Toast.fail('请输入正确的手机号码');
					return false;
				}
				Toast.loading('请求中')
				let r = await Api.query("/open/sms/index",{mobile:this.mobile},true);
				Toast.clear();
				if(r){
					this.smsCode= '';
					this.smsId = r.smsId;
					this.smsLimit = 60;
					this.smsLimitTimer();
				}else{
					Toast.fail('请求错误');
				}
				
			},
			async success(){
				this.$emit('success');
			},
		
		}
	}
</script>

<style lang="less" scoped> 
	.others{
		float:left;
		width:100%;
		height:90px;
		margin-top:10px;
		.line{
			float:left;
			height:20px;
			width:100%;
			display: flex;
			align-items:center;
			justify-content: center;
			position:relative;
			.l{
				float:left;
				height:1px;
				background:#f5f5f5;
				width:180px;
			}
			.ltext{
				position:absolute;
				top:calc(50% - 10px);
				left:calc(50% - 45px);
				padding:0px 10px 0px 10px;
				height:20px;
				line-height:20px;
				text-align:center; 
				font-size:12px;
				color:#a4a4a4;
				background:#fff;
			}
		}
		.btns{
			float:left;
			width:100%;
			height:70px;
			padding:5px;
			display: flex;
			justify-content: center;
			.btn{
				float:left;
				width:calc(100% / 2); 
				height:60px;
				.icon{
					float:left;
					width:100%;
					height:36px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.tip{
					float:left;
					width:100%;
					height:24px;
					line-height:24px;
					text-align:center;
					font-size:12px;
				}
			} 
		}
	}
	.third{
		float:left;
		width:100%;
		padding:10px;
		display: flex;
		align-items:center;
		justify-content: center;
		.btn{
			float:left;
			width:75%;
			height:38px;
			line-height:38px;
			border-radius:19px;
			text-align:center;
			background:#1261ff;
			color:#fff;
			
			
		}
	} 
	.formlogin{
		float:left;
		width:100%;  
		display: flex;
		align-items: center;
		justify-content: center;
		.form{
			float:left;
			width:100%;
			padding:0px 10px 0px 10px;  
			.line{
				float:left;
				width:100%; 
				position:relative;
				margin-top:10px; 
			}
			.smsBtn{ 
				float:left;
				color:#1261ff;
				font-weight:bold;
			}
			.wait{
				color:#a5a5a5;
				float:left; 
			}
		}
		.btns{
			float:left;
			width:100%; 
			display: flex;
			align-items: center;
			justify-content: center;
			height:40px;
			padding:3px 20px 3px 20px;
			margin-top:10px;
			.btn{
				float:left;
				width:100%;
				height:36px;
				line-height:36px;
				text-align:center;
				color:#fff;
				background:#1261ff;
				border-radius:18px;
				font-size:12px;
			} 
			
		}

		
	}
	.action{
				width:100%;
				height:44px; 
				display:flex ;
				align-items: center;
				justify-content: center;
				.btn{
					position: relative;
					margin-top: 10px;
					color: #FFFFFF;
					width: 75%;
					line-height: 36px;
					border-radius: 36px; 
					background:#1261ff;
					text-align:center;
					align-items: center;
					justify-content: center;
					font-size:14px;
				}
			} 
	.no{
		float:left;
		width:100%;
		height:32px;
		color: #666;
		font-size:12px;
		text-align:center;
		line-height:32px; 
		margin-top:10px; 
	}
	.userModel{
		z-index: 999;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0,0,0,0.6); 
		display:flex;
		align-items: center;
		justify-content: center;
		.cont{
			float:left;
			width: 300px;
			padding: 15px 5px 5px 5px; 
			background-color: #FFFFFF;
			border-radius: 10px;
			position:relative;  
			.logo{
				position:absolute;
				top:-32px;
				height:64px;
				width:100%; 
				display:flex ;
				align-items:center;
				justify-content: center;
				.img{
					width: 64px;
					height: 64px; 
					border-radius:50%; 
				}
			} 
			.text{
				line-height: 24px;
				margin-top:36px;
				width:100%;
				text-align:center;
				font-size:12px;
			} 
		}
	}
	.num{
		font-size: 16px;
		font-weight: 700;
		color: #f8c93c;
		padding: 0 8px;
	}
	
	.adClass{
		animation: tiaobig 1.2s ease-in-out alternate infinite;
		// animation-iteration-count:1;
	}
	@keyframes tiaobig {
		25% {
			transform: scale(0.98);
		}
		50% {
			transform: scale(1.08);
		}
		75% {
			transform: scale(0.98);
		}
		100% {
			transform: scale(1.08);
		}
	}
</style>
