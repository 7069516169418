import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeIndex from '../pages/home/index.vue'
import TemplateIndex from '../pages/template/index.vue'
import TemplateDetail from '../pages/template/detail.vue'
import EditorIndex from '../pages/editor/index.vue'
import UserCvIndex from '../pages/user/cv/index.vue'
import UserIndex from '../pages/user/index.vue'
import UserFavIndex from '../pages/user/fav/index.vue'
import UserOrderIndex from '../pages/user/order/index.vue'
import VipIndex from '../pages/vip/index.vue'
import HelpIndex from '../pages/help/index.vue'
import CvDetail from "../pages/cv/detail.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homeindex',
    component: HomeIndex,
    meta:{title:'首页'}
  } ,
  {
    path: '/vip/index',
    name: 'vipindex',
    component: VipIndex,
    meta:{title:'VIP服务'}
  } ,
  {
    path: '/help/index',
    name: 'helpindex',
    component: HelpIndex,
    meta:{title:'帮助中心'}
  } 
  ,
  {
    path: '/cv/detail/:cvId',
    name: 'cvdetail',
    component: CvDetail,
    meta:{title:'简历查看'}
  } ,
  {
    path: '/template/index',
    name: 'templateindex',
    component: TemplateIndex,
    meta:{title:'模板列表'}
  } ,
  {
    path: '/template/detail/:templateId',
    name: 'templatedetail',
    component: TemplateDetail,
    meta:{title:'模板详情'}
  } ,
  {
    path: '/editor/:cvId',
    name: 'editorindex',
    component: EditorIndex,
    meta:{title:'简历编辑器'}
  } ,
  {
    path: '/user/index',
    name: 'userIndex',
    component: UserIndex,
    meta:{title:'用户中心'}
  } ,
  {
    path: '/user/cv/index',
    name: 'usercvIndex',
    component: UserCvIndex,
    meta:{title:'我的简历'}
  },
  {
    path: '/user/fav/index',
    name: 'userfavIndex',
    component: UserFavIndex,
    meta:{title:'我的收藏'}
  }  ,
  {
    path: '/user/order/index',
    name: 'userorderIndex',
    component: UserOrderIndex,
    meta:{title:'我的订单'}
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
      document.title = to.meta.title + " - 简历通"
  }
  next() 
})
export default router
