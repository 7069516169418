<template> 
	<div class="container">
		<NavBar
		title="简历列表"
		left-text="返回" 
		left-arrow
		@click-left="back()" 
		 
		/> 
		<Cvs :cvs="cvs" v-if="cvs" @reload="getCvs"></Cvs>
		<div class="page" v-if="maxPage>1">
			<div class="pre">
				<div  :class="page==1?'btn disBtn':'btn'" @click="pre()">上一页</div>
			</div>
			<div class="pageinfo">
				{{page}}/{{maxPage}} 页
			</div>
			<div class="next" @click="next()">
				<div :class="page==maxPage?'btn disBtn':'btn'">下一页</div>
			</div>
		</div>
		<Login :show="showLogin" @close="closeLogin" @success="successLogin"/> 
	</div>
	
</template> 
<script>
	import Login from '@/components/login/login.vue';
	import User from '@/service/user.js'  
	import Cvs from "../cvs.vue" 
	import Api from "@/service/api.js"
	import {NavBar } from "vant"
	export default {
		components:{Login,Cvs,NavBar},
		data() {
			return {
				userInfo:{},
				loginStatus:false,
				showLogin:false, 
				maxPage:0,
				page:1,
				pageSize:10,
				status:'1', 
				type:'',
				cvs:[],
				
			}
		},
		created:function(){
			this.Init();
		},
		methods: { 
			async Init(){
				let l = await User.checkLogin();
				if(!l){
					this.loginStatus = false;
					this.userInfo = {};
				}else{
					this.getCvs();
					this.loginStatus=true;
					this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
				}
			},
			async back(){
				this.$router.go(-1)
			},
			async typeChange(a){
				this.type = a;
				this.page = 1;
				this.maxPage = 1;
				this.getCvs();
			},
			async onLoad(options){
				if(options.type){
					this.type = options.type;
				}
			}, 
			async pre(){
				if(this.page>1){
					this.page = this.page-1;
					this.getCvs();
				}else{
					console.log('第一页');
				} 
			},
			async next(){
				if(this.page<this.maxPage){
					this.page = this.page+1;
					this.getCvs();
				}else{
					console.log('最后一页');
				} 
			},
			async getCvs(){
				let result = await Api.query('/open/cv/index',{page:this.page,page_size:this.pageSize}); 
				if(result){
					this.cvs = result.data;
					this.maxPage = result.last_page; 
				}
			}, 
			async login(){ 
				this.showLogin = true;
			},
			async closeLogin(){
				this.showLogin =false;
			},
			async successLogin(){
				this.showLogin =false;
				this.loginStatus=true;
				this.userInfo = uni.getStorageSync('USER_INFO');
				this.getCvs();
			}
		}
	}
</script>
<style  lang="less" scoped>
	@import "../user.less";
	.tabs{
		float:left;
		width:100%;
		height:40px;
		background:#fff;
		border-radius:10px 10px 0px 0px;
		border-bottom:1px solid #F8F8F8;
		.tab{
			float:left;
			width:calc(100% / 3);
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
		}
		.active{
			color:#007AFF;
		} 
	}
	.page{
		float:left;
		width:100%;
		height:50px;
		padding:5px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top:20px;
		margin-bottom:80px;
		
		.pre{
			float:left;
			width:20%;
			height:40px;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				background:#007AFF;
				color:#fff;
				text-align: center;
				font-size:12px;
				height:30px;
				line-height:30px;
				font-weight:bold;
				width:90px;
				border-radius:2px;
			}
		}
		.pageinfo{
			float:left;
			width:33%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:12px;
			color:#808080;
		}
		.next{
			float:left;
			width:20%;
			height:40px;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				background:#007AFF;
				color:#fff;
				text-align: center;
				font-size:12px;
				height:30px;
				line-height:30px;
				font-weight:bold; 
				width:90px;
				
				border-radius:2px;
				
			}
		}
		.disBtn{
			background-color:#ccc!important;
			color:#606266!important;
		}
	}
	
	
</style>
 
