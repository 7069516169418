<template>
	<div class="editor"> 
		<div class="title">
			<div class="text" @click="titleEditOpen()">
				{{section.title}}
				<div class="icon">
					<Icon name="edit" color="#1261ff"></Icon>
				</div>
			</div> 
			<div class="sure" @click="close()" style="color:#1261ff;"> 
				确定
			</div> 
		</div>
		<div  class="main"> 
			<div class="section">
				<Baseinfo v-if="section.type=='baseinfo'" @update="update" :section="section"></Baseinfo>
				<div  class="lists" v-if="section.type!='baseinfo'">
					<div class="list" v-for="data,di in section.data" :key="di">
						<div class="info"> 
								<div class="order"  @click="dataEditOpen(di)">
									{{di+1}}/{{section.data.length}}
								</div>
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='job'">
									{{data.company}} - {{data.job}}
								</div> 
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='project'">
									{{data.project}}
								</div>
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='edu'">
									{{data.degree}} - {{data.school}}
								</div> 
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='hope'">
									{{data.type}} - {{data.job}}
								</div> 
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='skill'">
									{{data.skill}} - {{data.level}}
								</div> 
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='self'">
									{{data.content}}
								</div>
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='hobby'">
									{{data.content}}
								</div>
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='prize'">
									{{data.prize}}
								</div>
								<div class="data" @click="dataEditOpen(di)" v-if="section.type=='single'">
									{{data.content}}
								</div> 
								<div class="icon"  @click="dataEditOpen(di)">
									<Icon name="arrow"></Icon>
								</div>
						</div>
						<div class="actions">
							<div class="btn">
								<Icon name="edit" size="18" @click="dataEditOpen(di)" color="#1261ff"></Icon>
							</div>  
							<div class="btn">
								<Icon name="arrow-up" size="18" @click="dataUp(di)" :color="di==0?'#a5a5a5':'#000'"></Icon>
							</div>
							<div class="btn">
								<Icon name="arrow-down" size="18" @click="dataDown(di)" :color="di==section.data.length-1?'#a5a5a5':'#000'"></Icon>
							</div>
							<div class="btn">
								<Icon name="cross" size="18" @click="dataDelete(di)" color="red"></Icon>
							</div> 
						</div> 
					</div> 
					<div class="add">
						<div class="btn" @click="dataEditOpen()">
							增加一条
						</div>
					</div>
				</div>
			</div> 
		</div>
		<Popup ref="dataedit" position="bottom"   v-if="dataedit"   v-model="dataedit" :is-mask-click="false">
			<Edit :origindata="data" :isAdd="isAdd" :type="dataType"  v-if="dataedit" @close="dataEditClose" @sure="dataEditSure"></Edit>
		</Popup>
		<Popup position="center"    v-if="titleEditShow" v-model="titleEditShow">
			<div class="titleEdit">
				<Field   @input="titleChange" :autofocus="true"  style="border-radius:5px;border:1px solid #f5f5f5;max-width:210px;" :value="section.title" placeholder="请输入模块名称"/>  
			</div>
		</Popup>
	</div>
</template>

<script> 
	import Api from '@/service/api.js' 
	import Baseinfo from "./baseinfo.vue"
	import Edit from "./edit.vue"
	import {Icon,Field,Popup,Toast,Dialog} from "vant"
	export default {
		components:{Popup,Field,Icon,Baseinfo,Edit},
		name: 'sections',
		props:{ 
			section:{
				type:Object,
				default:{}, 
			}
		}, 
		data() {
			return {
				data:false,
				dataType:'',
				dataIndex:false,
				dataedit:false,
				isAdd:false,
				titleEditShow:false,
			};
		},
		mounted:function(){
			this.Init();
		},
		methods: {
			async Init(){ 
			},
			close(){
				this.$emit('close');
			},
			titleEditOpen(){ 
				this.titleEditShow = true;
			}, 
			async dataEditOpen(index=false){
				var that =this;
				if(index===false){
					that.data = {};
					that.dataIndex = false;
					that.isAdd = true;
					that.dataType = that.section.type;
					 
				}else{
					this.dataIndex = index;
					this.dataType = this.section.type;
					this.isAdd= false;
					this.data = this.section.data[index];
				} 
				this.dataedit = true; 
			},
			async dataEditClose(){
				this.dataIndex  = false;
				this.data = {};
				this.dataType = '';
				this.dataedit = false;
				this.isAdd = false; 
			}, 
			async dataEditSure(newData){
				if(this.dataIndex!==false){
					this.section.data[this.dataIndex] = newData;
				}else{
					this.section.data.push(newData);
				}
				this.dataEditClose(); 
				this.update();
			},
			async dataUp(index){
				if(index!=0){
					console.log('up');
					let data = JSON.parse(JSON.stringify(this.section.data));
					let newData = JSON.parse(JSON.stringify(data[index-1]));
					data.splice(index+1,0,newData);
					data.splice(index-1,1);
					this.section.data = data;
					this.update();
				}
			},
			async dataDown(index){
				if(index!=this.section.data.length-1){
					console.log('down');
					let data = JSON.parse(JSON.stringify(this.section.data));
					let newData = JSON.parse(JSON.stringify(data[index]));
					data.splice(index+2,0,newData);
					data.splice(index,1);
					this.section.data = data;
					this.update();
				}
			},
			async dataDelete(index=false){
				let that = this; 
				Dialog.confirm({
					title: '温馨提示',
					message: '删除后无法恢复，请问是否删除？', 
				}).then(function(res){
					if (res) {
						let section = JSON.parse(JSON.stringify(that.section));
						if(index===false){ 
							section.data = {};
						}else{
							section.data.splice(index,1);
						}
						that.section = section;
						that.update();
					} 
				});
				
			},
			async titleChange(e){ 
				let v = e;
				let section = JSON.parse(JSON.stringify(this.section));
				section.title = v; 
				this.update(section);
			},
			async update(section=false){
				if(section===false){
					this.$emit('sure',JSON.parse(JSON.stringify(this.section)));	
				}else{
					this.$emit('sure',JSON.parse(JSON.stringify(section)));
				}
				
			}, 
		}
	}
</script> 
<style lang="less" scoped> 
 .editor{
		float:left; 
		background:#fff;
		width:100%;
		height:500px; 
		position:relative;
		border-radius:10px 10px 0px 0px; 
		.titleEdit{
			float:left;
			width:240px;
			height:60px;
			display: flex;
			align-items: center;
			justify-content: center; 
		}
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			 border-radius:10px 10px 0px 0px;
			 display: flex;
			 align-items:center;
			 justify-content: center;
			 .text{
				 float:left;
				  height:40px;
				  line-height:40px;
				  padding:0px 30px 0px 10px;
				  position:relative;
				  color:#1261ff;
				  font-weight:bold;
				  .icon{
					  position:absolute;
					  right:0px;
					  top:0px;
					  width:20px;
					  height:40px;
					   display: flex;
					align-items:center;
					justify-content: center;
				  }
			 }
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 40px); 
			.section{
				float:left;
				width:100%;
				height:100%; 
				.lists{
					float:left;
					width:100%;
					padding:8px;
					background:#f5f5f5; 
					height:100%;
					overflow-y: auto;
					box-sizing: border-box;
						padding-bottom:10px;
					.list{
						float:left;
						width:100%;
						background:#fff; 
						border-radius:5px; 
						margin-bottom:10px;
						.info{
							float:left;
							width:100%;
							.order{
								float:left;
								width:60px;
								height:50px;
								line-height:50px;
								text-align:center;
								color:#a5a5a5;
							}
							.data{
								float:left;
								width:calc(100% - 120px);
								height:50px;
								line-height:50px;
								font-size:14px;
								font-weight:bold;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								padding-left:10px;
								color:#1261ff;
							} 
							.icon{
								float:left;
								width:60px;
								height:50px;
								display: flex;
								align-items: center;
								justify-content: center;
								
							} 
							
						}
						.actions{
							float:left;
							width:100%;
							border-top:1px solid #f5f5f5; 
							.btn{
								float:left;
								width:calc(100% / 4); 
								height:30px;
								display: flex;
								align-items: center;
								justify-content: center;
							}  
						}
					}
					.add{
						float:left;
						width:100%;
						height:50px;
						display: flex;
						align-items: center;
						justify-content: center;
						.btn{
							float:left;
							width:240px;
							height:32px;
							line-height:32px;
							text-align:center;
							background:#1261ff;
							color:#fff;
							border-radius:16px;
						}
					}
				}
			}
		}
		 
	}
</style>
