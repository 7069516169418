<template>
	<div class="edit">
		<div class="title">
			<template v-if="data">
				修改此条
			</template>
			<template v-if="!data">
				新增一条
			</template>
			<div class="close" @click="close()"> 
				取消
			</div> 
			<div class="sure" @click="sure()" style='color:#1261ff;'> 
				保存
			</div>
		</div>
		<div class="row"> 
			<div class="full">
				 <Field :value="data.name" label="标签："  @input="e=>{inputChange('name',e)}"></Field>
			</div> 
		</div> 
		<div class="row"> 
			<div class="full">
				 <Field :value="data.value" label="内容："  @input="e=>{inputChange('value',e)}"></Field>
			</div> 
		</div> 
	</div>
</template>

<script> 
	import Api from '@/service/api.js'
	import Utils from "../utils.js"
	import {Field} from "vant"
	export default {
		components:{Field},
		name: 'sections',
		props:{ 
			origindata:{
				type:Object,
				default:{}
			},
			isAdd:{
				type:Boolean,
				default:true
			} 
		}, 
		computed:{
			 
		},
		data() {
			return { 
				data:false,
				options:[]
			};
		},
		mounted:function(){
			this.Init(); 
		},
		methods: {
			async Init(){
				if(this.isAdd){
					 this.data = {
						 name:'姓名',
						 value:'简小通'
					 };
				}else{
					this.data = JSON.parse(JSON.stringify(this.origindata));
				}
				
				 
			},
			close(){
				this.$emit('close');
			}, 
			async inputChange(key,value){ 
				this.data[key] = value; 
			},  
			async sure(){  
				this.$emit('sure',this.data);
			}, 
		}
	}
</script> 
<style lang="less" scoped> 
 .edit{
		float:left; 
		background:#fff;
		width:100%;
		height:400px; 
		border-radius:10px 10px 0px 0px;
		.bottom{
			float:left;
			width:100%;
			height:40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top:1px solid #f5f5f5;
			.btn{
				float:left;
				width:120px;
				height:32px;
				line-height:32px; 
				background:#f5f5f5;
				border-radius:5px;
				border:1px solid #6A6A6A;
				.icon{
					float:left;
					width:42px;
					height:32px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.tip{
					float:left;
					width:calc(100% - 42px);
					height:32px;
					line-height:32px; 
				}
			} 
			.reset{
				margin-left:40px;
				
			}
		}
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			 border-radius:10px 10px 0px 0px;
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.row{
			float:left;
			width:100%;
			border-bottom:1px solid #f5f5f5;  
			.full{
				float:left;
				width:100%; 
			}  
		} 
	}
</style>
