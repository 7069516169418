<template> 
	<div class="container"> 
			<NavBar
		title="我的订单"
		left-text="返回" 
		left-arrow
		@click-left="back()" 
		 
		/> 
		<div class="tasks">
			<div class="content"> 
				<div class="no" v-if="orders.length==0">
					暂无任何订单记录
				</div> 
				<div class="items" v-if="orders.length>0"> 
					<div v-for="order,index in orders" class="item" :key="index">
						<div class="ticon">
							<uni-icons type="vip-filled" size="24" v-if="order.orderType=='vip'"></uni-icons>
							<img :src="order.productImg" v-if="order.orderType=='cv'" class="img"/>
						</div>
						<div class="info">
							<div class="name">
								{{order.productName}}
							</div>
							<div class="date">
								编号:{{order.orderId}}
							</div> 
							<div class="status1" v-if="order.state==1">
								 已支付
							</div>
							 <div class="status0" v-if="order.state==0">
							 	 未支付
							 </div> 
							 <div class="status-1" v-if="order.state==-1">
							 	 异常
							 </div>
						</div> 
					</div>
				</div> 
			</div> 
			
		</div>
		<div class="page" v-if="maxPage>1">
			<div class="pre">
				<div  :class="page==1?'btn disBtn':'btn'" @click="pre()">上一页</div>
			</div>
			<div class="pageinfo">
				{{page}}/{{maxPage}} 页
			</div>
			<div class="next" @click="next()">
				<div :class="page==maxPage?'btn disBtn':'btn'">下一页</div>
			</div>
		</div> 
		<Login :show="showLogin" @close="closeLogin" @success="successLogin"/> 
	</div> 
</template> 
<script>
	import Bottom from '@/components/bottom/bottom.vue'
	import Login from '@/components/login/login.vue';
	import User from '@/service/user.js'  
	import Api from "@/service/api.js"
	import {Icon,Toast,NavBar} from "vant" 
	export default {
		components:{Bottom,Login,Icon,NavBar},
		data() {
			return {
				userInfo:{},
				loginStatus:false,
				showLogin:false,
				orders:[],
				maxPage:0,
				page:1,
				pageSize:10,
				status:'1', 
				type:'',
				
			}
		},
		created:function(){
			this.Init();
		},
		methods: {
			async Init(){
				let l = await User.checkLogin();
				if(!l){
					this.loginStatus = false;
					this.userInfo = {};
				}else{
					this.getOrders();
					this.loginStatus=true;
					this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
				}
			},
			
				async back(){
				this.$router.go(-1)
			},
			async typeChange(a){
				this.type = a;
				this.page = 1;
				this.maxPage = 1;
				this.getOrders();
			},
			async onLoad(options){
				if(options.type){
					this.type = options.type;
				}
			},
			async pre(){
				if(this.page>1){
					this.page = this.page-1;
					this.getOrders();
				}else{
					console.log('第一页');
				} 
			},
			async next(){
				if(this.page<this.maxPage){
					this.page = this.page+1;
					this.getOrders();
				}else{
					console.log('最后一页');
				} 
			},
			async getOrders(){
				let result = await Api.query("/open/order/index",{pageNo:this.page,pageSize:this.pageSize});
				if(result){ 
					this.orders = result.data;
					this.maxPage = result.last_page; 
				}
			},
			async viewTask(type,id){
				let url = '';
				switch(type){
					case "normal":
						url = '/pages/ocr/normal?id='+id;
					break;
					case "hand":
						url = '/pages/ocr/hand?id='+id;
					break;
					case "table":
						url = '/pages/ocr/table?id='+id;
					break;
				}
				uni.navigateTo({
					url:url
				})
			},
			async login(){
				//let l = await User.checkLogin();
				this.showLogin = true;
			},
			async closeLogin(){
				this.showLogin =false;
			},
			async successLogin(){
				this.showLogin =false;
				this.loginStatus=true;
				this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
				this.getOrders();
			} 
		}
	}
</script>
<style  lang="less" scoped>
	@import "../user.less";
	.page{
		float:left;
		width:100%;
		height:50px;
		padding:5px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top:20px;
		margin-bottom:80px;
		
		.pre{
			float:left;
			width:20%;
			height:40px;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				background:#007AFF;
				color:#fff;
				text-align: center;
				font-size:12px;
				height:30px;
				line-height:30px;
				font-weight:bold;
				width:90px;
				border-radius:2px;
			}
		}
		.pageinfo{
			float:left;
			width:33%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:12px;
			color:#808080;
		}
		.next{
			float:left;
			width:20%;
			height:40px;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				background:#007AFF;
				color:#fff;
				text-align: center;
				font-size:12px;
				height:30px;
				line-height:30px;
				font-weight:bold; 
				width:90px;
				
				border-radius:2px;
				
			}
		}
		.disBtn{
			background-color:#ccc!important;
			color:#606266!important;
		}
	}
	.tasks{
		float:left;
		width:100%;
		min-height:calc(100vh - 120px);
		padding:10px; 
		.tabs{
			float:left;
			width:100%;
			height:40px;
			background:#fff;
			border-radius:10px 10px 0px 0px;
			border-bottom:1px solid #F8F8F8;
			.tab{
				float:left;
				width:calc(100% / 2);
				height:40px;
				line-height:40px;
				text-align:center;
				font-size:14px;
				color:#007AFF;
			}
			.active{
				color:#fff;
				background:#007AFF;
			}
			.tab:first-child{
				border-radius:10px 0px 0px 0px;
			}
			.tab:last-child{
				border-radius:0px 10px 0px 0px;
			}
		}
		.content{
			float:left;
			width:100%;
			.tip{
				float:left;
				width:100%;
				height:20px;
				font-size:12px;
				color:#8F939C;
				text-align:center;
			}
			.no{
				float:left;
				width:100%;
				height:140px;
				line-height:140px;
				text-align: center;
			}
			
			.items{
				float:left;
				width:100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-flow:row wrap;
				
				background:#fff;
				.item:first-child{
					border:none;
				}
				.item{
					float:left;
					width:100%;
					height:70px;
					padding:10px 5px 10px 5px;
					border-top:1px solid #F8F8F8;
					.ticon{
						float:left;
						width:50px;
						display: flex;
						align-items: center;
						justify-content: center;
						height:50px;
						.img{
							width:50px;
							height:50px;
						}
					}
					.info{
						float:left;
						width:calc(100% - 110px);
						height:50px;
						padding-left:10px;
						.name{
							float:left;
							height:24px;
							line-height:24px;
							font-size:14px;
							font-weight:bold;
							width:100%;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.status0{
							float:left;
							width:90px;
							height:26px;
							line-height:26px;
							font-size:12px;
							color:#F0AD4E;
						}
						.status1{
							float:left;
							width:90px;
							height:26px;
							line-height:26px;
							font-size:12px;
							color:#18BC37;
						}
						.status-1{
							float:left;
							width:90px;
							height:26px;
							line-height:26px;
							font-size:12px;
							color:#DD524D;
						}
						.date{
							float:left;
							width:calc(100% - 90px);
							height:26px;
							line-height:26px;
							font-size:12px;
							color:#606266;
						}
					}
					.actions{
						float:left;
						width:50px;
						height:50px;
						background-color: #007AFF;
						color:#fff;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius:2px;
						.img{
							width:30px;
							height:30px;
						}
					}
					 
				}
			}
		}
	}
	
</style>
 
