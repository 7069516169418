<template>
	<div class="imagecropper" v-if="show"> 
		<div class="bottom"> 
			<div class="actions"> 
				<div class="close" @click="cropClose()">关闭</div> 
				<div class="sure" @click="cropEnd()">确定</div>
			</div> 
		</div> 
		<div class="cropContainer">
			<div :style="'width:'+imageWidth+'px;height:'+imageHeight+'px;'" class="box">
				<div class="imgbox">
					<img :src="url" class="img"  :style="'width:'+imageWidth+'px;height:'+imageHeight+'px;'"/>
				</div>
			
				<div class="crop"  :data-scale="scale" :data-wh="wh"  :crop="{boxLeft:boxLeft,boxTop:boxTop,boxWidth:boxWidth,boxHeight:boxHeight}" :top="boxTop" :width="boxWidth"  :data-width="imageWidth" :data-height="imageHeight" :style="'width:'+boxWidth+'px;height:'+boxHeight+'px;left:'+boxLeft+'px;top:'+boxTop+'px;'">
					<div class="move" :catchttap="true" @touchmove.stop.prevent="" @touchstart="moveTouchStart" @touchmove="moveTouchMove" @touchend="moveTouchEnd">
						<div class="line" v-for="l,li in lines" :key="li"></div> 
					</div>
					<div class="resize" :catchttap="true" @touchmove.stop.prevent=""  @touchstart="resizeTouchStart" @touchmove="resizeTouchMove" @touchend="resizeTouchEnd">
						<img src="https://cdn.tutuguai.com/miniapp/icons/resize.png" class="img" mode="aspectFill"/>
					</div>
				</div>
				
			</div> 
		</div>
	
	</div>
</template>

<script> 
	import {Toast} from "vant"
	export default {
		name: 'ImageCropper',
		props:{ 
		},
		data() { 
			return {
				originWidth:100,
				originHeight:100,
				targetWidth:0,
				targetHeight:0,
				boxWidth:100,
				boxHeight:100,
				boxTop:0,
				boxLeft:0,
				scale:1,
				width:100,
				height:100,
				url:false, 
				windowWidth:0,
				windowHeight:0,
				show:false, 
				imageWidth:0,
				imageHeight:0,
				lines:[0,1,2,3,4,5,6,7,8],
				size:'diy',
				wh:false,
				mouse:{
					x:0,y:0
				},
				sizes:{
					'diy':{
						name:'自由',
						w:false,
						h:false, 
					},
					'fix':{
						name:'固定',
						w:false,
						h:false,  
					},
					'1:1':{
						name:'1:1',
						w:1,
						h:1, 
					},
					'2:3':{
						name:'2:3',
						w:2,
						h:3, 
					},
					'3:2':{
						name:'3:2',
						w:3,
						h:2, 
					},
					'3:4':{
						name:'3:4',
						w:3,
						h:4, 
					},
					'4:3':{
						name:'4:3',
						w:4,
						h:3, 
					},
					'9:16':{
						name:'9:16',
						w:9,
						h:16, 
					},
					'16:9':{
						name:'16:9',
						w:16,
						h:9, 
					},
				}
			};
		},
		mounted(){  
		},
		methods: {
			async resizeTouchStart(e){ 
				 e.stopPropagation();
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				this.mouse = {
					x:clientX,
					y:clientY,
				}
				return false;
			},
			async  resizeTouchMove(e){
				 e.stopPropagation();
				//计算差值
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				let dx = clientX - this.mouse.x;
				let dy = clientY - this.mouse.y;
				let width = this.boxWidth+dx;
				//let height = this.boxHeight+dy; 
				let height = width/this.wh;
				if(width>=0&&height>=0&&this.boxLeft+width<this.imageWidth&&this.boxTop+height<this.imageHeight){
					this.boxUpdate({width:width,height:height});
				}
				
				this.mouse = {
					x:clientX,
					y:clientY,
				}
				return false;
			},
			async  resizeTouchEnd(e){ 
				 e.stopPropagation();
				//计算差值
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				let dx = clientX - this.mouse.x;
				let dy = clientY - this.mouse.y;
				let width = this.boxWidth+dx;
				let height = width/this.wh;
				if(width>=0&&height>=0&&this.boxLeft+width<this.imageWidth&&this.boxTop+height<this.imageHeight){
					this.boxUpdate({width:width,height:height});
				}
				this.mouse = {
					x:0,
					x:0,
				}
				
				return false;
			},
			
			async moveTouchStart(e){
				 e.stopPropagation();
				console.log(e);
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				this.mouse = {
					x:clientX,
					y:clientY,
				}
				return false;
			},
			async  moveTouchMove(e){
				 e.stopPropagation();
				//计算差值
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				let dx = clientX - this.mouse.x;
				let dy = clientY - this.mouse.y;
				let left = this.boxLeft+dx;
				let top = this.boxTop+dy; 
				if(left>=0&&top>=0){
					this.boxUpdate({left:left,top:top});
				}
				
				this.mouse = {
					x:clientX,
					y:clientY,
				}
				
				return false;
			},
			async  moveTouchEnd(e){ 
				 e.stopPropagation();
				//计算差值
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				let dx = clientX - this.mouse.x;
				let dy = clientY - this.mouse.y;
				let left = this.boxLeft+dx;
				let top = this.boxTop+dy;
				if(left>=0&&top>=0){
					this.boxUpdate({left:left,top:top});
				}
				this.mouse = {
					x:0,
					x:0,
				}
				return false;
			},
			sizeChange(v){
				this.size = v;
				let boxWidth = 0;
				let boxHeight = 0;
				let boxLeft = 0;
				let boxTop = 0;
				let wh = false;
				let hengtu = false;
				if(this.originHeight<this.originWidth){
					hengtu = true;
				}
				console.log(hengtu);
				let size = this.sizes[v];
				if(v=='diy'){
					boxWidth = this.originWidth*this.scale;
					boxHeight = this.originHeight*this.scale;
					wh =false;
				}else if(v=='fix'){
					boxWidth = this.targetWidth*this.scale;
					boxHeight = this.targetHeight*this.scale;
					wh = boxWidth/boxHeight;
				}else{ 
					var tboxWidth = this.originWidth;
					var tboxHeight = tboxWidth*(size.h/size.w);
					if(tboxHeight>this.originHeight){
						tboxHeight = this.originHeight;
						tboxWidth = tboxHeight/(size.h/size.w);
					} 
					boxWidth = tboxWidth*this.scale;
					boxHeight = tboxHeight*this.scale;
					wh = size.w/size.h;
				} 
				this.boxWidth = boxWidth;
				this.boxHeight = boxHeight;
				this.boxLeft = (this.originWidth*this.scale-this.boxWidth)/2;
				this.boxTop = (this.originHeight*this.scale-this.boxHeight)/2;
				this.wh = wh;
			},
			boxUpdate(data){
				if(data.width){
					this.boxWidth = data.width;
				}
				if(data.height){
					this.boxHeight = data.height;
				}
				if(data.left){
					this.boxLeft = data.left;
				}
				if(data.top){
					this.boxTop = data.top;
				}
			},
			async crop(url,targetWidth,targetHeight){
				var that =this;
				this.url =url;  
				var image = new Image();
				image.src = this.url;
				image.onload = function() { 
					that.originWidth = image.width;
					that.originHeight =image.height; 
					if(targetWidth>that.originWidth||targetHeight>that.originHeight){
							Toast.fail('图片尺寸过小');
							return false;
					}
					that.targetWidth = targetWidth;
					that.targetHeight = targetHeight;
					that.Init();
				};  
				 
			},
			cropClose(){
				this.show = false;
			},
			cropEnd(){
				//计算剪裁框的x坐标、y坐标
				let width = this.boxWidth/this.scale;
				let height = this.boxHeight/this.scale;
				let x =this.boxLeft/this.scale;
				let y = this.boxTop/this.scale; 
				this.$emit('cropEnd',{url:this.url,naturalWidth:this.originWidth,naturalHeight:this.originHeight,width:width,height:height,x:x,y:y});
				this.show =false;
			}, 
			Init(){ 
				//计算整个画面高度和宽度
				//let info = uni.getSystemInfoSync();
				let info = {windowHeight:window.innerHeight,windowWidth:window.innerWidth}
				this.windowWidth = info.windowWidth;
				this.windowHeight = info.windowHeight;  
				var twidth = this.windowWidth;
				var theight = this.windowHeight-100;
				let scale = 1;
				if(this.originWidth*scale>twidth||this.originHeight*scale>theight){
					//进行scale计算,判断横向铺满，图片是否超出高度
					scale =twidth/this.originWidth;
					if(scale*this.originHeight>theight){
						scale = theight/this.originHeight;
					} 
				} 
				this.scale = scale; 
				//计算图片高度和宽度
				this.imageWidth = this.originWidth*this.scale;
				this.imageHeight = this.originHeight*this.scale; 
				//计算剪裁框的高度、宽度
				this.boxWidth = this.targetWidth*this.scale;
				this.boxHeight = this.targetHeight*this.scale;
				this.wh = this.boxWidth/this.boxHeight;
				this.size = 'fix';
				//计算剪裁框的top、lkeft
				this.boxLeft = (this.imageWidth-this.boxWidth)/2;
				this.boxTop = (this.imageHeight-this.boxHeight)/2;
				this.show = true;
			},
		}
	}
</script>

<style lang="less" scoped>
	.imagecropper{
		position: fixed;
		left:0px;
		top:0px;
		z-index:99999;
		width:100%;
		height:100vh;
		background:#fff;
		.cropContainer{
			float:left;
			width:100%;
			height:calc(100% - 40px);
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center; 
			
			 .box{
				position:relative;
				float:left; 
				.imgbox{
					float:left;
					width:100%;
					height:100%;
					display: flex;
					align-items: center;
					justify-content: center;
					background:#fff;
				} 
				.crop{
					position:absolute; 
					border:1px solid #fff; 
					border-left:none; 
					border-bottom:none;
					//background:rgba(0,0,0,0.6);
					box-shadow: rgba(33,33,33,0.8) 0px 0px 0px 0px, rgba(33,33,33,0.8) 0px 0px 0px 5000px;
					.move{
						float:left;
						z-index:1; 
						width:100%;
						height:100%;
						pointer-events: initial;
						.line{
							float:left;
							width:calc(100% / 3);
							height:calc(100% /3); 
							border-left:1px solid #fff;
							border-bottom:1px solid #fff;
						}
						
					}
					.resize{
						position: absolute;
						right:-8px;
						bottom:-8px;
						//border-right:4px solid #007aff;
						//border-bottom:4px solid #007aff;
						//width:20%;
						//height:20%;
						width:20px;
						height:20px;
						background:#1261ff;
						z-index:99;
						pointer-events: initial; 
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius:50%;
						.img{
							width:10px;
							height:10px;
						}
					}
				}
			 }
			
		} 
	} 
	 .bottom{
		 float:left;
		 width:100%;
		 height:40px; 
		 background:#fff;
		  
		 .actions{
			 float:left;
			 width:100%;
			 height:40px;
			 .sure{
			 	float:left;
				width:50%; 
			 	height:40px;
				line-height:40px;
			 	text-align:center;
			 	background:#007aff;
			 	color:#fff; 
			 	text-align:center;
			 }
			 .close{
				float:left;
				width:50%; 
				height:40px;
				line-height:40px;
				text-align:center; 
				color:#007aff; 
				text-align:center;
			 }
		 }
	 }
	
</style>
