<template>
	<div class="editor" v-if="cv"> 
		<div class="title">
			 生成 下载 发送
			<div class="sure" @click="close()" style="color:#1261ff;">
				<Icon name="cross" size="22" style="font-weight:bold;"></Icon> 
			</div> 
		</div>
		<div  class="main">
			<div class="types">
				<div class="type">
					<div class="c" @click="image()">
						<div class="top" style="background:linear-gradient(90deg,#a986ff 0,#9861ff 100%);">
							<div class="icon">
								<img class="img" src="@/assets/icon/image.png" mode="scaleToFill">
							</div>
							<div class="name">
								图片
							</div> 
						</div> 
						<div class="tip">
							简历图片
						</div>
					</div> 
				</div>
				<div class="type">
					<div class="c" @click="pdf()">
						<div class="top" style="background:linear-gradient(149deg,#ffa98f 0,#ff6464 100%);">
							<div class="icon">
								<img class="img" src="@/assets/icon/pdf.png" mode="scaleToFill">
							</div>
							<div class="name">
								PDF
							</div> 
						</div>
						<div class="tip">
							支持下载/打印
						</div>
					</div> 
				</div>
				<div class="type">
					<div class="c" @click="miniapp()">
						<div class="top" style="background:linear-gradient(139deg,#41bfff 0,#5f90ff 100%);">
							<div class="icon">
								<img class="img" src="@/assets/icon/miniapp.png" mode="scaleToFill">
							</div>
							<div class="name">
								网页版
							</div>
						</div>
						<div class="tip">
							在线转发
						</div>
					</div> 
				</div>
			</div> 
		</div> 
		<Popup ref="ordercv" position="bottom" v-model="ordercv" v-if="ordercv">
			<OrderCv :cvId="cvId" v-if="ordercv"   @close="orderCvClose" @sure="orderCvSure"></OrderCv>
		</Popup>
	</div>
</template>

<script> 
	import Api from '@/service/api.js'  
	import User from '@/service/user.js' 
	import OrderCv from "@/components/order/cv.vue"
	import {Popup,Icon,Toast,ShareSheet} from "vant"
	export default {
		components:{OrderCv,Popup,Icon,ShareSheet},
		name: 'sections',
		props:{ 
			cvId:{
				type:Number,
				default:0, 
			}
		}, 
		data() {
			return {
				cv:false, 
				ordercv:false,
			};
		},
		mounted:function(){
			this.Init();
		},
		methods: {
			async Init(){ 
				//获取cv信息
				this.cv = await Api.query("/open/cv/detail",{cvId:this.cvId}); 
			},
			async miniapp(){
				let l = await User.checkLogin();
				if(!l){
					Toast.fail('未登录');
					return false;
				}
				//检测是否为vip
				let userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
				if(!userInfo.vip_time||userInfo.vip_time<(Date.now()/1000)){
					//检测当前简历是否为付费状态
					let cv = await Api.query("/open/cv/detail",{cvId:this.cvId});
					if(cv.pay!=1){
						this.orderCvOpen();
						return false;
					}
				} 
				this.$router.push({path:"/cv/detail/"+this.cvId}); 
			},
			async image(){
				let l = await User.checkLogin();
				if(!l){
					Toast.fail('未登录');
					return false;
				}
				//检测是否为vip
				let userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
				if(!userInfo.vip_time||userInfo.vip_time<(Date.now()/1000)){
					//检测当前简历是否为付费状态
					let cv = await Api.query("/open/cv/detail",{cvId:this.cvId});
					if(cv.pay!=1){
						this.orderCvOpen();
						return false;
					}
				}
				Toast.loading("文件下载中");
				let result = await Api.query("/open/export/image",{cvId:this.cvId,water:0});
				Toast.clear();
				window.open(result.url,"_self");
			},
			 
			async pdf(){
				let l = await User.checkLogin();
				if(!l){
					Toast.fail('未登录');
					return false;
				}
				//检测是否为vip
				let userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
				if(!userInfo.vip_time||userInfo.vip_time<(Date.now()/1000)){
					//检测当前简历是否为付费状态
					let cv = await Api.query("/open/cv/detail",{cvId:this.cvId});
					if(cv.pay!=1){
						this.orderCvOpen();
						return false;
					}
				}
				Toast.loading("文件下载中");
				let result = await Api.query("/open/export/pdf",{cvId:this.cvId,water:0});
				Toast.clear();
				window.open(result.url,"_self"); 
			},
			orderCvOpen(){
				this.ordercv = true; 
			},
			orderCvClose(){
				this.ordercv = false; 
			},
			orderCvSure(){
				this.orderCvClose();
			},
			close(){
				this.$emit('close');
			},
			sure(){
				this.$emit('sure');
			},
		}
	}
</script> 
<style lang="less" scoped> 
 .editor{
		float:left; 
		background:#fff;
		width:100%;
		height:210px;
		border-radius:10px; 
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			 border-radius:10px 10px 0px 0px;
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.types{
			float:left;
			width:100%;
			height:calc(100% - 40px);
			padding:10px 0px 10px 0px; 
			.type{
				float:left;
				width:calc(100% / 3);
				height:150px;
				display: flex;
				align-items: center;
				justify-content: center;
				.c{
					float:left;
					width:90px;
					height:150px;
					.top{
						float:left;
						width:100%;
						height:90px;
						background:#1261ff;
						border-radius:5px;
						.icon{
							float:left;
							width:100%;
							height:60px;
							display: flex;
							align-items: center;
							justify-content: center; 
							.img{
								width:40px;
								height:40px;
							}
						}
						.name{
							float:left;
							width:100%;
							height:30px;
							line-height:30px;
							text-align:center;
							color:#fff;
							font-weight:bold;
						}
					} 
					.tip{
						float:left;
						width:100%;
						line-height:24px;
						text-align:center;
						color:#999;
					}
				}
			}
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 40px); 
		}
		.datas{
			float:left;
			width:100%;
			padding:10px; 
			.data{
				float:left;
				width:100%;
				height:40px;
				border-bottom:1px solid #f5f5f5;
				.name{
					float:left;
					width:calc(100% /3);
					text-align:center;
					line-height:40px;
					height:40px;
					font-weight:bold;
					color:#1261ff;
				}
				.preview{
					float:left;
					width:calc(100% /3);
					height:40px;
					line-height:40px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.download{
					float:left;
					width:calc(100% /3);
					height:40px;
					line-height:40px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		.status{
			float:left;
			width:100%;
			height:40px;
			padding:0px 40px 0px 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			.icon{
				float:left;
				width:40px;
				height:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.tip{
				float:left;
				height:40px;
				 
				line-height:40px;
			}
		} 
	}
</style>
