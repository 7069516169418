<template> 
	<div class="container">  
		<div class="header">
			<div class="main">
				<div class="logo">
					简历通
				</div>
				<div class="slogan">
					<div>高效简历制作工具，手机在线制作</div>
					<div>支持导出PDF、在线转发</div>
				</div>
			</div> 
		</div>
		<div class="girds">
			<div class="gird">
				<div class="c" @click="goMyCv()">
					<div class="icon">
						<img src="https://cdn.dayujianli.com/static/miniapp/images/resumes.png" mode="aspectFill" class="img"/>
					</div>
					<div class="name">
						我的简历
					</div>
				</div>
			</div>
			<div class="gird">
				<div class="c" @click="goTemplateMore()">
					<div class="icon">
						<img src="https://cdn.dayujianli.com/static/miniapp/images/gonglue.png" mode="aspectFill" class="img"/>
					</div>
					<div class="name">
						简历模板
					</div>
				</div>
			</div>
			<div class="gird">
				<div class="c" @click="goOrder()">
					<div class="icon">
						<img src="https://cdn.dayujianli.com/static/miniapp/images/jobtest.png" mode="aspectFill" class="img"/>
					</div>
					<div class="name">
						我的订单
					</div>
				</div>
			</div>
			<div class="gird">
				<div class="c" @click="goHelp()">
					<div class="icon">
						<img src="https://cdn.dayujianli.com/static/miniapp/images/help.png" mode="aspectFill" class="img"/>
					</div>
					<div class="name">
						帮助中心
					</div>
				</div>
			</div>
		</div> 
		 
		<Swipe class="recs" :loop="false"  :show-indicators="false" :initial-swipe="current" @change="currentChange" :style="'height:'+((systemInfo.windowWidth/2.5)/(140/198)+60)+'px;'">
			<SwipeItem v-for="template,ti in templates_swiper" >
				<div :class="ti==current?'active':'item'">
				 
						<div v-if="ti>current" class="template" :style="'margin-left:-'+(systemInfo.windowWidth*0.1)+'px;width:'+(systemInfo.windowWidth/2.5)+'px;height:'+(systemInfo.windowWidth/2.5)/(140/198)+'px;'"  @click="goTemplate(template.templateId)">
							<img :src="template.thumbnail" class="img" :style="'width:'+(systemInfo.windowWidth/2.5)+'px;height:'+(systemInfo.windowWidth/2.5)/(140/198)+'px;'"/>
						</div> 
						<div v-if="ti<current" class="template" :style="'margin-left:'+(systemInfo.windowWidth*0.7)+'px;width:'+(systemInfo.windowWidth/2.5)+'px;height:'+(systemInfo.windowWidth/2.5)/(140/198)+'px;'"  @click="goTemplate(template.templateId)">
							<img :src="template.thumbnail" class="img" :style="'width:'+(systemInfo.windowWidth/2.5)+'px;height:'+(systemInfo.windowWidth/2.5)/(140/198)+'px;'"/>
						</div>  
						<div v-if="ti===current" class="template" :style="'width:'+(systemInfo.windowWidth/2.5)+'px;height:'+(systemInfo.windowWidth/2.5)/(140/198)+'px;'"  @click="goTemplate(template.templateId)">
							<img :src="template.thumbnail" class="img" :style="'width:'+(systemInfo.windowWidth/2.5)+'px;height:'+(systemInfo.windowWidth/2.5)/(140/198)+'px;'"/>
						</div>
					</div>
			</SwipeItem>
		</Swipe> 
		<div class="templatemore">
			<div class="btn" @click="goTemplateMore()">
				<div class="tip">更多模板</div>
				<div class="icon">
					<Icon name="arrow" size="20" color="#1261ff"/>
				</div>
			</div>
		</div>
		<div class="templates">
			<div class="title">最新简历模板</div>
			<div class="template" v-for="template,si in templates" :key="si">
				<div class="c" @click="goTemplate(template.templateId)">
					<div class="cover">
						<img :src="template.thumbnail" mode="aspectFill" class="img"/>
					</div>
					<div class="name">
						{{template.name}}
					</div>
				</div>
			</div>
			<div class="templatemore">
				<div class="btn" @click="goTemplateMore()">
					<div class="tip">更多模板</div>
					<div class="icon"> 
						<Icon name="arrow" size="20" color="#1261ff"/>
					</div>
				</div>
			</div>
		</div>  
		<div style="float:left;width:100%;height:70px;"></div> 
		<Bottom avtive="home"></Bottom>
	</div>
</template>

<script> 
	import Api from '@/service/api.js';  
	import {Icon,Swipe, SwipeItem} from "vant"
	import Bottom from '@/components/bottom/bottom.vue'; 
	export default {
		components:{ Icon, Swipe, SwipeItem, Bottom },
		data() {
			return {
				loginShow:false,
				templates:[],
				current:1,
				systemInfo:false,
				templates_swiper:[],
				templates:[]
			}
		}, 
  	mounted:function(){
     	this.getData();
		this.systemInfo = {
			windowWidth:window.innerWidth,
			windowHeight:window.innerHeight
		};	
    },
	methods: {
      async getData(){
		 
        var result = await Api.query('/open/template/index',{page_size:10,page:1,orderby:'use'}); 
				if(result){ 
					this.templates_swiper = result.data;
				} 
				var result1 = await Api.query('/open/template/index',{page_size:10,page:1,orderby:'new'});
				if(result1){
					this.templates = result1.data;
				} 
     	},
			async currentChange(e){
				 
				this.current = e;
			},
			async goOrder(){
				this.$router.push({path:"/user/order/index"});
			},
			async goTemplateMore(){
				this.$router.push({path:"/template/index"});
			},
			async goHelp(){
				this.$router.push({path:"/help/index"}); 
			},
			async goTemplate(templateId){ 
				this.$router.push({path:"/template/detail/"+templateId});
			}, 
			async goMyCv(){ 
				this.$router.push({path:"/user/cv/index"}); 
			},
			loginSuccess(){
				this.loginShow= false;
			},
			loginClose(){
				this.loginShow=false;
			},
		}
	}
</script>

<style lang="less" scoped> 
	.recs{
		float:left;
		width:100%;
		position:relative; 
		.item{
			float:left;
			height:100%;
			width:100%; 
			position:relative;
			display: flex;
			align-items: center; 
			.template{
				float:left; 
				background:#FFF;
				border-radius:5px;
				box-shadow: 2px 2px 5px #888888;   
			
				.img{
					border-radius:5px;
					width:100%;
					height:100%;
				}
			}
		}
		.active{
			float:left;
			height:100%;
			width:100%; 
			position:relative; 
			display: flex;
			align-items: center;
			justify-content: center;
			transform: scale(1.1);
			transition: all .10s;
			.template{
				float:left; 
				width:100%;
				height:100%;
				background:#FFF;
				border-radius:5px;
				box-shadow: 2px 2px 5px #888888;  
				display: flex;
				align-items: center;
				justify-content: center;
				 .img{
					border-radius:5px;
					width:100%;
					height:100%;
				 }
			}
		}
	}
	.templatemore{
		float:left;
		width:100%;
		height:60px;
		display: flex;
		align-items: center;
		justify-content: center;
		.btn{
			float:left;
			width:180px;
			height:38px;
			border:1px solid #1261ff;
			color:#1261ff;
			background:#fff;
			border-radius:5px; 
			
			.tip{
				float:left;
				width:calc(100% - 70px);
				height:38px;
				line-height:38px;
				text-align:center;
				padding-left:38px;
			}
			.icon{
				float:left;
				width:70px;
				height:38px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.header{
		float:left;
		width:100%;
		height:180px;
		background:#1261ff;
		display: flex;
		align-items: center;
		justify-content: center;
		.main{
			float:left;
			width:240px;
			height:120px;
			color:#fff;
			.logo{
				float:left;
				width:100%;
				height:80px;
				line-height:80px;
				font-size:32px;
				font-weight:bold;
				text-align:center;
			}
			.slogan{
				float:left;
				width:100%;
				height:40px;
				line-height:20px;
				font-size:12px; 
				text-align:center;
			}
		}
	}
	.templates{
		float:left;
		width:100%;
		background:#fff;
		padding:10px;
		.title{
			float:left;
			width:100%;
			height:30px;
			line-height:30px;
			border-bottom:1px solid #f5f5f5;
			text-align:center;
			font-weight:bold;
		}
		.template{
			float:left;
			width:calc(100% / 2);
			display:flex;
			align-items: center;
			justify-content: center;
			margin:10px 0px 10px 0px;
			.c{
				float:left;
				width:144px;
				height:222px;
				padding:2px;
				background:#f5f5f5;
				.cover{
					float:left;
					width:140px;
					height:198px;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						width:140px;
						height:198px;
					}
				}
				.name{
					float:left;
					width:100%;
					height:20px;
					line-height:20px;
					text-align:center;
				}
			}
		}
	}
	.girds{
		float:left;
		width:calc(100% - 20px);
		height:80px;
		padding:10px;
		margin:10px; 
		.gird{
			float:left;
			width:calc(100% / 4);
			height:60px;
			display: flex;
			align-items: center;
			justify-content: center;
			.c{
				float:left;
				width:60px;
				height:60px;
				.icon{
					float:left;
					width:100%;
					height:40px;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						width:30px;
						height:30px;
					}
				}
				.name{
					float:left;
					width:100%;
					height:20px;
					line-height:20px;
					text-align:center;
				}
			}
		}
	}
</style>
