<template>
	<div class="container">
		<div v-for="page,pageIndex in cv.data.pages" :key="pageIndex" v-if="cv&&cv.data.pages.length>0">
			<div class="page" v-if="page.show&&page.type=='cover'"> 
				<Cover :page="page" :cvWidth="cvWidth" :cvHeight="cvHeight" :scale="scale" :theme="cv.data.config.theme" :template="template"></Cover>
				<div class="margin"></div>
			</div>
			<div class="page" v-if="page.type=='page'">
				<Page :page="page" :cvWidth="cvWidth" :cvHeight="cvHeight" :scale="scale" :theme="cv.data.config.theme" :template="template"></Page>
				<div class="margin"></div>
			</div>
			<div class="page" v-if="page.show&&page.type=='letter'">
				<Letter :page="page" :cvWidth="cvWidth" :cvHeight="cvHeight" :scale="scale" :theme="cv.data.config.theme" :template="template"></Letter>
				<div class="margin"></div>
			</div>
		</div> 
	</div>
</template>

<script> 
	import User from '@/service/user.js';
	import Api from '@/service/api.js';
	import Cover from "@/components/cv/preview/cover.vue"
	import Page from "@/components/cv/preview/page.vue"
	import Letter from "@/components/cv/preview/letter.vue"
	import {Icon,Toast} from "vant"
	export default {
		components:{Cover,Page,Letter,Toast},
		data() {
			return {
				loginShow:false,
				template:{},
				current:1,
				systemInfo:false,
				cvId:false,
				cv:false,
				cvWidth:0,
				cvHeight:0,
			}
		},
		created(){
			this.Init();
		},
		methods: {
			async currentChange(e){
				this.current = e.detail.current;
			}, 
			async Init(){ 
				this.systemInfo = {
					windowWidth:window.innerWidth,
					widowHeight:window.innerHeight
				}
				this.cvWidth = this.systemInfo.windowWidth;
				this.scale = this.cvWidth/950;
				this.cvHeight = 1345*this.scale;
				this.cvId = this.$route.params.cvId;  
				var result = await Api.query('/open/cv/get',{cvId:this.cvId});
				console.log(result);
				if(result){
					this.cv = result;
					Api.query("/open/counts/view",{cvId:this.cvId,view_type:'mobile'}); 
					this.template = await Api.query("/open/template/detail",{templateId:this.cv.templateId});
				} 
			},
			loginSuccess(){
				this.loginShow= false;
			},
			loginClose(){
				this.loginShow=false;
			},
		}
	}
</script>

<style lang="less" scoped>
	.container{
		min-height:100vh;
		overflow-y: scroll; 
	}
	.margin{
		float:left;
		width:100%;
		height:20px;
		background-color:#808080;
	}
	.recs{
		float:left;
		width:100%; 
		.item{
			float:left;
			height:100%;
			width:100%;
			display: flex;
			align-items: center;
			justify-content: center;  
			.template{
				float:left; 
				background:#FFF;
				border-radius:5px;
				box-shadow: 2px 2px 5px #888888;
				 .img{
					border-radius:5px;
					width:100%;
					height:100%;
				 }
			}
		}
		.active{
			transition: all .10s;
			transform: scale(1.1);
		}
	}
	.header{
		float:left;
		width:100%;
		height:180px;
		background:#1261ff;
		display: flex;
		align-items: center;
		justify-content: center;
		.main{
			float:left;
			width:240px;
			height:120px;
			color:#fff;
			.logo{
				float:left;
				width:100%;
				height:80px;
				line-height:80px;
				font-size:32px;
				font-weight:bold;
				text-align:center;
			}
			.slogan{
				float:left;
				width:100%;
				height:40px;
				line-height:40px;
				font-size:14px; 
				text-align:center;
			}
		}
	}
	.templates{
		float:left;
		width:100%;
		background:#fff;
		padding:10px;
		.title{
			float:left;
			width:100%;
			height:30px;
			line-height:30px;
			border-bottom:1px solid #f5f5f5;
			text-align:center;
			font-weight:bold;
		}
		.template{
			float:left;
			width:calc(100% / 2);
			display:flex;
			align-items: center;
			justify-content: center;
			margin:10px 0px 10px 0px;
			.c{
				float:left;
				width:144px;
				height:222px;
				padding:2px;
				background:#f5f5f5;
				.cover{
					float:left;
					width:140px;
					height:198px;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						width:140px;
						height:198px;
					}
				}
				.name{
					float:left;
					width:100%;
					height:20px;
					line-height:20px;
					text-align:center;
				}
			}
		}
	}
	.girds{
		float:left;
		width:calc(100% - 20px);
		height:80px;
		padding:10px;
		margin:10px; 
		.gird{
			float:left;
			width:calc(100% / 4);
			height:60px;
			display: flex;
			align-items: center;
			justify-content: center;
			.c{
				float:left;
				width:60px;
				height:60px;
				.icon{
					float:left;
					width:100%;
					height:40px;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						width:30px;
						height:30px;
					}
				}
				.name{
					float:left;
					width:100%;
					height:20px;
					line-height:20px;
					text-align:center;
				}
			}
		}
	}
</style>
