<template>
	<div class="edit" v-if="page">
		<div class="title"> 
			修改
			<div class="sure" @click="sure()" style='color:#1261ff;'>
				
				确定
			</div>
		</div>
		<div :scroll-y="true" class="main">
			<div class="text_full">
				 <Field label="主标题" ref="title" label-width="60" placeholder="请输入主标题" :value="page.data.title"  @input="e=>{inputChange('title',e)}" :clearable="false"  right-icon="edit" @click-right-icon="inputRightIconClick('title')"></Field>
			</div>
			<div class="text_full"> 
				 <Field label="副标题" ref="subTitle" label-width="60"  placeholder="请输入副标题" :value="page.data.subTitle"  @input="e=>{inputChange('subTitle',e)}" :clearable="false"   right-icon="edit" @click-right-icon="inputRightIconClick('subTitle')"></Field> 
			</div> 
			<div class="text_full"> 
				 <Field  label="抬头" ref="rise" label-width="60"  placeholder="抬头,例如:尊敬的领导:" :value="page.data.rise"  @input="e=>{inputChange('rise',e)}" :clearable="false" right-icon="edit" @click-right-icon="inputRightIconClick('rise')"></Field> 
			</div> 
			 <div class="text_full"> 
				 <Field  label="落款" ref="sign" label-width="60"  placeholder="请输入落款,写上自己的姓名即可" :value="page.data.sign"  @input="e=>{inputChange('sign',e)}" :clearable="false" right-icon="edit" @click-right-icon="inputRightIconClick('sign')"></Field> 
			</div>
			<div class="contents">
				<div class="tip">↓修改内容段落↓</div>
				<div class="lines">
					<div class="line" v-for="line,li in page.data.contents" :key="li">
						<div class="input">
							<Field type="textarea" :autosize="true"  @input="e=>{contentsChange(li,e)}"   :value="line"/>
						</div>
						<div class="btns">
							<div class="btn" >
								<div class="up" @click="contentsUp(li)">
									<Icon name="arrow-up"></Icon>
								</div> 
							</div>
							<div class="btn">
								<div class="up" @click="contentsDown(li)">
									<Icon name="arrow-down"></Icon>
								</div> 
							</div>
							<div class="btn">
								<div class="up"  @click="contentsDelete(li)">
									<Icon name="cross"></Icon>
								</div> 
							</div>
						</div>
					</div>
				</div>
				<div class="newline">
					<div class="btn" @click="contentsAdd()">
							增加一段
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script> 
	import Api from '@/service/api.js'
	import Utils from "../utils.js"
	import {Icon,Field,Dialog,Toast} from "vant"
	export default {
		components:{Icon,Field,Dialog},
		name: 'sections',
		props:{ 
			page:{
				type:Object,
				default:{}
			}, 
		}, 
		computed:{
			 
		},
		data() {
			return {
				newline:''
				};
		},
		mounted:function(){
			this.Init(); 
		},
		methods: {
			async Init(){
			}, 
			close(){
				this.$emit('close');
			}, 
			async contentsAdd(){
				let page =  JSON.parse(JSON.stringify(this.page));
				 page.data.contents.push('');
				 this.$emit("pageUpdate",page);
			},
			async inputRightIconClick(key){
				let refs = this.$refs;
				let ref = refs[key];
				ref.focus();
			},
			async contentsChange(li,value){
				let page = JSON.parse(JSON.stringify(this.page))
				page.data.contents[li] = value; 
				this.$emit("pageUpdate",page);
			},
			async inputChange(key,value){ 
				let page = JSON.parse(JSON.stringify(this.page))
				page.data[key] = value; 
				this.$emit("pageUpdate",page);
			},
			async contentsUp(li){
				if(li!=0){
					let page = JSON.parse(JSON.stringify(this.page));
					let newLine = page.data.contents[li-1];
					page.data.contents.splice(li+1,0,newLine);
					page.data.contents.splice(li-1,1);
					this.$emit("pageUpdate",page);
				}
			},
			async contentsDown(li){
				if(li!=this.page.data.contents.length-1){
					let page = JSON.parse(JSON.stringify(this.page));
					let newLine = page.data.contents[li];
					page.data.contents.splice(li+2,0,newLine);
					page.data.contents.splice(li,1);
					this.$emit("pageUpdate",page);
				}
			},
			async contentsDelete(li){
				var that =this;
				Dialog.confirm({
					title:"温馨提示",
					message:"删除后无法恢复，确定删除？" 
				}).then(function(res){
						if(res){
							let page = JSON.parse(JSON.stringify(that.page));
							page.data.contents.splice(li,1);
							that.$emit("pageUpdate",page);
						}
				});
			},
			async sure(){
				let page = JSON.parse(JSON.stringify(this.page))
				this.$emit("sure",page);
			},
		}
	}
</script> 
<style lang="less" scoped> 
 .edit{
		float:left; 
		background:#fff;
		width:100%;
		height:400px; 
		border-radius:10px 10px 0px 0px;
		.bottom{
			float:left;
			width:100%;
			height:40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top:1px solid #f5f5f5;
			.btn{
				float:left;
				width:120px;
				height:32px;
				line-height:32px; 
				background:#f5f5f5;
				border-radius:5px;
				border:1px solid #6A6A6A;
				.icon{
					float:left;
					width:42px;
					height:32px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.tip{
					float:left;
					width:calc(100% - 42px);
					height:32px;
					line-height:32px; 
				}
			} 
			.reset{
				margin-left:40px;
				
			}
		}
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			 border-radius:10px 10px 0px 0px;
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 40px);
			overflow-y: scroll;
			box-sizing: border-box;
		}
		.contents{
			float:left;
			width:100%;
			padding:10px;
			.tip{
				float:left;
				width:100%;
				height:30px;
				text-align:center;
				color:#a5a5a5;
				font-size:14px;
			}
			.line{
				float:left;
				width:100%;
				padding:5px;
				margin-bottom:10px;
				background:#f5f5f5;
				.input{
					float:left;
					width:100%; 
				}
				.btns{
					float:left;
					width:100%;
					height:30px; 
					.btn{
						float:left;
						width:calc(100% / 3);
						height:30px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			.newline{
				float:left;
				width:100%;
				height:40px;
				padding:6px;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn{
					float:left;
					width:180px;
					height:28px;
					line-height:28px;
					text-align:center;
					background:#1261ff;
					color:#fff;
					border-radius:14px;
				}
			}
		}
		.text_full{
				float:left;
				width:100%;
				border-bottom:1px solid #f5f5f5; 
			} 
		
	}
</style>
