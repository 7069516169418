<template>
	<div class="styles"> 
		<div class="title">
			增加模块
			<div class="sure" @click="close()">
				<Icon name="cross" size="22"></Icon>
			</div> 
		</div>
		<div class="main">
			<div class="sections">
				<div class="section" v-for="section,si in sections" :key="si" @click="select(si)">
					<div class="name">{{section.name}}</div>
					<div class="icon">
						<Icon name="arrow" size="24" color="#a5a5a5"></Icon>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script> 
	import Api from '@/service/api.js'
	import Utils from "@/components/cv/utils.js"
	import {Icon} from "vant"
	export default {
		components:{Icon},
		name: 'sections',
		props:{ 
		}, 
		data() {
			return {
				sections:[
					{
						name:"求职意向", 
						type:'hope',
						title:"求职意向"
					},	
					{
						name:"工作经验", 
						type:'job',
						title:"工作经验"
					},					
					{
						name:"实习经历",
						type:'job',
						title:'实习经历'
					},
					{
						name:"教育背景", 
						type:"edu",
						title:"教育背景"
					},
					{
					 	name:"兴趣爱好", 
					 	type:'hobby',
					 	title:"兴趣爱好"
					},
					{
					 	name:"自我评价", 
					 	type:'self',
					 	title:"自我评价"
					},
					{
						name:"专业技能",
						type:"skill",
						title:"专业技能"
					}, 
					{
						name:"奖项证书",
						type:"prize",
						title:"奖项证书"
					}, 
					{
						name:"其它介绍",
						type:"single",
						title:"其它介绍"
					}, 
				] 
			};
		},
		mounted:function(){
			this.Init();
		},
		methods: {
			async Init(){
				 
			},
			close(){
				this.$emit('close');
			}, 
			async select(si){
				let block = this.sections[si];
				 //获取section信息
				 let options = Utils.OPTIONS[block.type];
				 var data = {};
				 for(var i in options){
					data[options[i].key] = options[i].default; 
				 }
				 let section = {
					 type:block.type,
					 title:block.title,
					 titleShow:true,
					 data:[
						 data
					 ]
				 }
				this.$emit('sure',section);
			}, 
		}
	}
</script> 
<style lang="less" scoped> 
 .styles{
	float:left; 
		background:#fff;
		width:100%;
		height:100%; position:relative;
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			border-radius:5px 5px 0px 0px;
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 40px);
			overflow-y: scroll;
			.sections{
				float:left;
				width:100%;
				.section{
					float:left;
					width:100%;
					height:40px;
					border-bottom:1px solid #f5f5f5;
					.name{
						float:left;
						width:calc(100% - 60px);
						height:40px;
						padding-left:40px;
						line-height:40px;
						font-size:14px;
						font-weight:bold;
					}
					.icon{
						float:left;
						width:60px;
						height:40px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				.section:last-child{
					border:none;
				}
			}
		}
	}
</style>
