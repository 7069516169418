<template>  
	<div class="container">
		<div class="header-nologin" v-if="!loginStatus">
			<div class="header_content">
				<div class="avatar">
					<img src="@/assets/icon/defaultuser.png" class="img"/>
				</div>
				<div class="loginBtn" @click="login()">
					<div class="btn">点此登陆</div>
				</div>
			</div>
		</div> 
		<div class="header" v-if="loginStatus">
			<div class="header_content">
				<div class="avatar">
					<img :src="userInfo.headimg" class="img"/>
				</div>
				<div class="nickname">
					{{userInfo.nickname}}
				</div>
				<div class="info">
					编号:{{userInfo.userId}}
				</div>
			</div>
			<div class="items">
				<div class="item">
					<div class="c" @click="go('/user/cv/index')">
						<div class="value">{{cvCount}}</div>
						<div class="name">简历</div>
					</div> 
				</div>
				<div class="item">
					<div class="c">
						<div class="value">{{userInfo.redeem}}</div>
						<div class="name">积分</div>
					</div> 
				</div>
				<div class="item">
					<div class="c">
						<div class="value">{{userInfo.redeem}}</div>
						<div class="name">怪点</div>
					</div> 
				</div>
			</div>
			<div class="vip">
				<div class="c">
					<div class="icon">
						<Icon name="vip-filled" size="30" color="#fff"></Icon>
					</div>
					<div class="text">
						<div class="t">开通VIP会员</div>
						<div class="b">简历模板免费用，不限制生成次数</div>
					</div>
					<div class="action" @click="go('/vip/index')">
						<div class="btn">立即开通</div>
					</div>
				</div>
			</div>
			<div class="logout">
				<div class="btn" @click="logout()">退出登陆</div>
			</div>
		</div>
		<Cvs v-if="loginStatus&&cvs.length>0" :cvs="cvs" title="最近制作的简历" @reload="getCvs"></Cvs>
		
		<div class="action-list-block"> 
			<div class="action" @click="subscribe()">
				<div class="icon_left"> 
					<Icon name="like-o" size="30"></Icon>  
				</div>
				<div class="name">
					关注我们
				</div>
				<div class="icon_right">
					<Icon name="arrow" size="24" color="#a5a5a5"></Icon>  
				</div>
			</div>
			<div class="action" @click="go('/user/cv/index')">
				<div class="icon_left"> 
					<Icon name="bars" size="30"></Icon> 
				</div>
				<div class="name">
					我的简历
				</div>
				<div class="icon_right">
					<Icon name="arrow" size="24" color="#a5a5a5"></Icon>  
				</div>
			</div>
			<div class="action"  @click="go('/user/fav/index')">
				<div class="icon_left">
					<Icon name="star" size="30"></Icon>
				</div>
				<div class="name">
					我的收藏
				</div>
				<div class="icon_right">
					<Icon name="arrow" size="24" color="#a5a5a5"></Icon>  
				</div>
			</div>  
			<div class="action"  @click="go('/user/order/index')">
				<div class="icon_left">
					<Icon name="cart" size="30"></Icon>
				</div>
				<div class="name">
					我的订单
				</div>
				<div class="icon_right">
					<Icon name="arrow" size="24" color="#a5a5a5"></Icon>  
				</div>
			</div> 
			<div class="action"   @click="go('/help/index')">
				<div class="icon_left">
					<Icon name="question" size="30"></Icon> 
				</div>
				<div class="name">
					帮助中心
				</div>
				<div class="icon_right">
					<Icon name="arrow" size="24" color="#a5a5a5"></Icon>  
				</div>
			</div>  
		</div> 
		<Login :show="showLogin" @close="closeLogin" @success="successLogin"/> 
		<div style="float:left;width:100%;height:70px;"></div>
		<Bottom active="user"></Bottom>
	</div>
	
</template>  
<script>
	 
	import Login from '@/components/login/login.vue';
	import User from '@/service/user.js'  
	import Bottom from '@/components/bottom/bottom.vue'
	import Cvs from './cvs.vue'
	import Api from "@/service/api.js"
	import {Toast,Icon,Dialog} from "vant"
	export default {
		components:{Login,Bottom,Cvs,Icon,Dialog},
		data() {
			return {
				userInfo:{},
				loginStatus:false,
				showLogin:false,
				tasks:[],
				maxPage:0,
				page:1,
				pageSize:10,
				status:'1', 
				cvs:[],
				cvCount:"-",
				
			}
		},
		created:function(){
			this.Init();
		},
		methods: {
			subscribe(){
				window.location.href="https://mp.weixin.qq.com/s/fPbOV6me2gs3qINdv5Z-sQ"
			},
			async go(url){
				this.$router.push({path:url});
			},  
			async getData(){
				if(this.loginStatus){
					let l  = await User.checkLogin();
					this.userInfo =JSON.parse(localStorage.getItem('USER_INFO'));
					let count = await Api.query("/open/cv/count",{});
					if(count){
						this.cvCount = count.cvCount;
					}
					this.getCvs();
				} 
			},
			async getCvs(){
				let r = await Api.query("/open/cv/index",{type:'0',page:1,page_size:1});
				this.cvs = r.data; 
			},
			async Init(){
				let l = await User.checkLogin();
				if(!l){
					this.loginStatus = false; 
				}else{ 
					this.loginStatus=true; 
				}
				this.getData();
			},
			async logout(){
				var that = this;
				Dialog.confirm({
				    title: '退出登陆',
				    message: '请问是否需要退出登陆',
				    
				}).then(function(res){
					  if (res) {
							User.logout();
							that.loginStatus = false;
							that.userInfo = {};
							that.tasks = []; 
				        } 
				});
				
			}, 
			async login(){ 
				this.showLogin = true;
			},
			async closeLogin(){
				this.showLogin =false;
			},
			async successLogin(){
				this.showLogin =false;
				this.loginStatus=true;
				this.getData();
			} 
		}
	}
</script>
<style  lang="less" scoped>
	@import  './user.less';
	.girds{
		float:left;
		width:calc(100% - 20px);
		padding:10px;
		margin:10px;
		background:#fff;
		border-radius:10px;
		.title{
			float:left;
			width:100%;
			height:30px;
			line-height:30px;
			font-weight:bold;
			font-size:14px;
			border-bottom:1px solid #f5f5f5;
		}
		.gird{
			float:left;
			width:calc(100% / 4);
			height:60px;
			display:flex;
			align-items: center;
			justify-content: center; 
			margin-bottom:5px;
			margin-top:5px;
			.c{
				float:left;
				width:70px;
				height:60px;
				.icon{
					float:left;
					width:100%;
					height:40px;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						width:30px;
						height:30px;
					}
				}
				.tip{
					float:left;
					width:100%;
					height:20px;
					line-height:20px;
					text-align:center;
				}
			}
		}
	}
	.action-list-block{
		float:left;
		width:calc(100% - 20px);
		margin:10px;
		background:#fff;
		border-radius:10px;
		.action{
			float:left;
			width:100%;
			height:50px;   
			padding:5px;
			border-bottom:1px solid #DCDFE6;
			.icon_left{
				float:left;
				width:40px;
				height:40px;
				display: flex;
				align-items: center;
				justify-content: center;
				.img{
					width:30px;
					height:30px;
				}
			}
			.name{
				float:left;
				width:calc(100% - 80px);
				height:40px;
				padding-left:20px;
				line-height:40px; 
				color:#8F939C;
				font-size:14px;
			}
			.icon_right{
				float:left;
				width:40px;
				height:40px;
				display: flex;
				align-items: center;
				justify-content: center;
				.img{
					width:40px;
					height:40px;
				}
			}
		}
		.action:last-child{
			border:none;
			margin-bottom:0;
		}
	}
	
</style>
 
