<template>
	<div>
		<div class="h5s">
			<div class="title" v-if="title!==''">
				{{title}}
				<div class="more" @click="tomore()">查看更多</div>
			</div>
			 <div class="no" v-if="cvs.length==0">
				 <div class="btn" @click="todo()">去制作</div>
			 </div>
			 <div class="lists" v-if="cvs.length>0"> 
				 <div class="list" v-for="cv,pi in cvs" :key="pi">
					<div class="coverImage"> 
						<img :src="cv.thumbnail" class="img" mode="aspectFit"/>
					</div>
					 <div class="name">
						{{cv.name}}
					 </div> 
					 <div class="extra">
						<div class="block" style="width:100%;">
 							<div class="icon"><Icon name="manager"></Icon></div>
						 	<div class="value">{{cv.data.pages[1].sections[0].data.name}}</div>
						</div>  
					 </div> 
					  <div class="extra">
						<div class="block">
 							<div class="icon"><Icon name="eye"></Icon></div>
						 	<div class="value">浏览：{{cv.view_count}}次</div>
						</div> 
						<div class="block">
 							<div class="icon"><Icon name="eye"></Icon></div>
						 	<div class="value">导出：{{cv.export_count}}次</div>
						</div>
					 </div>  
					 <div class="actions">
						<div class="btn" @click="cvEdit(cv.cvId)">编辑</div>
						<div class="btn" @click="exportOpen(cv.cvId)">发送/生成</div> 
						<div class="more" @click="cvDelete(cv.cvId)">
							删除
						</div>
					 </div>
				 </div>
			 </div>
		</div> 
		<Popup ref="export" position="bottom" v-if="exportShow" v-model="exportShow">
			<Export :cvId="cvId" v-if="cvId&&exportShow" @close="exportClose" @sure="exportClose"></Export>
		</Popup> 
	</div> 
</template>

<script> 
	import Export from "@/components/cv/export/index.vue" 
	import Api from "@/service/api.js"
	import User from "@/service/user.js"
	import {Icon,Toast,Popup,Dialog} from "vant"
	 export default { 
		components:{Export,Icon,Popup,Dialog},
	 	props:{
	 		cvs:{	//显示的内容
	 			type: Array,
	 			default: []
	 		}, 
			title:{
				type:String,
				default:''
			},
			more:{
				type:String,
				default:"/user/cv/index"
			},
			do:{
				type:String,
				default:"/"
			},
	 	},
	 	data() {
	 		return {
	 			cvId:false,
	 			exportShow:false 
	 		};
	 	},
		mounted(){
		 
		},
		methods:{
			async todo(){
				this.$router.push({path:this.do});
			},
			async tomore(){
				this.$router.push({path:this.more});
				 
			},
			exportOpen(cvId){
				Api.query("/open/cv/thumbnail",{cvId:cvId});
				this.cvId = cvId;
				this.exportShow =true;
			 
			},
			exportClose(){
				this.exportShow =false; 
			},
			async cvOrderOpen(cvId){ 
				this.cvId =cvId;
				this.ordercv = true; 
			},
			async cvOrderClose(){
				
				this.cvId = false;
				 this.ordercv = false;
			},
			async cvOrderSure(){
				this.cvOrderClose(); 
				this.$emit('reload');
			},
			cvEdit(cvId){
				this.$router.push({path:"/editor/"+cvId});
			 
			},
			async cvDelete(cvId){
				var that =this;
				Dialog.confirm({
					title: '删除提示',
					message: '删除后无法恢复，确定删除？',
				 
				}).then(async function(res){
					if (res) { 
						let r = await Api.query("/open/cv/delete",{cvId:cvId});
						if(r){ 
							Toast.success('删除成功')
							that.$emit('reload');
						} 
					}  
				});
			},  
		},
	}
</script>

<style lang="less" scoped>
	.h5s{
		float:left;
		width:calc(100% - 20px);
		margin:10px;  
		border-radius:5px;
		 .title{
			 float:left;
			 width:100%;
			 height:30px;
			 line-height:30px;
			 color:#a5a5a5;
			 font-size:14px;
			 padding-left:10px;
			 position:relative;
			 .more{
				 position:absolute;
				 right:10px;
				 top:0px;
				 height:30px;
				 line-height:30px;
				 width:60px;
				 text-align:center;
				 color:#1261ff;
			 }
		 }
		 .no{
			 float:left;
			 width:100%;
			 height:90px;
			 display: flex;
			 align-items: center;
			 justify-content: center;
			 .btn{
				 float:left;
				 width:180px;
				 height:30px;
				 line-height:30px;
				 text-align:center;
				 background:#1261ff;
				 color:#fff;
				 border-radius:15px;
			 }
		 }
		 .lists{
			 float:left;
			 width:100%;  
			 .list{
				 float:left;
				 width:100%; 
				 padding:5px; 
				 background:#fff;
				 margin:5px 0px 5px 0px;
				 border-radius:5px;
				 .coverImage{
					 float:left;
					 width:90px;
					 height:90px;
					 display: flex;
					 align-items: center;
					 justify-content: center;
					 position:relative;
					.img{
						width:78px;
						height:78px;
						border-radius:3px;
					 }
				 }
				 .name{
					 float:left; 
					 width:calc(100% - 90px);
					 height:30px;
					 line-height:30px;
					 padding-left:10px;
					 overflow: hidden;
					 text-overflow: ellipsis;
					 white-space: nowrap; 
					 font-size:14px;
					 font-weight:bold;
					 color:#000; 
				 }
				.extra{
					 float:left; 
					 width:calc(100% - 90px);
					 height:30px;  
					 padding-left:5px;
					 overflow: hidden; 
					 .block{
						 float:left;
						 width:calc(100% / 2);
						 height:30px;
 						.icon{
							float:left;
							width:30px;
							height:30px;
							display: flex;
							align-items: center;
							justify-content: center;
					 	 }
					  	.value{
							float:left;
							width:calc(100% - 30px);
							height:30px;
							line-height:30px; 
							color:#3191F5;
							font-size:12px;
					  	}
					 }
					 
				}
				 
				.actions{
					 float:left; 
					 width:100%;
					 height:40px; 
					 padding:8px 0px 8px 10px;
					 overflow: hidden;
					 border-top:1px solid #f5f5f5;
					 position:relative;
					 .btn{
						 float:left;
						 padding:0px 14px 0px 14px;
						 border:1px solid #C0C0C0;
						 color:#000;
						 height:24px;
						 line-height:24px;
						 margin-right:5px;
						 border-radius:15px;
						 font-size:12px;
					 }
					 .more{
						 position:absolute;
						 right:10px;
						 top:8px;
						 height:24px;
						 padding:0px 14px 0px 14px;
						 border:1px solid #C0C0C0;
						 color:#000;
						 height:24px;
						 line-height:24px;
						 margin-right:5px;
						 border-radius:15px;
						  font-size:12px;
						 
					 }
				}
			 }
		 }
	}
</style>
