<template>  
	<div class="edit" v-if="section_">
		<AvatarCrop 
			@cropEnd="avatarCropEnd"
			ref="AvatarCrop"
		/> 
		<div class="base">
			<div class="avatar">
				<div class="preview">
					<img :src="section_.data.avatar" class="img">
				</div>
				<div class="btns">
					<div class="btn" @click="avatarShowChange()" v-if="section_.avatarShow">隐藏</div>
					<div class="btn" @click="avatarShowChange()" v-if="!section_.avatarShow">显示</div>
					<div class="btn upload">
						重传
						<input type="file" accept="image/*" style="hidden" ref="file" @change="avatarUpload"/>
					</div> 
				</div>
			</div>
			<div class="info">
				<div class="text">
					<Field ref="name" style="border-bottom:1px solid #f5f5f5;" label-class="text-label" label="姓名" label-width="60" colon  :value="section_.data.name" @input="e=>inputChange('name',e)" :clearable="false" @click-right-icon="inputRightIconClick('name')" right-icon="edit"/>
				</div> 
				<div class="text">
					<Field  ref="age" style="border-bottom:1px solid #f5f5f5;" label="年龄" label-width="60" colon  :value="section_.data.age" @input="e=>inputChange('age',e)" :clearable="false"  @click-right-icon="inputRightIconClick('age')" right-icon="edit"/>
				</div>
				<div class="text">
					<Field  ref="sex" label="性别" label-width="60" colon  :value="section_.data.sex" @input="e=>inputChange('sex',e)" :clearable="false"   @click-right-icon="inputRightIconClick('sex')" right-icon="edit"/>
				</div>
			</div>
		</div> 
		
		<div class="text-full">
			<Field  label="手机" label-width="60" colon  :value="section_.data.mobile" :clearable="false" @input="e=>inputChange('mobile',e)"  ref="mobile" @click-right-icon="inputRightIconClick('mobile')" right-icon="edit"/>
		</div>
		<div class="text-full">
			<Field  label="邮箱" label-width="60" colon  :value="section_.data.email"   :clearable="false" @input="e=>inputChange('email',e)" ref="email" @click-right-icon="inputRightIconClick('email')" right-icon="edit"/>
		</div>
		<div class="text-full">
			<Field  label="学历" label-width="60" colon  :value="section_.data.degree"  :clearable="false" right-icon="bars"  @input="e=>inputChange('degree',e)"  @click-right-icon="e=>pickerOpen('degree')"/>
		</div>
		<div class="text-full">
			<Field  label="籍贯" label-width="60" colon  :value="section_.data.birthLocation"  :clearable="false" right-icon="bars"  @input="e=>inputChange('birthLocation',e)"  @click-right-icon="e=>pickerOpen('birthLocation')"/>
		</div>  
		<div class="text-full">
			<Field  label="现居" label-width="60" colon  :value="section_.data.liveLocation"  :clearable="false" right-icon="bars"  @input="e=>inputChange('liveLocation',e)"  @click-right-icon="e=>pickerOpen('liveLocation')"/>
		</div> 
		<div class="text-full">
			<Field  label="当前状态" label-width="60" colon  :value="section_.data.status"   :clearable="false" right-icon="bars"  @input="e=>inputChange('status',e)" @click-right-icon="e=>pickerOpen('status')"/>
		</div>
		<div class="text-full">
			<Field  label="工作经验" label-width="60" colon  :value="section_.data.experience"  :clearable="false" right-icon="bars"  @input="e=>inputChange('experience',e)" @click-right-icon="e=>pickerOpen('experience')"/>
		</div> 
		
		<Popup position="bottom" v-model="statusPicker" v-if="statusPicker">
			<Picker
				title="请选择当前状态"
				show-toolbar
				:columns="statuss"  
				@cancel="e=>pickerClose('status')"
				@confirm="e=>pickerConfirm('status',e)" 
			> 
			</Picker>
		</Popup> 
		<Popup position="bottom" v-model="experiencePicker" v-if="experiencePicker">
			<Picker
				title="请选择工作经验"
				show-toolbar
				:columns="experiences"  
				@cancel="e=>pickerClose('experience')"
				@confirm="e=>pickerConfirm('experience',e)" 
			> 
			</Picker>
		</Popup> 
		<Popup position="bottom" v-model="degreePicker" v-if="degreePicker">
			<Picker
				title="请选择最高学历"
				show-toolbar
				:columns="degrees"  
				@cancel="e=>pickerClose('degree')"
				@confirm="e=>pickerConfirm('degree',e)" 
			> 
			</Picker>
		</Popup> 
		<Popup position="bottom" v-model="birthLocationPicker" v-if="birthLocationPicker">
			<Area
				title="请选择籍贯"
				:area-list="areaList"
				:columns-placeholder="['请选择', '请选择', '请选择']"
				@cancel="e=>pickerClose('birthLocation')"
				@confirm="e=>pickerConfirm('birthLocation',e)" 
			/>
		</Popup>
		<Popup position="bottom" v-model="liveLocationPicker" v-if="liveLocationPicker">
			<Area
				title="请选择现居地"
				:area-list="areaList"
				:columns-placeholder="['请选择', '请选择', '请选择']"
				@cancel="e=>pickerClose('liveLocation')"
				@confirm="e=>pickerConfirm('liveLocation',e)" 
			/>
		</Popup> 
	</div>  
</template>

<script> 
	import Api from '@/service/api.js' 
	import {Icon,Popup,Toast,Field,Picker,Area} from "vant"
	import { areaList } from '@vant/area-data';
	import axios from "axios"
	import  AvatarCrop from "./avatarcrop.vue"
	export default {
		components:{Icon,Popup,Field, Picker,Area,AvatarCrop},
		name: 'sections',
		props:{ 
			section:{
				type:Object,
				default:{}
			}
		}, 
		computed:{
			birthLocation:function(){
				if(this.section_.data){
					console.log(this.section_.data.birthLocation);
					let ls = this.section_.data.birthLocation.split('-');
					console.log(ls);
				} 
			}
		},
		data() {
			return {
				section_:false,
				statusPicker:false,
				experiencePicker:false,
				degreePicker:false,
				birthLocationPicker:false,
				liveLocationPicker:false, 
				areaList,
				sex:[
					{
						text: '男',
						value: "男"
					}, 
					{
						text: '女',
						value: "女"
					},
				],
				experiences:[
					{
						text:'应届生',
						value:'应届生'
					},
					{
						text:'1年工作经验',
						value:'1年工作经验'
					},
					{
						text:'2年工作经验',
						value:'2年工作经验'
					},
					{
						text:'3年工作经验',
						value:'3年工作经验'
					},
					{
						text:'4年工作经验',
						value:'4年工作经验'
					},
					{
						text:'5年及以上工作经验',
						value:'5年及以上工作经验'
					},
				],
				statuss:[
					{
						text:'已离职，可立即上岗',
						value:'已离职，可立即上岗'
					},
					{
						text:'在职，有更好的机会可以考虑',
						value:'在职，有更好的机会可以考虑'
					},
					{
						text:'在职，正考虑换份工作',
						value:'在职，正考虑换份工作'
					},
					{
						text:'在职，暂无跳槽打算',
						value:'在职，暂无跳槽打算'
					},
					{
						text:'应届毕业生',
						value:'应届毕业生'
					},
					
				],
				degrees:[ 
					{
						text:'大专',
						value:'大专',
					},
					{
						text:'本科',
						value:'本科',
					},
					{
						text:'硕士',
						value:'硕士',
					},
					{
						text:'博士',
						value:'博士',
					},
					{
						text:'小学及以下',
						value:'小学及以下',
					},
					{
						text:'初中',
						value:'初中',
					},
					{
						text:'高中',
						value:'高中',
					},
					{
						text:'中专',
						value:'中专',
					},
				],
				
			};
		},
		mounted:function(){
			this.Init();  
		},
		methods: {
			async Init(){ 
				this.section_ = this.section;
			},
			async avatarShowChange(){
				this.section_.avatarShow = !this.section_.avatarShow;
				this.update();
			},
			async avatarCropEnd(data){ 
				let result = await Api.query("/open/avatar/crop",data);
				if(result){
					this.section_.data.avatar = result.url;
					this.update();
				}else{
					Toast.fail('剪裁失败');
				}
			}, 
			async avatarUpload(e){ 
				var file = e.target.files[0];
				if (!/\.(gif|jpg|jpeg|png|gif|jpg|png)$/i.test(file.name)) {
					Toast.fail('仅支持上传图片');
					return false;
				}
				if(file.size>(1024*1024*2)){
					Toast.fail('图片大小超过2M');
					return false;
				}
				Toast.loading("上传中");
				let r = await Api.upload(file);
				this.$refs.file.value = '';
				Toast.clear();
				if(!r){
					Toast.fail("上传失败"); 
					return false;
				}
				//进行图片剪裁
				this.$refs.AvatarCrop.crop(r.url,200,240);
				
			},  
			pickerOpen(name){ 
				switch(name){
					case "status":
						this.statusPicker = true; 
					break;
					case "experience":
						this.experiencePicker = true; 
					break;
					case "degree":
						this.degreePicker = true; 
					break;
					case "birthLocation":
						this.birthLocationPicker = true; 
					break;
					case "liveLocation":
						this.liveLocationPicker = true; 
					break;
				} 
			},
			pickerClose(name){
				switch(name){
					case "status":
						this.statusPicker = false; 
					break;
					case "experience":
						this.experiencePicker = false; 
					break;
					case "degree":
						this.degreePicker = false; 
					break;
					case "birthLocation":  
						this.birthLocationPicker = false; 
					break;
					case "liveLocation":  
						this.liveLocationPicker = false; 
					break;
				}
			},
			pickerConfirm(name,e){ 
				switch(name){
					case "status":	
						this.section_.data.status = e.value;  
					break;
					case "experience":	
						this.section_.data.experience = e.value;  
					break;
					case "degree":	
						this.section_.data.degree = e.value;  
					break;
					case "birthLocation":
						var as = []; 
						for(var i in e){
							as.push(e[i].name);
						}
						this.section_.data.birthLocation = as.join('-');  
					break;
					case "liveLocation":
						var as = []; 
						for(var i in e){
							as.push(e[i].name);
						}
						this.section_.data.liveLocation = as.join('-');  
					break;
				} 
				this.pickerClose(name);
			},
			close(){
				this.$emit('close');
			}, 
			inputChange(key,e){
				console.log(key,e);
				this.section_.data[key] = e;
				this.update();
			}, 
		 	inputRightIconClick(key){
				 console.log(key);
				 switch(key){
					case "name":
						this.$refs.name.focus();
					break;
					case "age":
						this.$refs.age.focus();
					break;
					case "sex":
						this.$refs.sex.focus();
					break;
					case "mobile":
						this.$refs.mobile.focus();
					break;
					case "email":
						this.$refs.email.focus();
					break;
				 }
			 }, 

			async update(){ 
				this.$emit('update',JSON.parse(JSON.stringify(this.section_)));
			}, 
		}
	}
</script> 
<style lang="less" scoped>  
 .edit{
		float:left; 
		background:#f5f5f5;
		width:100%;
		height:100%; 
		padding:5px;
		overflow-y:scroll;
		.base{
			float:left;
			width:100%;
			height:144px;
			background:#fff;
			padding:5px;
			.avatar{
				float:left;
				width:106px;
				height:100%;
				position:relative;
				.preview{
					float:left;
					width:100%;
					height:100%;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						height:100%;
						width:100%;
					}
				}
				.btns{
					position:absolute;
					left:0px;
					bottom:0px;
					background:rgba(0,0,0,0.8);
					width: 100%; 
					height:26px; 
					.btn{
						float:left;
						width:calc(100% / 2);
						color:#fff;
						border-right:1px solid #fff;
						height:26px;
						line-height:26px;
						text-align:center;
						font-size:12px;
					}
					.upload{
						position:relative;
						input{
							position:absolute;
							z-index:1;
							left:0px;
							top:0px;
							width:100%;
							height: 100%;
							opacity: 0;
						}
					}
					.btn:last-child{
						border:none;
					}
				}
			}
			.info{
				float:left;
				width:calc(100% - 106px);
				height:100%;
				.text{
					float:left;
					width: 100%;
					padding:0px;
				}
			}
		}
		.text-full{
			float:left;
			width:100%; 
			border-bottom:1px solid #f5f5f5;
		} 
	}
</style>
