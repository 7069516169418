<template>
	<div class="container" :style="'width:'+cvWidth+'px;height:'+cvHeight+'px;'">
	 <div class="deletecover" @click="pageDelete()" v-if="renderIn=='editor'&&pageIndex!=1">
		<Icon name="cross" color="#fff" size="20"></Icon>
	</div> 
	<div class="pageInfo" v-if="pages.length>0">
		第{{pageIndex}}/{{pages.length-2}}页
	</div>
	<div class="preview" :style="'width:'+cvWidth+'px;height:'+cvHeight+'px;transform:scale('+(cvWidth/950)+');transform-origin:0 0;'">
		<div :class="contentClass"> 
			<div class="resume-paper">
				<div class="resume-left"> 
					<div class="baseinfo" v-if="template.plate=='double'"> 
						<template  v-for="section,si in page.sections" >
						<div class="field-value-span" @click="sectionSelect(si)" v-if="section.type=='baseinfo'" >
							  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si"> 
							</div>
							<div class="avater-wrapper" v-if="section.avatarShow">                 
								<div class="avater-inner">
									<img class="avater-img" alt="简历头像" :src="section.data.avatar" mode="aspectFill"/>
								</div>
							</div>
							<div class="baseinfo-panel-right">
								<div class="baseinfo-item baseinfo-title p">
									<div class="baseinfo-value editable">
										{{section.data.name}}
									</div>
								</div> 
							</div>
							<div class="baseinfo-panel-left">
								<div class="baseinfo-item baseinfo-fields">
									<div class="baseinfo-row p">
										<div class="baseinfo-label">学历：</div>
										<div class="baseinfo-value editable">{{section.data.degree}}</div>
									</div>
									<div class="baseinfo-row p">
										<div class="baseinfo-label">性别：</div>
										<div class="baseinfo-value editable">{{section.data.sex}}</div>
									</div>
									<div class="baseinfo-row p">
										<div class="baseinfo-label">年龄：</div>
										<div class="baseinfo-value editable">{{section.data.age}}</div>
									</div>
									<div class="baseinfo-row p">
										<div class="baseinfo-label">经验：</div>
										<div class="baseinfo-value editable">{{section.data.experience}}</div>
									</div>
									<div class="baseinfo-row p">
										<div class="baseinfo-label">手机：</div>
										<div class="baseinfo-value editable">{{section.data.mobile}}</div>
									</div>
									<div class="baseinfo-row p">
										<div class="baseinfo-label">邮箱：</div>
										<div class="baseinfo-value editable">{{section.data.email}}</div>
									</div>
								</div>
							</div>
						</div>
						</template>
						<template v-for="section,si in page.sections">
						<div class="field-value-span"  @click="sectionSelect(si)"  v-if="section.type=='skill'" >
							  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si"> 
							</div>
							<div class="baseinfo-panel-skill">
								<div class="skill-item  p" v-for="skill,si in section.data">
									<div class="baseinfo-label editable">
										{{skill.skill}}
									</div>
									<div style="float:left;width:100%;height:10px;"></div>
									<div class="skill-level-bg">
										<div class="skill-level" :style="'width:25%;'" v-if="skill.level=='了解'"></div>
										<div class="skill-level" :style="'width:50%;'" v-if="skill.level=='掌握'"></div>
										<div class="skill-level" :style="'width:75%;'" v-if="skill.level=='熟练'"></div>
										<div class="skill-level" :style="'width:100%;'" v-if="skill.level=='精通'"></div>
									</div>
									<div class="skill-level-word">
										{{skill.level}}
									</div>
								</div>
							</div>
						</div>
						</template>
					</div> 
					
				</div>
				<div class="field-list" cellspacing="0"> 
					<template v-for="section,si in page.sections">
					<div class="tbody baseinfo" @click="sectionSelect(si)" v-if="section.type=='baseinfo'&&template.plate=='single'">
						<div class="tr">
							<div class="field-title-td">                 
								<div class="baseinfo-header">                     
									<div class="baseinfo-title"></div>                 
								</div>             
							</div>         
						</div>
						<div class="tr">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable">{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>  
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si"> 
						</div>
						<div class="tr"> 
							<div class="td field-value-td">
								<div class="field-value-span">
									<div class="avater-wrapper"  v-if="section.avatarShow">
										<div class="avater-inner">
										  <img  class="avater-img" alt="简历头像" :src="section.data.avatar"  mode="aspectFill"/>
										</div>
									</div> 		  
									<div class="baseinfo-panel-left" v-if="!section.titleShow">
										<div class="baseinfo-item baseinfo-title">
											<div class="baseinfo-value editable"> 
												{{section.data.name}}
											</div>
										</div>
										<div class="baseinfo-item baseinfo-fields">
											<div class="baseinfo-value editable" >{{section.data.sex}}</div>
											<div class="baseinfo-value editable">{{section.data.age}}岁</div>
											<div class="baseinfo-value editable" >{{section.data.experience}}</div>
											<div class="baseinfo-value editable" >{{section.data.degree}}</div>  
											<div class="baseinfo-value editable">{{section.data.mobile}}</div>
											<div class="baseinfo-value editable" >{{section.data.email}}</div>
										</div> 
										<div class="baseinfo-item baseinfo-intro">
											<div class="baseinfo-value editable" adddel="false">{{section.data.status}}</div>
										</div>
									</div>
									<div class="baseinfo-panel-left" v-if="section.titleShow">
										<div class="baseinfo-item baseinfo-fields">
											<div class="baseinfo-row"> 
												<div class="baseinfo-label">
													姓名：
												</div>
												<div class="baseinfo-value editable">
													{{section.data.name}}
												</div>
											</div>
											<div class="baseinfo-row">
												<div class="baseinfo-label">
													性别：
												</div>
												<div class="baseinfo-value editable">
													{{section.data.sex}}
												</div>
											</div>
											<div class="baseinfo-row">
												<div class="baseinfo-label">
													年龄：
												</div>
												<div class="baseinfo-value editable">
													{{section.data.age}}
												</div>
											</div>
											<div class="baseinfo-row">
												<div class="baseinfo-label">
													手机：
												</div>
												<div class="baseinfo-value editable">
													{{section.data.mobile}}
												</div>
											</div>
											<div class="baseinfo-row">
												<div class="baseinfo-label">
													邮箱：
												</div>
												<div class="baseinfo-value editable">
													{{section.data.email}}
												</div>
											</div>
											<div class="baseinfo-row">
												<div class="baseinfo-label">
													学历：
												</div>
												<div class="baseinfo-value editable">
													{{section.data.degree}}
												</div>
											</div>
											<div class="baseinfo-row"> 
												<div class="baseinfo-value editable">
													{{section.data.status}}
												</div>
											</div>
										</div>
										
									</div>
									<div class="baseinfo-panel-right"></div>
								</div>
							</div>
						</div>
					</div>	
					<div class="tbody" data-block="four-1" v-if="section.type=='hope'"  @click="sectionSelect(si)"> 
						<div class="tr" v-if="section.titleShow">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable" v-if="section.titleShow">{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si">
							
						</div>
						<div class="tr">
							<div class="td field-value-td" v-for="hope,hi in section.data" :key="hi">
								<div class="field-value-span">
									<div class="editable editable-four-column editable-left">
										岗位：{{hope.job}} 
									</div>
									<div class="editable editable-four-column editable-center">
										地点：{{hope.location}} 
									</div>
									<div class="editable editable-four-column editable-center">
										薪资：{{hope.salary}} 
									</div>
									<div class="editable editable-four-column editable-right">
										性质：{{hope.type}} 
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tbody" data-block="single-1" v-if="section.type=='self'" @click="sectionSelect(si)">
						<div class="tr">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable">{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si">
							
						</div>
						<div class="tr">
							<div class="td field-value-td">
								<div class="field-value-span" v-for="data,di in section.data" :key="di">
									<div class="editable editable-one-column">{{data.content}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tbody" data-block="single-1" v-if="section.type=='hobby'" @click="sectionSelect(si)">
						<div class="tr">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable">{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si"> 
						</div>
						<div class="tr">
							<div class="td field-value-td">
								<div class="field-value-span" v-for="data,di in section.data" :key="di">
									<div class="editable editable-one-column">{{data.content}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tbody" data-block="single-1" v-if="section.type=='prize'" @click="sectionSelect(si)">
						<div class="tr">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable" >{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si">
							
						</div>
						<div class="tr">
							<div class="td field-value-td">
								<div class="field-value-span" v-for="prize,pi in section.data" :key="pi">
									<div class="editable editable-one-column">
									{{prize.date}} : {{prize.prize}}
									</div>
								</div> 
							</div>
						</div>
					</div>
					<div class="tbody" data-block="union-1" v-if="section.type=='edu'" @click="sectionSelect(si)">						
						<div class="tr">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable" >{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si">
							
						</div>
						<div class="tr" v-for="edu,ei in section.data" :key="ei">
							<div class="td field-value-td">
								<div class="field-value-span">
									<div class="editable editable-three-column editable-left">
										{{edu.date}}
									</div>
									<div class="editable editable-three-column editable-center">
										{{edu.school}}
									</div>
									<div class="editable editable-three-column editable-right">
										{{edu.degree}} - {{edu.major}}
									</div>
									<div class="editable editable-one-column" v-if="edu.lessons">
										主修课程：{{edu.lessons}}
									</div>
									<div class="editable editable-one-column" v-if="edu.description">
										{{edu.description}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tbody" data-block="union-1" v-if="section.type=='project'" @click="sectionSelect(si)">						
						<div class="tr">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable" >{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si">
							
						</div>
						<div class="tr" v-for="project,pi in section.data" :key="pi">
							<div class="td field-value-td">
								<div class="field-value-span">
									<div class="editable editable-three-column editable-left">
										{{project.date}}
									</div>
									<div class="editable editable-three-column editable-center">
										{{project.project}}
									</div>
									<div class="editable editable-three-column editable-right">
										{{project.company}}
									</div>
									<div class="editable editable-one-column" v-if="project.description">
										{{project.description}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tbody" data-block="union-1" v-if="section.type=='job'" @click="sectionSelect(si)">						
						<div class="tr">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable" >{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si">
							
						</div>
						<div class="tr" v-for="job,ji in section.data" :key="ji">
							<div class="td field-value-td">
								<div class="field-value-span">
									<div class="editable editable-three-column editable-left">
										{{job.date}}
									</div> 
									<div class="editable editable-three-column editable-center">
										{{job.company}}
									</div> 
									<div class="editable editable-three-column editable-right">
										{{job.job}}
									</div> 
									<div class="editable editable-one-column" v-if="job.description">
										{{job.description}}
									</div>
									<div class="editable editable-one-column" v-if="job.projects">
										{{job.projects}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--技能-->
					<div class="tbody" data-block="three-2" v-if="section.type=='skill'&&template.plate=='single'" @click="sectionSelect(si)">						
						<div class="tr">
							<div  class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable">专业技能</div>
									<div class="title-icon-right"></div>
								 </div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si">
							
						</div>
						<div class="tr">
							<div class="td field-value-td" v-for="skill,ski in section.data" :key="ski">
								<div class="field-value-span">
									<div class="editable editable-three-column editable-left">{{skill.skill}}</div>
									<div class="editable editable-three-column editable-center uneditable">
										<div class="skill-replace"></div> 
										<div class="skill-level-bg">
											<div class="skill-level" style="width:25%" v-if="skill.level=='了解'"></div>
											<div class="skill-level" style="width:50%" v-if="skill.level=='掌握'"></div>
											<div class="skill-level" style="width:75%" v-if="skill.level=='熟练'"></div>
											<div class="skill-level" style="width:100%" v-if="skill.level=='精通'"></div>
										</div>
									</div>
									<div class="editable editable-three-column editable-right uneditable">
										<div class="skill-level-word">{{skill.level}}</div>
									</div>
								</div> 
							</div> 
						</div> 
					</div>
					<!--自定义单项-->
					<div class="tbody" data-block="single-1" v-if="section.type=='single'" @click="sectionSelect(si)">						
						<div class="tr">
							<div class="td field-title-td">
								<div class="field-title-wrap">
									<div class="title-icon-left">
										<div class="title-icon-inner"></div>
									</div>
									<div class="editable" >{{section.title}}</div>
									<div class="title-icon-right"></div>
								</div>
							</div>
						</div>
						  <div class="editBox" @touchstart="editBoxTouchStart"  @touchend="editBoxTouchEnd" v-if="sectionIndex===si"> 
						</div>
						<div class="tr">
							<div class="td field-value-td">
								<div class="field-value-span" v-for="data,di in section.data" :key="di">
									<div class="editable editable-one-column">
									{{data.content}}
									</div>
								</div> 
							</div>
						</div>
					</div>
					</template>
				</div>
				<div class="resume-top"></div> 
				<div class="resume-right"></div>
				<div class="resume-bottom"></div>
			</div>  
		</div>
	</div>
</div>
</template>

<script>
	import Api from "@/service/api.js"
	import {Icon} from "vant"
	export default {
		components:{Icon},
		name: 'preview',
		props:{
			 
			scale:{
				type:Number,
				default:1
			}, 
			sectionIndex:{
				type:Number,
				default:-1
			},
			cvHeight:{
				type:Number,
				default:0,
			},
			cvWidth:{
				type:Number,
				default:0
			},
			pageIndex:{
				type:Number,
				default:0
			},
			pages:{
				type:Array,
				default:Array()
			},
			page:{
				type:Object,
				default:{} 
			},
			template:{
				type:Object,
				default:{
					miniappTplId:''
				}
			},
			theme:{
				type:String,
				default:'5E8EE0'
			},
			renderIn:{
				type:String,
				default:''
			}
		}, 
		computed:{
		  	contentClass:function(){
				if(this.template){
					if(this.theme!='default'){
						//console.log("theme>>>",this.theme);
						return 'content '+this.template.miniappTplId+" "+this.template.miniappTplId+"_"+this.theme;
					}else{
						return "content "+this.template.miniappTplId;
					}
				}else{
					return 'content';
				}
			}
		},
		mounted:function(){
			this.Init();
		},
		watch:{
			page:function(){
				this.Init()
			}
		},
		data() {
			return { 
				touch:false
			};
		},
		methods: {
			async Init(){ 
				 
			},
			editBoxTouchStart(e){
				//console.log("touch start>>>",e);
				if(this.touch){
					let x = e.timeStamp-this.touch.timeStamp;
					//console.log(x);
					if(x<300){
						this.touch = false;
						this.$emit("sectionEditOpen");
					}
				}
				this.touch = e;
				
			}, 
			editBoxTouchEnd(e){
				this.touch = e;
			},
			sectionSelect(v){ 
				this.$emit('sectionSelect',v);
			},
			async pageDelete(){
				this.$emit('pageDelete',this.pageIndex);
			},
			 
		} 
	}
</script> 
<style lang="less" scoped>  
	@import './cv.css';
	@import './tpl/tpl100.css';
	@import './tpl/tpl101.css';
	@import './tpl/tpl102.css';
	@import './tpl/tpl103.css';
	@import './tpl/tpl104.css';
	@import './tpl/tpl105.css';
	@import './tpl/tpl106.css';
	@import './tpl/tpl107.css';
	@import './tpl/tpl108.css'; 
	@import './tpl/tpl109.css';
	@import './tpl/tpl110.css';
	@import './tpl/tpl111.css';
	@import './tpl/tpl112.css';
	@import './tpl/tpl113.css';
	@import './tpl/tpl114.css';
	@import './tpl/tpl115.css';
	@import './tpl/tpl116.css';
	@import './tpl/tpl117.css';
	@import './tpl/tpl118.css';
	@import './tpl/tpl119.css';
	@import './tpl/tpl120.css';
	@import "./tpl/tpl121.css";
	@import "./tpl/tpl122.css";
	@import "./tpl/tpl123.css";
	@import './tpl/tpl124.css';
	@import "./tpl/tpl125.css";
	@import "./tpl/tpl126.css";
	@import "./tpl/tpl127.css";
	@import "./tpl/tpl128.css";
	@import './tpl/tpl129.css';
	@import './tpl/tpl130.css';
	@import './tpl/tpl131.css';
	@import './tpl/tpl132.css';
	@import './tpl/tpl133.css';
	@import './tpl/tpl134.css';
	@import './tpl/tpl135.css';
	.container{
		position:relative;
		overflow: hidden;
		.preview{
			float:left;
			width:100%;
			height:100%;
		}
		.deletecover{
			position:absolute;
			right:0px;
			top:0px;
			height:40px;
			width:40px; 
			padding:0px 0px 5px 5px;
			text-align:center;
			background:rgba(0,0,0,0.6);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius:0% 0px 0px 100%;
			z-index:1;
		} 
		.pageInfo{
			position:absolute;
			bottom:0px;
			right:0px;
			height:18px;
			line-height:18px;
			padding:0px 10px 0px 10px;
			background:rgba(0,0,0,0.6);
			color:#fff;
			z-index:999;
			border-radius:20px 0px 0px 0px;
		}
	}
	
	.editBox{
		position:absolute;
		left:-2px;
		top:-2px;
		height:calc(100% + 4px);
		width:calc(100% + 4px);
		z-index:1; 
		border:4px dashed #1261ff!important; 
	}
</style>
