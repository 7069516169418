<template>
	<div class="container">
		<div class="header"> 
			<div class="left"> 
				<template v-if="nowPage.type=='page'"> 
					<div class="tool" @click="cvNameOpen()">
						<div class="icon">  
							<Icon name="edit" size="22"></Icon>
						</div>
						<div class="name">名称</div> 
					</div>
					<div class="tool" @click="templatesOpen()">
						<div class="icon"> 
							<Icon name="bag" size="22"></Icon>
						</div>
						<div class="name">模板</div> 
					</div>
					<div class="tool" @click="themesOpen()" v-if="nowPage.type=='page'">
						<div class="icon"> 
							<Icon name="fire" size="22" :color="cv.data.config.theme=='default'?'':'#'+cv.data.config.theme"></Icon>
						</div>
						<div class="name" :style="cv.data.config.theme=='default'?'':'color:#'+cv.data.config.theme+';'">配色</div> 
					</div>
					<div class="tool" @click="dataReload()" v-if="nowPage.type=='page'">
						<div class="icon"> 
							<Icon name="underway-o" size="22"></Icon>
						</div>
						<div class="name">重置</div> 
					</div>
				</template>
			</div>
			<div class="right"> 
				<div class="tool" @click="cvSave(true)">
					<div class="icon">
						<Icon name="checked" size="22" color="#1261ff"></Icon>
					</div>
					<div class="name">保存</div> 
				</div>
				<div class="tool" @click="send()">
					<div class="icon">
						<Icon name="share" size="22" color="#1261ff"></Icon>
					</div>
					<div class="name">生成</div> 
				</div>
			</div>
		</div> 
		<div class="center"> 
			<div class="tip" v-if="swipertip">
				小提示：左右滑动试试，可以编辑更多内容哦~
			</div>  
			<div class="paper" :style="'width:100%;height:'+(systemInfo.windowHeight-100)+'px;'">
				<Preview v-if="cv" renderIn="editor" @sectionEditOpen="sectionEditOpen" :scale="scale" @pageUpdate="pageUpdate" @pageDelete="pageDelete"  @letterDataEditOpen="letterDataEditOpen"  @letterImageChangeOpen="letterImageChangeOpen" @coverDataEditOpen="coverDataEditOpen"  @coverImageChangeOpen="coverImageChangeOpen" @pageAdd="pageAdd"  @pageSelect="pageSelect" :pageIndex="pageIndex"  :cvHeight="cvHeight" :cvWidth="cvWidth"   :sectionIndex="sectionIndex" @sectionDelete="sectionDelete" @sectionSelect="sectionSelect" @sectionUp="sectionUp" @sectionDown="sectionDown" :cv="cv"></Preview> 
			 </div>
			 
		</div>
		<div class="bottom" v-if="sectionIndex==-1&&nowPage.type=='page'">
			 <div class="pageTool" @click="sectionAddOpen()">
			 	<div class="c">
			 		<div class="icon">
			 			<Icon name="add-o" size="22" color="#fff"></Icon>
			 		</div>
			 		<div class="name">
			 			增加模块
			 		</div>
			 	</div>
			 </div>  
			 <div class="pageTool" @click="pageAdd()">
			 	<div class="c">
			 		<div class="icon">
			 			<Icon name="plus" size="22" color="#fff"></Icon>
			 		</div>
			 		<div class="name">
			 			增加一页
			 		</div>
			 	</div>
			 </div> 
		 </div> 
		<!--选中图层，且图层不为基本信息-->
		<div class="bottom" v-if="sectionIndex!=-1&&pageIndex!=-1&&nowPage.type=='page'&&nowPage.sections[sectionIndex].type!='baseinfo'">
			<div class="sectionTool" @click="sectionEditOpen()">
				<div class="c">
					<div class="icon">
						<Icon name="edit" color="#fff" size="22"></Icon>
					</div>
					<div class="name">
						修改模块
					</div>
				</div>
			</div>
			 <div class="sectionTool" @click="sectionUp()" v-if="nowPage.sections[sectionIndex].type!='baseinfo'">
			 	<div class="c">
			 		<div class="icon">
			 			<Icon name="arrow-up"  color="#fff" size="22"></Icon>
			 		</div>
			 		<div class="name" style="color:#fff;">
			 			上移模块
			 		</div>
			 	</div>
			 </div>
			 <div class="sectionTool" @click="sectionDown()">
			 	<div class="c">
			 		<div class="icon">
			 			<Icon name="arrow-down" color="#fff" size="22"></Icon>
			 		</div>
			 		<div class="name" style="color:#fff;">
			 			下移模块
			 		</div>
			 	</div>
			 </div>
			 <div class="sectionTool" @click="sectionDelete()">
			 	<div class="c">
			 		<div class="icon">
			 			<Icon name="cross" color="#fff" size="22"></Icon>
			 		</div>
			 		<div class="name">
			 			删除模块
			 		</div>
			 	</div>
			 </div>
		</div> 
		<div class="bottom" v-if="sectionIndex!==-1&&pageIndex!==-1&&cv.data.pages[pageIndex].sections[sectionIndex].type=='baseinfo'">
			<div class="sectionTool" @click="sectionEditOpen()">
				<div class="c">
					<div class="icon">
						<Icon name="edit" color="#fff" size="22"></Icon>
					</div>
					<div class="name">
						修改资料
					</div>
				</div>
			</div> 
			<div class="sectionTool" @click="baseinfoTitleShowChange()" v-if="cv.data.pages[1].sections[0].titleShow">
				<div class="c">
					<div class="icon">
						<Icon name="closed-eye" color="#fff" size="22"></Icon>
					</div>
					<div class="name">
						隐藏标题
					</div>
				</div>
			</div>
			<div class="sectionTool" @click="baseinfoTitleShowChange()" v-if="!cv.data.pages[1].sections[0].titleShow">
				<div class="c">
					<div class="icon">
						<Icon name="eye" color="#fff" size="22"></Icon>
					</div>
					<div class="name">
						显示标题
					</div>
				</div>
			</div> 
		</div>
		<Popup ref="templates" :close-on-click-overlay="false"  position="bottom" v-model="templatesShow" v-if="templatesShow">
			<Templates v-if="cv&&templatesShow"  ref="templatesref" @sure="templateChange" @close="templatesClose" :cv="cv"></Templates>
		</Popup> 
		<Popup ref="themes" :close-on-click-overlay="false" position="top" v-if="themesShow" v-model="themesShow">
			<Themes  :cv="cv" ref="themesref"  v-if="cv&&themesShow" @close="themesClose" @sure="themeChange"></Themes>
		</Popup>
		<Popup ref="sections" :close-on-click-overlay="false" v-if="sectionsShow" v-model="sectionsShow"  position="bottom">
			<Sections   @sure="sectionAdd" @close="sectionAddClose" v-if="sectionsShow"></Sections>
		</Popup>
		<Popup ref="sectioneditor" :close-on-click-overlay="false" position="bottom" v-if="sectionEditor" v-model="sectionEditor">
			<SectionEditor v-if="sectionEditor" :section="cv.data.pages[pageIndex].sections[sectionIndex]" @sure="sectionUpdate" @close="sectionEditClose"></SectionEditor>
		</Popup>
		<Popup ref="coverdata"  position="bottom" v-if="coverDataEditShow" v-model="coverDataEditShow">
			<CoverDataEditor v-if="nowPage.type=='cover'&&coverDataEditShow" :page="cv.data.pages[pageIndex]" @sure="coverDataEditSure" @close="coverDataEditClose"></CoverDataEditor>
		</Popup>
		<Popup ref="coverimages" position="bottom" v-if="coverimagesShow" v-model="coverimagesShow">
			<CoverImages v-if="nowPage.type=='cover'" :page="cv.data.pages[pageIndex]" @sure="coverImageChangeSure" @close="coverImageChangeClose"></CoverImages>
		</Popup>
		<Popup ref="letterdata" v-if="letterdataShow" v-model="letterdataShow"  position="bottom"> 
			<LetterDataEditor v-if="nowPage.type=='letter'" @pageUpdate="pageUpdate" :page="cv.data.pages[pageIndex]" @sure="letterDataEditSure" @close="letterDataEditClose"></LetterDataEditor>
		</Popup>
		<Popup ref="letterimages" position="bottom" v-if="letterimagesShow" v-model="letterimagesShow">
			<LetterImages v-if="nowPage.type=='letter'" :page="cv.data.pages[pageIndex]" @sure="letterImageChangeSure" @close="letterImageChangeClose"></LetterImages>
		</Popup>
		<Popup ref="export" position="bottom" v-if="exportShow" v-model="exportShow">
			<Export v-if="exportShow" :cvId="cvId" @sure="exportSure" @close="exportClose"></Export>
		</Popup>
		<Popup  ref="cvname" position="center" v-if="cvNameShow" v-model="cvNameShow">
			<CvName :name="cv.name" v-if="cv&&cvNameShow"  @close="cvNameClose" @sure="cvNameSure"></CvName>
		</Popup>
		<Login :show="loginShow" @success="loginSuccess" @close="loginClose"></Login> 

	</div>
</template> 
<script> 
	import Login from '@/components/login/login.vue';
	import User from '@/service/user.js';
	import Api from '@/service/api.js';
	import Preview from "@/components/cv/preview/index.vue"
	import { Popup } from 'vant';
	import Templates from "./plugin/templates.vue"
	import Sections from "./plugin/sections.vue"
	import SectionEditor from "@/components/cv/page/index.vue"
	import CoverDataEditor from "@/components/cv/cover/data.vue"
	import CoverImages from "@/components/cv/cover/image.vue"
	import LetterImages from "@/components/cv/letter/image.vue"
	import LetterDataEditor from "@/components/cv/letter/data.vue"
	import Themes from "./plugin/themes.vue"
	import Utils from "@/components/cv/utils.js" 
	import Export from "@/components/cv/export/index.vue"
	import CvName from "./plugin/cvname.vue"
	import {Icon,Toast,Dialog,ActionSheet}  from "vant" 
	export default { 
		components:{ActionSheet,Dialog,Toast,Popup,Icon,Preview,Login,CvName,Export,Templates,Sections,SectionEditor,Themes,CoverDataEditor,CoverImages,LetterDataEditor,LetterImages},
		data() {
			return {
				loginShow:false,
				styles:[],
				cvId:false,
				cv:false, 
				systemInfo:false, 
				scale:1,
				cvWidth:0,
				cvHeight:0,
				sectionIndex:-1,
				pageIndex:1,
				templatesShow:false,
				sectionsShow:false, 
				sectionEditor:false, 
				themesShow:false,
				exportShow:false,
				cvNameShow:false,
				swipertip:true,
				coverDataEditShow:false,
				coverimagesShow:false,
				letterdataShow:false,
				letterimagesShow:false,
			} 
		},
		computed:{
			nowPage:function(){
				if(this.cv&&this.pageIndex!=-1){
					return this.cv.data.pages[this.pageIndex];
				}else{
					return false;
				}
			},
			
		},
		created(){
			this.Init();
		},
		methods: {  
			async send(){
				var that =this;
				that.cvSave();
				Api.query("/open/cv/thumbnail",{cvId:this.cvId}); 
				that.exportOpen();
			}, 
			async dataReload(){
				var that = this;
				Dialog.confirm({
					title:"温馨提示",
					message:"重置内容会删除已经填写的内容，并恢复到模板原始状态，确定重置？",
				}).then(async function(res){
						if(res){
							let data = await Api.query("/open/config/defaultCv",{});
							if(data){
								let cv = JSON.parse(JSON.stringify(that.cv));
								cv.data = JSON.parse(data);
								that.cv = cv;
								that.$forceUpdate();
								Toast.success("重置成功");
							}
						}
				});
				 
			},
			async cvNameOpen(){
				this.cvNameShow = true;
			},
			async cvNameClose(){
				this.cvNameShow = false;
			},
			async cvNameSure(name){  
				if(name.length<1||name.length>50){
					Toast.failt('不能为空，且需要小于25个字')
					return false;
				}
				this.cv.name = name; 
				this.cvNameClose();
				this.$forceUpdate();
				this.cvSave();
			},
			async exportOpen(){
				this.exportShow = true;
			},
			async exportClose(){
				this.exportShow = false;
			},
			async exportSure(){
				this.exportClose();
			}, 
			async themesOpen(){ 
				this.themesShow = true;
			},
			async baseinfoTitleShowChange(){
				let cv = JSON.parse(JSON.stringify(this.cv));
				cv.data.pages[this.pageIndex].sections[this.sectionIndex].titleShow = !cv.data.pages[this.pageIndex].sections[this.sectionIndex].titleShow;
				this.cv = cv;
				this.$forceUpdate();
			},
			async themesClose(){
				this.themesShow = false;
			},
			async themeChange(theme){
				let config = JSON.parse(JSON.stringify(this.cv.data.config));
				config.theme = theme;
				this.configUpdate(config); 
			}, 
			async templatesOpen(){ 
				this.templatesShow = true;
			},
			async templatesClose(){
				this.templatesShow = false;
			},
			async templateChange(templateId){
				let cv = JSON.parse(JSON.stringify(this.cv));
				cv.templateId = templateId;
				this.cv  = cv;
				this.$forceUpdate();
			},
			//自荐信修改 
			async letterDataEditOpen(){ 
				this.letterdataShow=true;
			},
			async letterDataEditClose(){
				this.letterdataShow=false;
			},
			async letterDataEditSure(data){ 
				this.letterDataEditClose();
				this.pageUpdate(data);
			},
			async letterImageChangeOpen(){
				this.letterimagesShow = true;
			},
			async letterImageChangeClose(){
				this.letterimagesShow = false;
			},
			async letterImageChangeSure(image){ 
				let cv = JSON.parse(JSON.stringify(this.cv));
				cv.data.pages[this.pageIndex].image = image; 
				this.cv = cv; 
				this.$forceUpdate();
			},
			//封面修改
			async coverDataEditOpen(){
				this.coverDataEditShow = true;
			},
			async coverDataEditClose(){
				this.coverDataEditShow = false;
			},
			async coverDataEditSure(data){  
				this.pageUpdate(data);
			},
			async coverImageChangeOpen(){ 
				this.coverimagesShow = true;
			},
			async coverImageChangeClose(){
				this.coverimagesShow = false;
			},
			async coverImageChangeSure(image){ 
				this.cv.data.pages[this.pageIndex].image = image; 
				this.$forceUpdate();
			},
			async coverShowChange(){
				this.cv.data.pages[0].show = !this.cv.data.pages[0].show;
				this.$forceUpdate();
			},
			async cvSave(showMessage=false){ 
				if(showMessage!==false){
					Toast.loading();
				} 
				let param = {
					cvId:this.cvId,
					name:this.cv.name,
					data:JSON.stringify(this.cv.data), 
					templateId:this.cv.templateId 
				}
				let result = await Api.query("/open/cv/save",param);
				if(showMessage!==false){
					Toast.clear();
				} 
				if(result){
					if(showMessage!==false){
						Toast.success('保存成功');
					}
				}else{
					Toast.fail('保存失败');
				}
			}, 
			/**页面部分开始**/
			async pageUpdate(page,pageIndex=false){
				if(pageIndex===false){
					pageIndex =  this.pageIndex
				}
				let cv = JSON.parse(JSON.stringify(this.cv));
				cv.data.pages[pageIndex] = page;
				this.cv = cv;
				this.$forceUpdate();
			},
			async pageAdd(){
				let page = {
					type:"page",
					sections:[]
				};
				//判断共有多少个type为page 的页面
				let items = this.cv.data.pages.filter(item=>{
					if(item.type=='page'){
						return item;
					}
				}); 
				let newIndex = items.length+1; 
				this.cv.data.pages.splice(newIndex,0,page); 
				this.pageSelect(newIndex);
				this.$forceUpdate()
			},
			async pageSelect(i){
				this.sectionSelect(-1);
				this.swipertip = false;
				this.pageIndex= i;
			},
			async pageDelete(pageIndex=false){
				 
				if(pageIndex===false){
					pageIndex = this.pageIndex
				}
				if(pageIndex==1||pageIndex==0){
					Toast.fail('禁止删除');
					return false;
				}else{
					var that =this;
					Dialog.confirm({
						title: '删除警告',
						message: '删除后无法恢复，确定删除？', 
					}).then(function(res){ 
						if (res) { 
							that.sectionSelect(-1);
							let cv = JSON.parse(JSON.stringify(that.cv));
							that.pageSelect(pageIndex-1);
							cv.data.pages.splice(pageIndex,1);
							that.cv = cv;
							that.$forceUpdate();
							Toast.success("删除成功");
						} 
					});
					
				}
			},
			//section 开始
			async sectionUpdate(section,sectionIndex=false,pageIndex=false){
				if(sectionIndex===false){
					sectionIndex = this.sectionIndex
				}
				if(pageIndex===false){
					pageIndex = this.pageIndex;
				} 
				let cv = JSON.parse(JSON.stringify(this.cv));
				cv.data.pages[pageIndex].sections[sectionIndex] = section;
				this.cv = cv;
				this.$forceUpdate();
			},
			async sectionEditOpen(sectionIndex=false){
				var that =this;
				if(sectionIndex!==false){
					that.sectionSelect(sectionIndex);
				}
				that.sectionEditor = true; 
			},
			async sectionEditClose(){ 
				this.sectionEditor = false; 
			}, 
			async sectionAddOpen(){
				this.sectionsShow = true;
			},
			async sectionAddClose(){
				this.sectionsShow = false;
			},
			async sectionAdd(section){
				let cv = JSON.parse(JSON.stringify(this.cv));
				cv.data.pages[this.pageIndex].sections.push(section);
				this.cv = cv;
				this.sectionAddClose();
				this.$forceUpdate();
			},
			sectionSelect(v){ 
				this.sectionIndex = v;
			},
			sectionUp(si=false){
				if(si===false){
					si = this.sectionIndex;
				}
				let page = JSON.parse(JSON.stringify(this.cv.data.pages[this.pageIndex])); 
				let section = JSON.parse(JSON.stringify(page.sections[si])); 
				if(si!=0){
					//复制前一个元素到当前元素前面
					let newSection = page.sections[si-1];
					//如果上一个是baseinfo
					if(newSection.type=='baseinfo'){
						Toast.fail("已经是第一个了");
						return false;
					}
					page.sections.splice(si+1,0,newSection);
					page.sections.splice(si-1,1);
					this.sectionIndex = si-1;
					this.cv.data.pages[this.pageIndex] = page;
					this.$forceUpdate();
				}
			},
			sectionDelete(si=false){
				if(si===false){
					si = this.sectionIndex;
				}
				var that =this;
				let page = JSON.parse(JSON.stringify(that.cv.data.pages[that.pageIndex]));
				let section = JSON.parse(JSON.stringify(page.sections[si]));
				if(section.type=='baseinfo'){
					Toast.fail("基本资料不支持删除");
					return false;
				} 
				Dialog.confirm({
					title: '删除警告',
					message: '删除后无法恢复，确定删除？',
						
				}).then(function(res){ 
					if (res) { 
						page.sections.splice(si,1);
						that.cv.data.pages[that.pageIndex] = page;
						that.sectionSelect(-1);
						that.$forceUpdate();
					}
				});
				 
			},
			sectionDown(si=false){
				if(si===false){
					si = this.sectionIndex;
				}
				let page = JSON.parse(JSON.stringify(this.cv.data.pages[this.pageIndex])); 
				let section = JSON.parse(JSON.stringify(page.sections[si]));
				if(section.type=='baseinfo'){
					Toast.fail("基本资料不支持移动");
					return false;
				}
				if(si==this.cv.data.pages[this.pageIndex].sections.length-1){
					Toast.fail("已经在最后一个了");
					return false;
				}
				let newSection = section;
				//复制当前元素到下一个元素后面 
				page.sections.splice(si+2,0,section);
				page.sections.splice(si,1);
				this.sectionIndex = si+1;
				this.cv.data.pages[this.pageIndex] = page;
				this.$forceUpdate();
			},
			async configUpdate(config){ 
				var data = JSON.parse(JSON.stringify(this.cv.data));
				data.config = config;
				this.cv.data = data;
				this.$forceUpdate();
			},
			async Init(){
				this.cvId = this.$route.params.cvId; 
				this.cvInit(); 
				var that =this;
				let l = await User.checkLogin();
				if(!l){
					that.loginShow = true;
					that.loginSuccess = function(){
						that.getCv();
					}; 
					return false;
				}
				this.getCv();
				
			},
			async getCv(){
				let cv = await Api.query('/open/cv/detail',{cvId:this.cvId});
				if(!cv){
					Toast.fail("错误简历");
					return false;
				} 
				if(!cv.data){
					let result = await Api.query("/open/config/defaultCv",{});
					if(result){
						cv.data  =JSON.parse(result);
					}else{
						cv.data =Utils.DEFAULTCV; 
					} 
				}
				this.cv = cv;
				if(this.cv.name==''||this.cv.name=='新建简历'){
					this.cvNameOpen();
				}
				
			}, 
			async cvInit(){ 
				//计算paper大小 
				this.systemInfo = {
					windowHeight:window.innerHeight,
					windowWidth:window.innerWidth
				}; 
				this.cvHeight = (window.innerHeight-100)*0.8;
				this.cvWidth = this.cvHeight/(1345/950);
				this.scale = this.cvWidth/950; 
			},
			loginSuccess(){
				this.loginShow= false;
			},
			loginClose(){
				this.loginShow=false;
			},
		}
	}
</script>

<style lang="less" scoped> 
	.container{
		height:100vh; 
		overflow: hidden; 
		background:#f5f5f5;
	}
	.header{
		float:left;  
		width:100%;
		height:50px;
		background:#ffffff; 
		.left{
			float:left;
			width:calc(100% - 120px);
			height:50px;
			padding:5px;
			display: flex;
			align-items: center;
			justify-content: center;
			.tool{
				float:left;
				width:60px;
				height:40px; 
				border-right:1px solid #f5f5f5;
				.icon{
					float:left;
					width:100%;
					height:24px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.name{
					float:left;
					width:100%;
					height:16px;
					line-height:16px;
					text-align:center; 
					font-size:12px;
				} 
			}
			.tool:last-child{
				border:none;
			}
		}
		.right{
			float:left;
			width:120px;
			height:50px;
			padding:5px 0px 5px 0px;
			.tool{
				float:left;
				width:60px;
				height:40px; 
				border-right:1px solid #f5f5f5;
				.icon{
					float:left;
					width:100%;
					height:24px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.name{
					float:left;
					width:100%;
					height:16px;
					line-height:16px;
					text-align:center; 
					font-size:12px;
					color:#1261ff;
					font-weight:bold;
				} 
			}
			.tool:last-child{
				border:none;
			}
		}
	} 
	.center{
		float:left;
		width:100%;
		height:calc(100% - 100px); 
		display: flex;
		align-items: center;
		justify-content: center;
		position:relative;
		.tip{
			position:absolute;
			left:0px;
			top:0px;
			height:30px;
			line-height:30px; 
			width:100%;
			z-index:1;
			color:#F06600;
			text-align:center;
			 
		}
		.paper{
			float:left;
			background:#fff;
			overflow: hidden;
		}
		.mask{
			position:absolute; 
			left:0;
			top:0;
			width:100%;
			height:100%;
		}
	}
	.bottom{
		float:left;
		width:100%;
		height:50px; 
		padding:5px;
		background:#fff;
		background:#1261ff;
		.pageTool,.sectionTool{
			float:left;
			width:calc(100% / 4);
			height:40px;
			display:flex;
			align-items:center;
			justify-content:center;
			.c{
				float:left;
				width:60px;
				height:40px;
				.icon{
					float:left;
					width:100%;
					height:28px;
					display:flex;
					align-items:center;
					justify-content:center;
				}
				.name{
					float:left;
					width:100%;
					height:12px;
					text-align:center;
					line-height:12px;
					color:#fff;
				}
			}
		}
	}
</style>
