<template>
	<div class="container">
		<div class="header">
			<NavBar
		:title="template?template.name:'简历详情'"
		left-text="返回" 
		left-arrow
		@click-left="back()"  
		/> 
		</div>
		<div class="detail">
			<div class="page" :style="'width:'+cvWidth+'px;height:'+cvHeight+'px;'">
				<Page :pages="data.pages" :cvHeight="cvHeight" v-if="template" :theme="data.config.theme" :template="template" :cvWidth="cvWidth" :page="data.pages[1]"></Page>
			</div>  
			<!--<div class="tip">
				 创建后可以更换模板样式，不会丢失数据
			</div>-->
		</div> 
		<div class="info">
			<div class="nv">
				<div class="n">模板名称：</div>
				<div class="v">{{template.name}}</div>
			</div>
			<div class="nv">
				<div class="n">配色支持：</div>
				<div class="v">
					<div class="colors">
						<div class="color" v-for="theme,ti in themes" :style="'background-color:#'+theme+';'" :key="ti" @click="themeChange(theme)" v-if="theme!='default'"></div> 
					</div>
				</div>
			</div>
			<div class="nv">
				<div class="n">封面：</div>
				<div class="v">支持添加/支持删除</div>
			</div>
			<div class="nv">
				<div class="n">求职信：</div>
				<div class="v">支持添加/支持删除</div>
			</div>
			<div class="nv">
				<div class="n">生成下载：</div>
				<div class="v">支持</div>
			</div> 
		</div>
		<div class="action">
			<div class="collect" @click="collectDo()">
				<div class="icon">
					<Icon name="star" v-if="collect" size="22" color="#E43D33"/>
					<Icon name="star-o" v-if="!collect" size="22" color="#E43D33"/>
				</div>
				<div class="tip" v-if="!collect">
					收藏模板
				</div>
				<div class="tip" v-if="collect">
					已收藏
				</div>
			</div>
			<div class="create" @click="create()">
				<div class="btn">使用此模板制作</div>
			</div>
		</div>
		<Login :show="loginShow" @success="loginSuccess" @close="loginClose"></Login> 
	</div>
</template>

<script> 
	import { Icon,Toast,NavBar } from 'vant';
	import Login from '@/components/login/login.vue';
	import User from '@/service/user.js';
	import Api from '@/service/api.js';
	import Utils from "@/components/cv/utils.js"
	import Page from "@/components/cv/preview/page.vue" 
	export default {
		components:{Page,Icon,Login,NavBar},
		data() {
			return {
				loginShow:false,
				template:false,
				templateId:false,
				data:{},
				cvWidth:0,
				cvHeight:0,
				scale:1,
				collect:false,
				themes:Utils.THEMES
			} 
		},
		created(){
			this.getData();
		},
		methods: {
			async back(){
				this.$router.go(-1)
			},
			async themeChange(theme){
				this.data.config.theme = theme;
			},
			async create(){
				let l = await User.checkLogin();
				if(!l){
					this.loginShow = true;
					return false;
				} 
				let r = await Api.query("/open/cv/create",{templateId:this.templateId,data:JSON.stringify(this.data)}); 
				if(r){
					Toast.success('创建成功');
					this.$router.push({path:"/editor/"+r.cvId}) 
				}else{
					Toast.fail('简历创建失败');
				}
			},
			async collectDo(){ 
				if(await User.checkLogin()){
					let r = await Api.query('/open/collect/collect',{type:'template',templateId:this.templateId});
					if(r){
						this.collect = !this.collect;
					} 
					Toast.success(this.collect?'收藏成功':"取消成功")
					
				}else{
					this.loginShow = true;
				}
			},
			async getData(){  
				//获取页面参数 
				let data = await Api.query("/open/config/defaultCv");
				this.data = JSON.parse(data);
				let width = window.innerWidth*0.6;
				let height = width*(1345/950);
				this.cvWidth = width;
				this.cvHeight = height;
				this.scale = width/950;   
				 this.templateId = this.$route.params.templateId;  
				this.template = await Api.query('/open/template/detail',{templateId:this.templateId}); 
				this.collectGet();
			},
			async collectGet(){
				//判断是否收藏
				if(await User.checkLogin()){
					let r = await Api.query('/open/collect/is',{type:'template',templateId:this.templateId});
					if(r){
						this.collect = true;
					}else{
						this.collect = false;
					}
					
				}else{
					this.collect = false;
				}
			},
			loginSuccess(){
				this.collectGet();
				this.loginShow= false;
			},
			loginClose(){
				this.loginShow=false;
			},
		}
	}
</script>

<style lang="less" scoped>
	.container{
		min-height:100vh;
		background:#f3f5fa; 
		padding-bottom:40px;
	} 
	.info{
		float:left;
		width:100%;
		padding:10px;
		background:#fff;
		.nv{
			float:left;
			width:100%; 
			border-bottom:1px solid #f5f5f5;
			.n{
				float:left;
				width:70px;
				height:38px;
				line-height:38px;
				 font-weight:bold; 
			}
			.v{
				float:left;
				width:calc(100% - 70px);
				height:38px;
				line-height:38px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight:bold;
				
			}
		}
	}
	.colors{
		float:left; 
		height:38px; 
		width:100%;
		overflow-x: scroll;
		white-space: nowrap;
		.color{
			display: inline-block;
			width:32px;
			height:32px;
			margin:3px;
			border-radius:50%; 
		}
	}
	.detail{
		float:left;
		width:100%;
		padding:20px 10px 20px 10px;
		display: flex;
		align-items: center;
		justify-content: center; 
		position:relative;
		overflow: hidden;
		.page{
			float:left;
		}
		.tip{
			position:absolute;
			bottom:0px;
			height:20px;
			line-height:20px;
			width:100%;
			left:0px;
			text-align:center;
			color:#a5a5a5;
		}
	}
	.action{
		position:fixed;
		left:0px;
		bottom:0px;
		width:100%;
		height:48px;
		padding:5px;
		display: flex;
		align-items: center;
		justify-content: center;
		background:#fff;
		border-top:1px solid #f5f5f5;
		.collect{
			float:left;
			width:120px;
			height:38px;
			border-radius:19px;
			border:1px solid #E43D33;
			.icon{
				float:left;
				width:50px;
				height:38px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.tip{
				float:left;
				width:calc(100% - 50px);
				height:38px; 
				line-height:38px; 
				color:#E43D33;
				font-weight:bold; 
			}
		}
		.create{
			float:left;
			width:calc(100% - 120px);
			height:30px;
			padding-left:10px;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				float:left;
				width:100%;
				height:38px;
				background: #1261ff;
				color:#fff;
				text-align:center;
				line-height:38px;
				border-radius:38px;
			}
		}
		
	}
	
	
</style>
