<template>
	<div class="templates"> 
		<div class="title">
			切换模板
			<div class="close" @click="close()" style="color:#666;">
				取消
			</div> 
			<div class="sure" @click="sure()" style="color:#1261ff;font-weight:bold;">
				确定
			</div> 
		</div>
		<div  class="types">
			<div :class="typeId==''?'type active':'type'" @click="typeChange('')">所有</div>
			<div :class="typeId==type.typeId?'type active':'type'" v-for="type,ti in types" :key="ti" @click="typeChange(type.typeId)">
				{{type.name}}
			</div> 
		</div>
		<div class="main" style="box-sizing: border-box;" >
			<div   class="template" v-for="template,si in templates" :key="si" @click="select(template.templateId)"> 
				<div class="c">
					<div class="select" v-if="cv.templateId==template.templateId">
						<Icon name="checkbox-filled" size="24" color="#fff"></Icon>
					</div>
					<div class="cover">
						<img :src="template.thumbnail" mode="aspectFit" class="img"/>
					</div>
					<div class="name">
						{{template.name}}
					</div>
				</div>
			</div>
			<div class="more">
				<div class="load" v-if="page<last_page" @click="load()">
					加载更多
				</div>
				<div class="tip" v-if="page>=last_page">
					没有更多了
				</div>
			</div>
		</div>
	</div>
</template>

<script> 
	import Api from '@/service/api.js' 
	import {Icon} from 'vant'
	export default {
		components:{Icon},
		name: 'templates',
		props:{
			cv:{
				type:Object,
				default:new Object()
			}, 
		}, 
		data() {
			return {
				templates:[],
				typeId:'',
				types:[],
				page:1,
				last_page:1,
				page_size:10,
				origin:false,
			};
		},
		mounted:function(){
			 this.Init();
		},
		methods: {
			async Init(){ 
				this.origin = this.cv.templateId;
				this.getTemplates();
				this.getTemplateType();
			},
			async setOrigin(v){
				this.origin = v;
			},
			async typeChange(typeId){
				this.typeId = typeId;
				this.page = 1;
				this.last_page = 1;
				this.templates = [];
				this.getTemplates();
			},
			async load(){
				if(this.page<this.last_page){
					this.page = this.page+1;
					this.getTemplates();
				}
			},
			async getTemplates(){
				var result =await Api.query("/open/template/index",{typeId:this.typeId,page_size:this.page_size,page:this.page}); 
				if(result){
					for(var i in result.data){
						this.templates.push(result.data[i]); 
					}
					this.last_page = result.last_page;
				}
				
			},
			async getTemplateType(){
				this.types =await Api.query("/open/template/types",{}); 
			},
			close(){
				this.$emit('sure',this.origin);
				this.$emit('close');
			}, 
			async select(templateId){ 
			 
				this.$emit('sure',templateId);
			},
			 async sure(){
				this.$emit('close');
			 },
		}
	}
</script> 
<style lang="less" scoped> 
 .templates{
		float:left; 
		background:#fff;
		width:100%;
		height:257px; 
		position:relative;
		border-radius:10px 10px 0px 0px;
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			border-radius:10px 10px 0px 0px;
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.types{
			float:left;
			width:100%;
			height:40px;
			padding:0px 10px 0px 10px;
			white-space:nowrap; 
			box-sizing: border-box;
			border-bottom:1px solid #f5f5f5;
			.type{
				display: inline-block; 
				height:40px;
				line-height:40px;
				padding:0px 5px 0px 5px;
				margin-right:10px;
			}
			.active{
				color:#1261ff;
			}
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 80px);
			overflow-x: scroll;
			white-space: nowrap;
			box-sizing: border-box;
			.more{
				display: inline-block;
				width:118px;
				height:167px;
				margin:5px;
				.load{
					float:left;
					width:100%;
					height:100%;
					border-radius:14px;
					text-align:center;
					background:#e6e9f2;
					color:#1261ff;
					line-height:192px;
				}
				.tip{
					float:left;
					width:100%;
					height:100%;
					text-align:center;
					color:#AAAAAA; 
					line-height:192px;
				}
			}
			.template{ 
				display: inline-block;
				width:118px;
				height:167px;
				margin:5px; 
				.c{
					float:left;
					width:118px;
					height:167px;
					position:relative;
					.select{
						position:absolute;
						left:0px;
						top:0px;
						height:100%;
						width:100%;
						background:rgba(0,0,0,0.3);
						display:flex;
						align-items: center;
						justify-content: center;
					}
					.cover{
						float:left;
						width:100%;
						height:100%;
						padding:2px;
						display: flex;
						align-items: center;
						justify-content: center;
						background:#f5f5f5;
						.img{
							width:100%;
							height:100%;
						}
					}
					.name{
						position:absolute;
						left:0px;
						bottom:0px;
						height:24px;
						line-height:24px;
						text-align:center;
						background:rgba(0,0,0,0.6);
						color:#fff;
						width:100%;
					}
				}
			}
			 
		}
	}
</style>
