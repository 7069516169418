<template>
<div class="container" :style="'width:'+cvWidth+'px;height:'+cvHeight+'px;'">
	<template v-if="renderIn=='editor'">
		<div class="ishide" v-if="!page.show">
			<div class="add" :style="'width:'+(cvWidth*0.8)+'px;height:'+(cvHeight*0.8)+'px;'" @click="coverShowChange()">
				<div class="c">
					<div class="icon">
						<Icon name="add" size="40" color="#1261ff"></Icon>
					</div>
					<div class="tip">
						增加封面
					</div>
				</div> 
			</div>
		</div> 
		<template v-if="page.show">
			<div class="imagechange" @click="coverImageChangeOpen()">更换背景</div>
			<div class="dataedit" @click="coverDataEditOpen()">修改内容</div>
			<div class="deletecover" @click="coverShowChange()">删除封面</div> 
		</template>
		<div class="pageInfo" v-if="page.show">
			封面
		</div>
	</template>
	<div class="preview" v-if="page.show" @click="sectionSelect()" :style="'width:'+cvWidth+'px;height:'+cvHeight+'px;transform:scale('+(cvWidth/950)+');transform-origin:0 0;'">
		<div class="cover" data-show="show" data-covid="1"> 
			<img class="cover-img" :src="page.image" width="100%" height="100%" mode="aspectFill"/>
			<div class="cover-info ui-draggable" :data-scale="scale" :style="'left: '+page.position.left+'px; top: '+page.position.top+'px;'"> 
				<div class="editBox" v-if="select" @touchmove.stop.prevent=""  @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd"></div> 
				<div class="cover-info-add"><i class="jlbfont icon-create" style="font-size:19px"></i></div>
				<div class="cover-info-move ui-draggable-handle"><i class="jlbfont icon-move" style="font-size:20px"></i></div>
				<div class="baseinfo-row" v-for="data,di in page.data" :key="di">
					<div class="baseinfo-label" contenteditable="true">{{data.name}}：</div>
					<div class="baseinfo-value" contenteditable="true">{{data.value}}</div> 
				</div> 
			</div>
		</div>
	</div> 
</div>
</template>

 
<script> 
	import Api from "@/service/api.js"
	import Images from "../cover/image.vue"
	import DataEditor from "../cover/data.vue"
	import {Icon} from "vant"
	export default {
		components:{Images,DataEditor,Icon},
		name: 'Preview',
		props:{
			scale:{
				type:Number,
				default:1
			}, 
			cvHeight:{
				type:Number,
				default:0,
			},
			cvWidth:{
				type:Number,
				default:0
			},
			pageIndex:{
				type:Number,
				default:-1
			},
			page:{
				type:Object,
				default:{}
			},
			sectionIndex:{
				type:Number,
				default:-1
			},
			renderIn:{
				type:String,
				default:''
			}
		}, 
		computed:{
		},
		mounted:function(){
			this.Init();
		},
		watch:{
			 
		},
		data() {
			return { 
				active:false,
				mouse:{
					x:0,y:0
				},
				select:false,
			};
		},
		methods: {
			async Init(){  
			},
			async touchStart(e){
				console.log(e);
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				this.mouse = {
					x:clientX,
					y:clientY,
				}
				return false;
			},
			async touchMove(e){
				//计算差值
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				let dx = (clientX - this.mouse.x)/this.scale;
				let dy = (clientY - this.mouse.y)/this.scale;
				let left = this.page.position.left+dx;
				let top = this.page.position.top+dy;
				this.positionUpdate({left:left,top:top});
				this.mouse = {
					x:clientX,
					y:clientY,
				}
				
				return false;
			},
			async touchEnd(e){ 
				//计算差值
				let clientX = e.changedTouches[0].clientX;
				let clientY = e.changedTouches[0].clientY;
				let dx = (clientX - this.mouse.x)/this.scale;
				let dy = (clientY - this.mouse.y)/this.scale;
				let left = this.page.position.left+dx;
				let top = this.page.position.top+dy;
				this.positionUpdate({left:left,top:top});
				this.mouse = {
					x:0,
					x:0,
				}
				return false;
			},
			async coverDataEditOpen(){
				this.$emit('coverDataEditOpen');
			}, 
			async coverImageChangeOpen(){
				this.$emit('coverImageChangeOpen');
			},  
			async coverShowChange(){
				let page = JSON.parse(JSON.stringify(this.page));
				page.show = !page.show;
				this.$emit('pageUpdate',page,this.pageIndex);
			},
			async positionUpdate(param){
				let page = JSON.parse(JSON.stringify(this.page));
				page.position.left = param.left;
				page.position.top= param.top; 
				this.$emit('pageUpdate',page,this.pageIndex);
			},
			async sectionSelect(){
				this.select = true;
			},  
			 
		} 
	}
</script> 
<style lang="less" scoped>  
	@import './cv.css';
	.container{
		position:relative;
		.preview{
			float:left;
			width:100%;
			height:100%;
			position:relative; 
		}
		.pageInfo{
			position:absolute;
			bottom:0px;
			right:0px;
			height:18px;
			line-height:18px;
			padding:0px 10px 0px 10px;
			background:rgba(0,0,0,0.6);
			color:#fff;
			z-index:1;
			border-radius:20px 0px 0px 0px;
			 
		}
		.imagechange{
			position:absolute;
			left:10px;
			bottom:44px;
			width:90px;
			height:24px;
			line-height:24px;
			text-align:center;
			background:#fff;
			border:1px solid #1261ff;
			color:#1261ff;
			border-radius:3px;
			font-size:12px;
			z-index:1;
		}
		.dataedit{
			position:absolute;
			left:10px;
			bottom:10px;
			width:90px; 
			height:24px;
			line-height:24px;
			text-align:center;
			background:#fff;
			border:1px solid #1261ff;
			color:#1261ff;
			border-radius:3px;
			font-size:12px;
			z-index:1;
		}
		.deletecover{
			position:absolute;
			right:10px;
			top:10px;
			height:24px;
			width:90px;
			line-height:24px;
			text-align:center;
			background:red;
			color:#fff;
			border-radius:3px;
			font-size:12px;
			z-index:1;
		} 
		
	}
	.ishide{
		float:left;
		width:100%;
		height:100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.add{
					 float:left;
					 display: flex;
					 align-items: center;
					 justify-content: center;
					 background:#fff;
					 border:5px solid #fff;
					 border-radius:10px;
					 
					 .c{
						 float:left;
						 width:100%;
						 height:90px;
						 .icon{
							 float:left;
							 width:100%;
							 height:60px;
							 display: flex;
							 align-items: center;
							 justify-content: center;
						 }
						 .tip{
							 float:left;
							 width:100%;
							 height:30px;
							 line-height:30px;
							 text-align:center;
							 color:#1261ff;
							 font-size:14px;
							 font-weight:bold;
						 }
						 
					 }
		}
	}
	.editBox{
		position:absolute;
		left:-2px;
		top:-2px;
		height:calc(100% + 4px);
		width:calc(100% + 4px);
		z-index:1; 
		border:4px dashed #1261ff!important; 
	}
</style>
