<template>
	<div class="container"> 
		<div  class="types">
			<div class="back flex-center-center" @click="back()">
				<Icon name="arrow-left" size="20"/>
			</div>
			<div  :class="typeId==''?'type active':'type'"  @click="typeChange('')">所有模板</div>
			<div :class="typeId==type.typeId?'type active':'type'" v-for="type,ti in types" :key="ti" @click="typeChange(type.typeId)">
				{{type.name}}
			</div>  
		</div>
		<!--<div class="search">
			<uni-search-bar placeholder="搜索想要的简历模板"></uni-search-bar>
		</div>-->
		<div class="templates"> 
			<div class="template" v-for="template,si in templates" :key="si">
				<div class="c" @click="detail(template.templateId)">
					<div class="cover">
						<img :src="template.thumbnail" mode="aspectFill" class="img"/>
					</div> 
				</div>
			</div>
		</div>
		<div class="more">
			<div class="load" v-if="page<last_page" @click="load()">
				加载更多
			</div>
			<div class="tip" v-if="page>=last_page">
				没有更多了
			</div>
		</div>
		 
	</div>
</template>

<script> 
	import { Icon } from 'vant';
	import User from '@/service/user.js';
	import Api from '@/service/api.js';
	export default {
		components:{Icon},
		data() {
			return {
				loginShow:false,
				templates:[],
				types:[],
				typeId:'',
				page:1,
				page_size:10,
				last_page:1
			}
		},
		created:function(){
			this.getData();
		},
		methods: {
			async back(){
				window.history.go(-1);
			},
			async detail(templateId){ 
				this.$router.push({path:"/template/detail/"+templateId}); 
			},
			async typeChange(typeId){
				this.typeId = typeId;
				this.page = 1;
				this.templates = [];
				this.getTemplates();
			},
			async getData(){
				this.getTemplates();
				//获取分类
				var tresult = await Api.query("/open/template/types",{});
				if(tresult){
					this.types = tresult;
				}
				this.getTemplates(); 
			},
			async load(){
				if(this.page<=this.last_page){
					this.page = this.page+1;
					this.getTemplates();
				}
			},
			async getTemplates(){
				var result = await Api.query('/open/template/index',{typeId:this.typeId,page_size:this.page_size,page:this.page});
				if(result){
					let templates = JSON.parse(JSON.stringify(this.templates));
					//templates.concat(result.data);
					for(var i in result.data){
						templates.push(result.data[i]);
					}
					this.templates = templates;
					this.last_page = result.last_page;
				} 
			},
			loginSuccess(){
				this.loginShow= false;
			},
			loginClose(){
				this.loginShow=false;
			},
		}
	}
</script>

<style lang="less" scoped>
	.types{
		position:fixed;
		left:0px;
		top:0px;
		width:100%;
		height:48px;
		padding:10px;
		overflow-x: scroll;
		white-space: nowrap;
		box-sizing: border-box;
		background:#fff;
		padding-left:48px; 
		z-index:9;
		.back{
			position:absolute;
			left:0px;
			top:0px;
			width:48px;
			height:48px; 
		}
		.type{
			display: inline-block;
			padding:0px 20px 0px 20px;
			margin-right:10px;
			height:28px;
			line-height:28px;
			
		}
		.active{
			background:#e6e9f2!important;
			color:#667cff;
			border-radius:15px;
		}
	}
	.search{
		float:left; 
		height:50px;
		width:100%;
		background:#fff;
	}
	.more{
		float:left;
		width:100%;
		height:60px;
		padding:10px;
		display: flex;
		align-items: center;
		justify-content: center;
		.load{
			float:left;
			width:240px;
			height:28px;
			border-radius:14px;
			text-align:center;
			background:#e6e9f2;
			color:#1261ff;
			line-height:28px;
		}
		.tip{
			float:left;
			width:240px;
			height:28px;
			 
			text-align:center;
			color:#AAAAAA; 
			line-height:28px;
		}
	}
	.templates{
		float:left;
		width:100%; 
		padding:10px; 
		margin-top:40px;
		.title{
			float:left;
			width:100%;
			height:30px;
			line-height:30px;
			border-bottom:1px solid #f5f5f5;
			text-align:center;
			font-weight:bold;
		}
		.template{
			float:left;
			width:calc(100% / 2);
			display:flex;
			align-items: center;
			justify-content: center;
			margin:10px 0px 10px 0px;
			
			.c{
				float:left;
				width:144px;
				height:202px;
				padding:2px;
				background:#fff; 
				border-radius:5px;
				.cover{
					float:left;
					width:140px;
					height:198px;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						width:140px;
						height:198px;
					}
				} 
			}
		}
	}
	.girds{
		float:left;
		width:calc(100% - 20px);
		height:80px;
		padding:10px;
		margin:10px; 
		.gird{
			float:left;
			width:calc(100% / 4);
			height:60px;
			display: flex;
			align-items: center;
			justify-content: center;
			.c{
				float:left;
				width:60px;
				height:60px;
				.icon{
					float:left;
					width:100%;
					height:40px;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						width:30px;
						height:30px;
					}
				}
				.name{
					float:left;
					width:100%;
					height:20px;
					line-height:20px;
					text-align:center;
				}
			}
		}
	}
</style>
