
import Api from "./api.js"
export default  {
	async checkLogin(){  
		let token = localStorage.getItem('USER_TOKEN');  
		if(!token){
			localStorage.removeItem('USER_INFO');
			localStorage.removeItem('USER_TOKEN');
			localStorage.removeItem('USER_OPENID'); 
			return false;
		}else{
			//通过token
			let result = await Api.query("/open/user/check"); 
			if(!result){
				//存储本地的用户数据
				localStorage.removeItem('USER_INFO');
				localStorage.removeItem('USER_TOKEN');
				localStorage.removeItem('USER_OPENID');  
				return false;
			}else{
				localStorage.setItem('USER_INFO',JSON.stringify(result)); 
				return result;  
			} 
		}
	}, 
	async logout(){
		localStorage.removeItem('USER_INFO');
		localStorage.removeItem('USER_TOKEN');
		localStorage.removeItem('USER_OPENID');  
		return true;
	},
	
}