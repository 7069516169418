<template>
	<div class="templates"> 
		<div class="main">
			<div :class="cv.data.config.theme==theme?'theme active':'theme'" v-for="theme,si in themes" :key="si" @click="select(theme)"> 
				<div class="c"  v-if="theme!='default'">
					<div class='color' :style="'background-color:#'+theme+';'"></div> 
				</div>
				<div  class="c"  v-if="theme=='default'" style="line-height:36px;text-align:center;">
					默认
				</div>
			</div>
		</div>
		<div class="title"> 
			<div class="close" @click="close()" style="color:#666666">
				取消
			</div> 
			<div class="sure" @click="sure()" style="font-weight:bold;color:#1261ff;">
				确定
			</div> 
		</div> 
	</div>
</template> 
<script> 
	import Api from '@/service/api.js';
	 import Utils from "@/components/cv/utils.js"
	export default {
		name: 'templates',
		props:{
			cv:{
				type:Object,
				default:{}
			} 
		}, 
		data() {
			return {
				template:[],
				themes:Utils.THEMES,
				origin:'default',
			};
		},
		mounted:function(){
			this.Init();
		},
		methods: {
			async Init(){
				 this.origin = this.cv.data.config.theme;
			},
			async setOrigin(v){
				this.origin = v;
			},
			close(){
				this.$emit('sure',this.origin);
				this.$emit('close');
			}, 
			async select(theme){ 
				this.$emit('sure',theme);
			}, 
			async sure(){
				this.$emit('close');
			},
		}
	}
</script> 
<style lang="less" scoped> 
 .templates{
	float:left; 
		background:#fff;
		width:100%;
		height:100%; position:relative; 
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-top:1px solid #F5F5F5;
			position:relative; 
			background:#fff; 
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 40px);
			overflow-y: scroll;
			white-space: nowrap;
			.theme{
				display: inline-block; 
				margin:5px;
				padding:2px;
				border:2px solid #a5a5a5;				
				border-radius:50%;
				.c{
					float:left;
					width:36px;
					height:36px;
					position:relative; 
					.color{
						float:left;
						width:100%;
						height:100%; 
						border-radius:50%;
					}
					.select{
						position:absolute;
						left:0px;
						top:0px;
						height:100%;
						width:100%;
						background:rgba(0,0,0,0.6);
						display:flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			.active{
				border-color:#1261ff;
				.c{
					color:#1261ff;
				}
			}
			 
		}
	}
</style>
