<template>
<div class="container" :style="'width:'+cvWidth+'px;height:'+cvHeight+'px;'">
	<template v-if="renderIn=='editor'">
		<div class="ishide" v-if="!page.show">
			<div class="add" :style="'width:'+(cvWidth*0.8)+'px;height:'+(cvHeight*0.8)+'px;'" @click="letterShowChange()">
				<div class="c">
					<div class="icon">
						<Icon name="add" size="40" color="#1261ff"></Icon>
					</div>
					<div class="tip">
						增加求职信
					</div>
				</div> 
			</div>
		</div> 
		<template v-if="page.show">
			<div class="imagechange" @click="letterImageChangeOpen()">更换背景</div>
			<div class="dataedit" @click="letterDataEditOpen()">修改内容</div>
			<div class="deletecover" @click="letterShowChange()">删除求职信</div>  
		</template>
		<div class="pageInfo" v-if="page.show">
			求职信
		</div> 
	</template> 
	<div class="preview" v-if="page.show" :style="'width:'+cvWidth+'px;height:'+cvHeight+'px;transform:scale('+(cvWidth/950)+');transform-origin:0 0;'">
		<div class="letter" data-show="show" data-letid="0">
			<img class="cover-img" :src="page.image" mode="aspectFill"/>
			<div class="letter-title editable">
				{{page.data.title}}
			</div>
			<div class="letter-subtitle editable">
				{{page.data.subTitle}}
			</div>
			<div class="letter-content editable">
				 <div class="rise">
					 {{page.data.rise}}
				 </div>
				 <div class="contents">
					 <div class="line" v-for="line,li in page.data.contents" :key="li" editable="true">
						 {{line}}
					 </div> 
				</div>  
			</div>
			<div class="letter-footer editable" contenteditable="true">
				{{page.data.sign}}
			</div> 
		</div>
	</div> 
</div>
</template>

<script>
	import Api from "@/service/api.js" 
	import {Icon} from "vant"
	export default { 
		name: 'Preview',
		components:{Icon},
		props:{
			scale:{
				type:Number,
				default:1
			}, 
			cvHeight:{
				type:Number,
				default:0,
			},
			cvWidth:{
				type:Number,
				default:0
			},
			page:{
				type:Object,
				default:{}
			} ,
			sectionIndex:{
				type:Number,
				default:-1
			},
			renderIn:{
				type:String,
				default:''
			}
		}, 
		computed:{
		},
		mounted:function(){
			this.Init();
		},
		watch:{
			 
		},
		data() {
			return { 
				active:false
			};
		},
		methods: {
			async Init(){ 
				 
			} ,
			async letterImageChangeOpen(){
				this.$emit('letterImageChangeOpen');
			}, 
			async letterDataEditOpen(){
				this.$emit('letterDataEditOpen');
			},
			async letterShowChange(){
				let page = JSON.parse(JSON.stringify(this.page));
				page.show = !page.show;
				this.$emit('pageUpdate',page,this.pageIndex);
			},  
			 
		} 
	}
</script> 
<style lang="less" scoped>  
	@import './cv.css';
	.container{
		position:relative;
		.preview{
			float:left;
			width:100%;
			height:100%;
			position:relative; 
		}
		.pageInfo{
			position:absolute;
			bottom:0px;
			right:0px;
			height:18px;
			line-height:18px;
			padding:0px 20px 0px 20px;
			background:rgba(0,0,0,0.6);
			color:#fff;
			z-index:999;
			border-radius:20px 0px 0px 0px;
			 
		}
		.imagechange{
			position:absolute;
			left:10px;
			bottom:44px;
			width:90px;
			height:24px;
			line-height:24px;
			text-align:center;
			background:#fff;
			border:1px solid #1261ff;
			color:#1261ff;
			border-radius:3px;
			font-size:12px;
			z-index:1;
		}
		.dataedit{
			position:absolute;
			left:10px;
			bottom:10px;
			width:90px; 
			height:24px;
			line-height:24px;
			text-align:center;
			background:#fff;
			border:1px solid #1261ff;
			color:#1261ff;
			border-radius:3px;
			font-size:12px;
			z-index:1;
		}
		.deletecover{
			position:absolute;
			right:10px;
			top:10px;
			height:24px;
			width:90px;
			line-height:24px;
			text-align:center;
			background:red;
			color:#fff;
			border-radius:3px;
			font-size:12px;
			z-index:1;
		} 
		
	}
	.ishide{
		float:left;
		width:100%;
		height:100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.add{
					 float:left;
					 display: flex;
					 align-items: center;
					 justify-content: center;
					 background:#fff;
					 border:5px solid #fff;
					 border-radius:10px;
					 
					 .c{
						 float:left;
						 width:100%;
						 height:90px;
						 .icon{
							 float:left;
							 width:100%;
							 height:60px;
							 display: flex;
							 align-items: center;
							 justify-content: center;
						 }
						 .tip{
							 float:left;
							 width:100%;
							 height:30px;
							 line-height:30px;
							 text-align:center;
							 color:#1261ff;
							 font-size:14px;
							 font-weight:bold;
						 }
						 
					 }
		}
	}
	.editBox{
		position:absolute;
		left:-2px;
		top:-2px;
		height:calc(100% + 4px);
		width:calc(100% + 4px);
		z-index:1; 
		border:4px dashed #1261ff!important; 
	}
</style>
