<template>
	<div class="edit">
		<div class="title">
			<div class="text" v-if="data">
				修改此条
			</div>
			<div class="text" v-if="!data">
				新增一条
			</div>
			<div class="close" @click="close()"> 
				取消
			</div> 
			<div class="sure" @click="sure()" style='color:#1261ff;'>
				<!--<uni-icons type="checkmarkempty" size="22" style="font-weight:bold;"></uni-icons>-->
				保存
			</div>
		</div>
		<div  class="main">
			<div class="text-full"  v-for="option,opi in options" :key="opi">
				<Field v-if="option.type=='text'" :placeholder="option.placeholder"  :label="option.name" label-width="60" colon  :value="data[option.key]" :clearable="false" @input="e=>inputChange(option.key,e)"  :ref="option.key" @click-right-icon="inputRightIconClick(option.key)" right-icon="edit"/>
				<Field v-if="option.type=='textarea'" :placeholder="option.placeholder"  type="textarea"  :label="option.name" label-width="60" colon  :value="data[option.key]" :clearable="false" @input="e=>inputChange(option.key,e)"   :ref="option.key" @click-right-icon="inputRightIconClick(option.key)" right-icon="edit"/>
				<Field v-if="option.type=='picker'"  :placeholder="option.placeholder"   :label="option.name" label-width="60" colon  :value="data[option.key]" :clearable="false" @input="e=>inputChange(option.key,e)"   :ref="option.key" @click-right-icon="pickerOpen(option.key)" right-icon="bars"/>
			 	<Popup position="bottom" v-model="picker" v-if="picker==option.key">
					<Picker
						title="请选择当前状态"
						show-toolbar
						:columns="option.pickerData"  
						@cancel="e=>pickerClose(option.key)"
						@confirm="e=>pickerConfirm(option.key,e)" 
					> 
					</Picker>
				</Popup> 
			</div> 
		</div> 
	</div>
</template>

<script> 
	import Api from '@/service/api.js'
	import Utils from "../utils.js"
	import {Toast,Popup,Picker,Icon,Field} from "vant"
	export default { 
		components:{Popup,Picker,Icon,Field},
		name: 'sections',
		props:{ 
			origindata:{
				type:Object,
				default:{}
			},
			isAdd:{
				type:Boolean,
				default:true
			},
			type:{
				 type:String,
				 default:''
			}
		}, 
		computed:{
			 
		},
		data() {
			return { 
				data:false,
				options:[],
				picker:false
			};
		},
		mounted:function(){
			this.Init(); 
		},
		methods: {
			async Init(){
				var that= this;
				//进行options获取
				that.options = Utils.OPTIONS[this.type];
				if(!that.isAdd){  
					that.data = that.origindata;
				}else{  
					let data = {};
					for(var i in that.options){
						let option = that.options[i];
						data[option.key] = option.default;
					}
					that.data = data;
				}
				
			},
			close(){
				this.$emit('close');
			}, 
			pickerOpen(key){
				this.picker = key;
			},
			inputRightIconClick(key){
				let refs = this.$refs;
				let  ref = refs[key][0];
				ref.focus();
			}, 
			pickerConfirm(key,e){
				let value = e.value;
				this.data[key] = value; 
				this.pickerClose();
			},
			pickerClose(key){
				this.picker = false; 
			},
			async inputChange(key,value){ 
				this.data[key] = value;
			}, 
			async inputFocus(key){
				let items = this.options.filter(item=>{
					if(item.key==key){
						return item;
					}
				}); 
				if(this.data[key]==items[0].default){
					this.data[key]="";
				}
			},   
			async sure(){  
				var that = this;
				//进行数据校验
				for(var i in this.options){
					let option = this.options[i]; 
					if(option.required&&!this.data[option.key]){
						let refs = that.$refs;
						let ref = refs[option.key][0];
						ref.focus();
						Toast.fail(option.name+"不能为空")
						return false;
					}
				}
				this.$emit('sure',this.data);
			}, 
		}
	}
</script> 
<style lang="less" scoped> 
 .edit{
		float:left; 
		background:#fff;
		width:100%;
		height:500px; 
		position:relative;
		border-radius:10px 10px 0px 0px; 
		.titleEdit{
			float:left;
			width:240px;
			height:60px;
			display: flex;
			align-items: center;
			justify-content: center; 
		}
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			 border-radius:10px 10px 0px 0px;
			 display: flex;
			 align-items:center;
			 justify-content: center;
			 .text{
				 float:left;
				  height:40px;
				  line-height:40px;
				  padding:0px 30px 0px 10px;
				  position:relative;
				  color:#1261ff;
				  font-weight:bold;
				  .icon{
					  position:absolute;
					  right:0px;
					  top:0px;
					  width:20px;
					  height:40px;
					   display: flex;
					align-items:center;
					justify-content: center;
				  }
			 }
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 40px); 
			overflow-y: scroll;
			padding:5px;
			background:#f5f5f5;
			.text-full{
				float:left;
				width:100%; 
				border-bottom:1px solid #f5f5f5;
			} 
		}
 }
</style>
