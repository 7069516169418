<template>
	<div class="container">
			<NavBar
		title="帮助中心"
		left-text="返回" 
		left-arrow
		@click-left="back()" 
		 
		/> 
		<div class="help-list-block" v-for="help,hi in helps" :key="hi">
			 <div class="title">{{help.title}}</div>
			 <div class="item" v-for="qa,qai in help.items"  :style="qa.show?'height:auto;':''" @click="itemChange(hi,qai)" :key="qai">
				 <div class="question"  :style="qa.show?'color:#007aff;':''">
					 {{qa.question}}
					 <div class="icon">
						 <img src="@/assets/icon/totop.png" v-if="qa.show" class="img" mode="aspectFill"/>
						  <img src="@/assets/icon/tobottom.png" v-if="!qa.show"  class="img" mode="aspectFill"/>
					 </div>
				 </div>
				 <div class="answer"  :style="qa.show?'display:block;color:#A5a5a5':''">
					  {{qa.answer}}
				 </div>
			 </div>
		</div> 
		<div style="float:left;width:100%;height:70px;"></div> 
	</div>
</template> 
<script>
	import Api from '@/service/api.js';
		import {NavBar } from "vant"
	export default {
		components:{NavBar},
		data() {
			return {
				helps:[],
			}
		}, 
		created:function(){
			this.Init();
		},
		methods: {
			async Init(){
				this.helps = await Api.query('/open/help/index',{appId:Api.APPID});
			},
			async back(){
				this.$router.go(-1)
			},
			async itemChange(hi,qai){
				this.helps[hi].items[qai].show = !this.helps[hi].items[qai].show;
			}, 
			loginSuccess(){
				this.loginShow= false;
			},
			loginClose(){
				this.loginShow=false;
			},
		}
	}
</script>

<style lang="less" scoped>
	.header{
		float:left;
		width:100%;
		height:100px; 
		background-color: #007AFF;
		.slogan{
			float:left;
			width:100%;
			height:100px;
			display: flex;
			align-items: center;
			justify-content: center;
			.img{
				width:240px;
				height:54px;
			}
		}
	} 
	.help-list-block{
		float:left;
		width:100%;
		padding:10px;
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			font-size:14px;
			color:#8F939C;
			padding-left:10px;
		}
		.item{
			float:left;
			width:100%;  
			height:60px;
			background:#fff;
			border-bottom:1px solid #F5F5F5;
			background:#fff;
			padding:10px;
			.question{
				float:left;
				width:100%; 
				padding:5px;
				height:40px;
				line-height:30px; 
				font-size:14px;
				position:relative;
				.icon{
					position:absolute;
					top:0px;
					right:10px;
					height:40px;
					width:40px;
					display: flex;
					align-items: center;
					justify-content: center;
					.img{
						width:20px;
						height:20px;
					}
				}
			}
			.answer{
				float:left;
				width:100%;
				background:#fff;
				padding:5px;
				font-size:12px;
				display: none;
				line-height:24px;
			}
		}
		
		.item:last-child{
			border-radius:0px 0px 10px 10px;
			border:none;
		}
		.item:first-child{
			border-radius:10px 10px 0px 0px; 
		}
		
	}
	 
</style>
