<template>
	<div class="editor" v-if="page"> 
		<div class="title">
			修改模块 
			<div class="sure" @click="close()" style="color:#1261ff;"> 
				确定
			</div> 
		</div>
		<div  class="main">
			<div class="section"> 
				<div :scroll-y="true" class="lists">
					<div class="list" v-for="data,di in page.data" :key="di">
						<div class="info"> 
								<div class="order">
									{{di+1}}/{{page.data.length}}
								</div>
								<div class="data" @click="dataEditOpen(di)">
									{{data.name}} ： {{data.value}}
								</div>
								<div class="icon">
									<Icon name="right"></Icon>
								</div>
						</div>
						<div class="actions">
							<div class="btn">
								<Icon name="edit" size="18" @click="dataEditOpen(di)" color="#1261ff"></Icon>
							</div> 
							<div class="btn">
								<Icon name="arrow-up" size="18" @click="dataUp(di)" :color="di==0?'#a5a5a5':'#000'"></Icon>
							</div>
							<div class="btn">
								<Icon name="arrow-down" size="18" @click="dataDown(di)" :color="di==page.data.length-1?'#a5a5a5':'#000'"></Icon>
							</div>
							<div class="btn">
								<Icon name="cross" size="18" @click="dataDelete(di)" color="red"></Icon>
							</div> 
						</div> 
					</div> 
					<div class="add">
						<div class="btn" @click="dataEditOpen()">
							增加一条
						</div>
					</div>
				</div>
			</div> 
		</div>
		<Popup ref="dataedit" position="bottom" v-model="dataedit" v-if="dataedit">
			<Edit :origindata="data" :isAdd="isAdd"   v-if="dataedit" @close="dataEditClose" @sure="dataEditSure"></Edit>
		</Popup>
	</div>
</template>

<script> 
	import Api from '@/service/api.js'  
	import Edit from "./edit.vue"
	import {Icon,Dialog,Popup} from "vant"
	export default {
		components:{Edit,Icon,Dialog,Popup},
		name: 'sections',
		props:{ 
			page:{
				type:Object,
				default:Object(), 
			}
		}, 
		data() {
			return {
				data:false, 
				dataIndex:false,
				dataedit:false,
				isAdd:false,
			};
		},
		mounted:function(){
			this.Init(); 
		},
		methods: {
			async Init(){ 
				 
			},
			close(){
				this.$emit('close');
			},
			async dataEditOpen(index=false){
				var that =this;
				if(index===false){
					//判断是否超过三项，如果大于等于三项则提醒过多
					let length = this.page.data.length;
					that.data = {};
					that.dataIndex = false;
					that.isAdd = true;  
				}else{
					this.dataIndex = index; 
					this.isAdd= false;
					this.data = this.page.data[index];
				} 
				this.dataedit = true; 
			},
			async dataEditClose(){
				this.dataIndex  = false;
				this.data = {}; 
				this.dataedit = false;
				this.isAdd = false; 
			}, 
			async dataEditSure(newData){
				if(this.dataIndex!==false){
					this.page.data[this.dataIndex] = newData;
				}else{
					this.page.data.push(newData);
				}
				this.dataEditClose(); 
				this.update();
			},
			async dataUp(index){
				if(index!=0){
					let data = JSON.parse(JSON.stringify(this.page.data));
					let newData = JSON.parse(JSON.stringify(data[index-1]));
					data.splice(index+1,0,newData);
					data.splice(index-1,1);
					this.page.data = data;
					this.update();
				}
			},
			async dataDown(index){
				if(index!=this.page.data.length-1){ 
					let data = JSON.parse(JSON.stringify(this.page.data));
					let newData = JSON.parse(JSON.stringify(data[index]));
					data.splice(index+2,0,newData);
					data.splice(index,1);
					this.page.data = data;
					this.update();
				}
			},
			async dataDelete(index=false){
				console.log("dataDelete");
				let that = this;
				Dialog.confirm({
					title: '温馨提示',
					message: '删除后无法恢复，请问是否删除？', 
				}).then(function(res){
					if (res) {
						let data = JSON.parse(JSON.stringify(that.page.data));
						data.splice(index,1);
						that.page.data = data;
						that.update(); 
					} 
				});
				
			}, 
			async update(){ 
				this.$emit('sure',JSON.parse(JSON.stringify(this.page)));
			}, 
		}
	}
</script> 
<style lang="less" scoped> 
 .editor{
		float:left; 
		background:#fff;
		width:100%;
		max-height:450px; 
		position:relative;
		border-radius:10px 10px 0px 0px; 
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			 border-radius:10px 10px 0px 0px;
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.main{
			float:left;
			width:100%;
			max-height:calc(100% - 40px);  
			.section{
				float:left;
				width:100%;
				height:100%;
				.lists{
					float:left;
					width:100%;
					padding:8px;
					background:#f5f5f5; 
					height:360px;
					overflow-y: auto;
					box-sizing: border-box;
					.list{
						float:left;
						width:100%;
						background:#fff; 
						border-radius:5px; 
						margin-bottom:10px;
						.info{
							float:left;
							width:100%;
							.order{
								float:left;
								width:60px;
								height:50px;
								line-height:50px;
								text-align:center;
								color:#a5a5a5;
							}
							.data{
								float:left;
								width:calc(100% - 120px);
								height:50px;
								line-height:50px;
								font-size:14px;
								font-weight:bold;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								padding-left:10px;
								color:#666;
							} 
							.icon{
								float:left;
								width:60px;
								height:50px;
								display: flex;
								align-items: center;
								justify-content: center;
								
							} 
							
						}
						.actions{
							float:left;
							width:100%;
							border-top:1px solid #f5f5f5; 
							.btn{
								float:left;
								width:calc(100% / 4); 
								height:30px;
								display: flex;
								align-items: center;
								justify-content: center;
							}  
						}
					}
					.add{
						float:left;
						width:100%;
						height:50px;
						display: flex;
						align-items: center;
						justify-content: center;
						.btn{
							float:left;
							width:240px;
							height:32px;
							line-height:32px;
							text-align:center;
							background:#1261ff;
							color:#fff;
							border-radius:16px;
						}
					}
				}
			}
		}
		 
	}
</style>
