const APPID = 'wxb88f37341c05058a';
import axios from "axios" 
export default  {
	APPID:APPID,
	async query(api,params={},errorShow=false){
		let token = localStorage.getItem('USER_TOKEN'); 
		//通过token
		let result = false;
		params.appId = APPID;
		let url = 'https://api.dayujianli.com'+api;
		await axios({
		  method:'post',
		  url:url,
		  data:params, 
		  headers:{
			  token:token
		  }
		})
		  .then(function (response) {
			 if(response.status!=200){ 
				result = false;
			 }else{
				 let data = response.data;
				 if(data.code!=200){ 
					result = false;
				 }else{
					 result = data.data;
				 }
			 }
		  });
		  return result; 
	},  
	async upload(file){
		let token = localStorage.getItem('USER_TOKEN'); 
		//通过token
		let result = false;
		
		let params = new FormData()       // 创建form对象     
		params.append('file', file)       // 通过append向form对象添加数据 
		params.append("appId",APPID)
		let url = 'https://api.dayujianli.com/open/avatar/upload';
		await axios({
		  method:'post',
		  url:url,
		  data:params, 
		  headers:{
			'Content-Type': 'multipart/form-data',
			  token:token
		  }
		})
		  .then(function (response) {
			 if(response.status!=200){ 
				result = false;
			 }else{
				 let data = response.data;
				 if(data.code!=200){ 
					result = false;
				 }else{
					 result = data.data;
				 }
			 }
		  });
		  return result; 
	},  
}