<template>
	 
	<div class="container">
			<NavBar
		title="我的收藏"
		left-text="返回" 
		left-arrow
		@click-left="back()" 
		 
		/> 
		<div class="h5s"> 
			 <div class="no" v-if="favs.length==0">
				 <div class="btn">暂无任何收藏</div>
			 </div>
			 <div class="lists" v-if="favs.length>0"> 
				 <div class="list" v-for="f,pi in favs" :key="pi">
					<div class="coverImage"> 
						<img :src="f.thumbnail" class="img" mode="aspectFill"/>
					</div>
					 <div class="name">
						{{f.name}}
					 </div> 
					 <div class="view"></div>
					 <div class="actions">
						  <div class="btn" @click="view(f.templateId)">去查看</div> 
						  <div class="btn" @click="unCollect(f.templateId)">取消收藏</div> 
					 </div>
				 </div>
			 </div>
		</div>
		<div class="page" v-if="maxPage>1">
			<div class="pre">
				<div  :class="page==1?'btn disBtn':'btn'" @click="pre()">上一页</div>
			</div>
			<div class="pageinfo">
				{{page}}/{{maxPage}} 页
			</div>
			<div class="next" @click="next()">
				<div :class="page==maxPage?'btn disBtn':'btn'">下一页</div>
			</div>
		</div>
		 
		<Login :show="showLogin" @close="closeLogin" @success="successLogin"/> 
	</div>
	
</template> 
<script>
	 
	import Login from '@/components/login/login.vue';
	import User from '@/service/user.js'  
	import Api from '@/service/api.js'
	import {Icon,Toast,NavBar} from "vant" 
	export default {

		components:{Login,Icon,NavBar},
		data() {
			return {
				userInfo:{},
				loginStatus:false,
				showLogin:false, 
				maxPage:0,
				page:1,
				pageSize:10,
				status:'1', 
				type:'0',
				favs:[]
			}
		},
		created:function(){
			this.Init();
		},
		methods: {
			async Init(){
				let l = await User.checkLogin();
				if(!l){
					this.loginStatus = false;
					this.userInfo = {};
				}else{
					this.getFavs();
					this.loginStatus=true;
					this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
				}
			},
				async back(){
				this.$router.go(-1)
			},
			async typeChange(a){
				this.type = a;
				this.page = 1;
				this.maxPage = 1;
				this.getFavs();
			},
			async onLoad(options){
				if(options.type){
					this.type = options.type;
				}
			}, 
			async pre(){
				if(this.page>1){
					this.page = this.page-1;
					this.getFavs();
				}else{
					console.log('第一页');
				} 
			},
			async next(){
				if(this.page<this.maxPage){
					this.page = this.page+1;
					this.getFavs();
				}else{
					console.log('最后一页');
				} 
			},
			async getFavs(){
				let result = await Api.query('/open/collect/index',{page:this.page,page_size:this.pageSize,type:this.type}); 
				if(result){
				
					this.favs = result.data;
					this.maxPage = result.last_page; 
				}
			},
			async unCollect(templateId){
				let r = await Api.query("/open/collect/collect",{templateId:templateId,type:'template'});
				if(r){
					Toast.success('取消收藏');
				 
				}else{
					Toast.fail('取消失败');
				}
				this.getFavs(); 
			},
			async view(templateId){
				this.$router.push({path:"/template/detail/"+templateId}); 
			},
			async login(){ 
				this.showLogin = true;
			},
			async closeLogin(){
				this.showLogin =false;
			},
			async successLogin(){
				this.showLogin =false;
				this.loginStatus=true;
				this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
				this.getFavs();
			} 
		}
	}
</script>
<style  lang="less" scoped>
	.h5s{
		float:left;
		width:calc(100% - 20px);
		margin:10px;  
		border-radius:5px;
		 .title{
			 float:left;
			 width:100%;
			 height:30px;
			 line-height:30px;
			 color:#a5a5a5;
			 font-size:14px;
			 padding-left:10px;
			 position:relative;
			 .more{
				 position:absolute;
				 right:10px;
				 top:0px;
				 height:30px;
				 line-height:30px;
				 width:60px;
				 text-align:center;
				 color:#1261ff;
			 }
		 }
		 .no{
			 float:left;
			 width:100%;
			 height:90px;
			 display: flex;
			 align-items: center;
			 justify-content: center;
			 .btn{
				 float:left;
				 width:180px;
				 height:30px;
				 line-height:30px;
				 text-align:center;
				 background:#1261ff;
				 color:#fff;
				 border-radius:15px;
			 }
		 }
		 .lists{
			 float:left;
			 width:100%;  
			 .list{
				 float:left;
				 width:100%; 
				 padding:5px; 
				 background:#fff;
				 margin:5px 0px 5px 0px;
				 border-radius:5px;
				 .coverImage{
					 float:left;
					 width:90px;
					 height:90px;
					 display: flex;
					 align-items: center;
					 justify-content: center;
					 position:relative;
					.img{
						width:78px;
						height:78px;
						border-radius:3px;
					 }
				 }
				 .name{
					 float:left; 
					 width:calc(100% - 90px);
					 height:30px;
					 line-height:30px;
					 padding-left:10px;
					 overflow: hidden;
					 text-overflow: ellipsis;
					 white-space: nowrap; 
					 font-size:14px;
					 font-weight:bold;
					 color:#000; 
				 }
				.view{
					 float:left; 
					 width:calc(100% - 90px);
					 height:20px;  
					 padding-left:5px;
					 overflow: hidden; 
					  .icon{
						  float:left;
						  width:20px;
						  height:20px;
						  display: flex;
						  align-items: center;
						  justify-content: center;
					  }
					  .value{
						  float:left;
						  height:20px;
						  line-height:20px;
						  margin-left:5px;
						  color:#3191F5;
					  }
				}
				.extra{
					float:left;
					width:calc(100% - 90px);
					height:40px;
					padding:8px;
					position:relative;
					.designer{
						float:left; 
						height:24px;
						line-height:24px;
						color:#1261ff;
						font-weight:bold;
						border-radius:12px;
						padding:0px 25px 0px 25px;
						border:1px solid #1261ff; 
					}
					.ad{
						position:absolute;
						right:10px;
						top:8px; 
						height:24px;
						line-height:24px;
						color:red;
						font-weight:bold;
						border-radius:12px;
						padding:0px 25px 0px 25px;
						border:1px solid red; 
						margin-left:10px;
					}
				}
				 
				.actions{
					 float:left; 
					 width:100%;
					 height:40px; 
					 padding:8px 0px 8px 10px;
					 overflow: hidden;
					 border-top:1px solid #f5f5f5;
					 position:relative;
					 .btn{
						 float:left;
						 padding:0px 14px 0px 14px;
						 border:1px solid #C0C0C0;
						 color:#000;
						 height:24px;
						 line-height:24px;
						 margin-right:5px;
						 border-radius:15px;
					 }
					 .more{
						 position:absolute;
						 right:10px;
						 top:8px;
						 height:24px;
						 padding:0px 14px 0px 14px;
						 border:1px solid #C0C0C0;
						 color:#000;
						 height:24px;
						 line-height:24px;
						 margin-right:5px;
						 border-radius:15px;
						 
					 }
				}
			 }
		 }
	}
	
	.page{
		float:left;
		width:100%;
		height:50px;
		padding:5px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top:20px;
		margin-bottom:80px;
		
		.pre{
			float:left;
			width:20%;
			height:40px;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				background:#007AFF;
				color:#fff;
				text-align: center;
				font-size:12px;
				height:30px;
				line-height:30px;
				font-weight:bold;
				width:90px;
				border-radius:2px;
			}
		}
		.pageinfo{
			float:left;
			width:33%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:12px;
			color:#808080;
		}
		.next{
			float:left;
			width:20%;
			height:40px;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				background:#007AFF;
				color:#fff;
				text-align: center;
				font-size:12px;
				height:30px;
				line-height:30px;
				font-weight:bold; 
				width:90px;
				
				border-radius:2px;
				
			}
		}
		.disBtn{
			background-color:#ccc!important;
			color:#606266!important;
		}
	}
	
	
</style>
 
