<template>
	<div class="container" style="width:100%;height:100%;">
		<div class="mask" @click="sectionSelect(-1)">
		</div>
		<Swipe class="swiper" :loop="false"  :show-indicators="false" ref="mySwiper"  @change="currentChange" :initial-swipe="current"   v-if="pages.length>0"> 
			<template v-for="page,pi in pages" >
			<SwipeItem class="swiper-item">
				<div  class="item">
					<div class="mask" @click="sectionSelect(-1)">
					</div>
					<div class="page"  :style="'width:'+cvWidth+'px;height:'+cvHeight+'px;'"> 
						<Page @sectionEditOpen="sectionEditOpen" :renderIn="renderIn"  v-if="page.type=='page'" @pageUpdate="pageUpdate" @pageDelete="pageDelete" :theme="cv.data.config.theme" :template="template" :pageIndex="pi" :page="page"  :pages="pages"  @sectionSelect="sectionSelect" :cvHeight="cvHeight" :cvWidth="cvWidth" :scale="scale" :sectionIndex="sectionIndex"></Page>
						<Cover :renderIn="renderIn" v-if="page.type=='cover'" @coverImageChangeOpen="coverImageChangeOpen" @coverDataEditOpen="coverDataEditOpen"  @pageUpdate="pageUpdate"  :pageIndex="pi" :page="page"    @sectionSelect="sectionSelect" :cvHeight="cvHeight" :cvWidth="cvWidth" :scale="scale" :sectionIndex="sectionIndex"></Cover>
						<Letter :renderIn="renderIn" v-if="page.type=='letter'" @letterImageChangeOpen="letterImageChangeOpen" @letterDataEditOpen="letterDataEditOpen" @pageUpdate="pageUpdate" :template="template" :pageIndex="pi" :page="page"  :pages="pages"  @sectionSelect="sectionSelect" :cvHeight="cvHeight" :cvWidth="cvWidth" :scale="scale" :sectionIndex="sectionIndex"></Letter>
					  </div>
				</div>
			</SwipeItem> 
			</template>
		</Swipe>
	</div> 
</template> 
<script>
	import Api from "@/service/api.js"
	import Page from "./page.vue" 
	import Cover from "./cover.vue"
	import Letter from "./letter.vue"
	import { Swipe, SwipeItem } from 'vant';
	export default { 
		components:{Page,Cover,Letter,Swipe, SwipeItem},
		name: 'Preview',
		props:{
			scale:{
				type:Number,
				default:1
			},
			cv:{
				type:Object,
				default:{}
			},
			pageIndex:{
				type:Number,
				default:0
			},
			sectionIndex:{
				type:Number,
				default:-1
			},
			cvHeight:{
				type:Number,
				default:0,
			},
			cvWidth:{
				type:Number,
				default:0
			},
			renderIn:{
				type:String,
				default:"prediv"
			}
		}, 
		computed:{
			theme:function(){
				if(this.cv&&this.cv.theme){
					return JSON.parse(this.cv.theme);
				}else{
					return false;
				}
			}, 	
		},
		mounted:function(){
			this.Init();
		},
		watch:{
			cv:function(){
				this.Init()
			},
			pageIndex:function(){
				if(this.pageIndex!==-1){
					this.current = this.pageIndex;
				} 
			}
		},
		data() {
			return {
				template:false, 
				pages:[],
				config:{},
				current:0
			};
		},
		methods: {
			async Init(){
				if(this.cv){ 
					if(this.pageIndex!=-1){
						this.current = this.pageIndex;
					}
					this.template = await Api.query("/open/template/detail",{templateId:this.cv.templateId});
					this.pages = this.cv.data.pages;
					this.config = this.cv.data.config; 
				}
				  
			},
			async pageUpdate(page,pageIndex){
				this.$emit('pageUpdate',page,pageIndex);
			}, 
			pageAdd(){
				this.$emit('pageAdd');
			}, 
			currentChange(e){ 
				this.current = e;
				if(this.current<this.cv.data.pages.length){
					this.$emit('pageSelect',this.current);
				} else{
					this.$emit("pageSelect",-1);
				}
			},
			sectionSelect(v){ 
				this.$emit('sectionSelect',v);
			}, 
			async sectionDelete(si){
				this.$emit('sectionDelete',si);
			},
			async sectionEditOpen(sectionIndex){
				this.$emit('sectionEditOpen',sectionIndex);
			},
			async sectionUp(si){
				this.$emit('sectionUp',si);
			},
			async sectionDown(si){
				this.$emit('sectionDown',si);
			},
			//封面相关操作
			async coverImageChangeOpen(){
				this.$emit('coverImageChangeOpen');
			},
			async coverDataEditOpen(){
				this.$emit('coverDataEditOpen');
			},
			//自荐信相关操作
			async letterImageChangeOpen(){
				this.$emit('letterImageChangeOpen');
			},
			async letterDataEditOpen(){
				this.$emit('letterDataEditOpen');
			},
			async pageDelete(pageIndex){
				this.$emit("pageDelete",pageIndex);
			}
		} 
	}
</script> 
<style lang="less" scoped> 
	
	.container{
		float:left;
		width:100%;
		height:100%;
		background:#f5f5f5; 
		.mask{
			position:absolute;
			left:0;
			top:0; 
			width:100%;
			height:100%;
		}
	} 
	.swiper{
		float:left;width:100%;height:100%;
		.swiper-item{
			float:left;
			width:100%;
			height:100%;
		 
		}
	}
	 .item{
		 float:left;
		 width:100%;
		 height:100%;
		 display: flex;
		 align-items: center;
		 justify-content: center;
		 .page{
		 	float:left;
			 position:relative;
			 width: 100%;
			 height:100%;
			 display: flex;
			 align-items:center;
			 justify-content: center;
			 
		 }
		 .add{
			 float:left;
			 display: flex;
			 align-items: center;
			 justify-content: center;
			 background:#fff;
			 border:5px solid #fff;
			 border-radius:10px;
			 
			 .content{
				 float:left;
				 width:100%;
				 height:90px;
				 .icon{
					 float:left;
					 width:100%;
					 height:60px;
					 display: flex;
					 align-items: center;
					 justify-content: center;
				 }
				 .tip{
					 float:left;
					 width:100%;
					 height:30px;
					 line-height:30px;
					 text-align:center;
					 color:#1261ff;
					 font-size:14px;
					 font-weight:bold;
				 }
				 
			 }
		 }
		 .mask{
		 	position:absolute;
		 	left:0;
		 	top:0; 
		 	width:100%;
		 	height:100%;
		 }
		 
	 } 
	 
</style>
