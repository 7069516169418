<template>
	<div class="footer">
		 <div :class="active=='home'?'menu active':'menu'">
			 <div class="normal">
				 <div class="c" @click="go('home')">
					<div class="icon">
						<Icon name="wap-home" size="28" :color="active=='home'?'#1261ff':'#8F939C'"></Icon>
					</div>
					<div class="title" :style="active=='home'?'color:#1261ff;':''">首页</div> 
				 </div> 
			 </div>
		 </div>
		 <div class="menu" :class="active=='vip'?'menu active':'menu'">
			 <div class="normal">
				 <div class="c" @click="go('vip')">
					<div class="icon"> 
						<Icon name="gem" size="28"  :color="active=='vip'?'#1261ff':'#8F939C'"></Icon>
					</div>
					<div class="title" :style="active=='vip'?'color:#1261ff;':''">VIP</div> 
				 </div> 
			 </div>
		 </div>
		 <div class="center">
			<div class="icon" @click="go('template')">
				<div class="c">
					<Icon name="plus" size="30" color="#fff"></Icon>
				</div> 
			</div> 
		 </div>
		 <div class="menu" :class="active=='user'?'menu active':'menu'">
			 <div class="normal">
				 <div class="c" @click="go('user')">
					<div class="icon">
						<Icon name="manager" size="28"  :color="active=='user'?'#1261ff':'#8F939C'"></Icon>
					</div>
					<div class="title" :style="active=='user'?'color:#1261ff;':''">我的</div> 
				 </div> 
			 </div>
		 </div>
		 <div class="menu" :class="active=='question'?'menu active':'menu'">
			 <div class="normal">
				 <div class="c" @click="go('question')">
					<div class="icon">
						<Icon name="question" size="28"  :color="active=='question'?'#1261ff':'#8F939C'"></Icon>
					</div>
					<div class="title" :style="active=='question'?'color:#1261ff;':''">帮助中心</div> 
				 </div> 
			 </div>
		 </div>
	</div>	
</template>

<script> 
	import {Icon} from "vant"
	export default {
		components:{Icon},
		name: 'Bottom',
		props:{
			active:{	//显示的内容
				type: String,
				default: 'home'
			}, 
		},
		data() {
			return {
				 
			};
		},
		methods: {
			go(action){
				var that =this;
				if(action!=this.active){
					switch(action){
						case "home":
							that.$router.push({ 
								path:"/"
							})
						break;
						case "vip":
							that.$router.push({ 
								path:"/vip/index"
							})
						break;
						case "template":
							that.$router.push({ 
								path:"/template/index"
							})
						break;
						case "user":
							that.$router.push({ 
								path:"/user/index"
							})
						break;
						case "question":
							that.$router.push({ 
								path:"/help/index"
							})
						break;
					}
				}
				 
			}, 
		}
	}
</script>

<style lang="less" scoped>
	.footer{
		width:100%;
		height:70px;
		position:fixed;
		left:0px;
		bottom:0px; 
		box-sizing:border-box;
		z-index:99; 
		.center{
		 	float:left;
		 	width:20%;
		 	height:50px;
			margin-top:20px; 
		 	background:#fff;
			position:relative;
			.icon{
				position:absolute;
				left:calc(50% - 34px);
				top:-15px;
				height:70px;
				width:70px;
				background:#fff;
				border-radius:50%;
				display: flex;
				align-items:center;
				justify-content: center; 
				.c{
					float:left;
					width:48px;
					height:48px;
					display: flex;
					align-items: center;
					justify-content: center;
					background:#1261ff;
					border-radius:50%;
				}
			}
		}
		.menu{
			float:left;
			width:20%;
			height:50px;
			margin-top:20px; 
			background:#fff;
			position: relative;  
			.normal{
				float:left;
				width:100%;
				height:50px;
				display: flex;
				align-items: center;
				justify-content: center; 
				.c{
					float:left;
					width:50px;
					height:50px;
					.title{
						float:left;
						width:100%;
						height:20px;
						line-height:20px;
						text-align:center;
						font-size:12px;
						color:#666666;
					}
					.icon{
						float:left;
						width:100%;
						height:30px;
						display:flex;
						align-items:center;
						justify-content: center;
						.img{
							width:24px;
							height:24px;
						}
					}
				} 
			} 
		} 
		 
		 
	}	 
</style>
