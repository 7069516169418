const THEMES = [
	
		"default",
		"222222",
		"254665",
		"105E8C",
		"097DC2",
		"5E8EE0",
		"016866",
		"467B73",
		"019A89",
		"00A13C",
		"6B4B24",
		"847A74",
		"93101F",
		"D9474A",
		"7EABC1",
		"FF9694",
		"F06600",
		"F1CD2D",
	
];
const  OPTIONS={
	job:[
		 {
			 key:"date",
			 name:"时间",
			 placeholder:"起止时间，例如：2019-12 ~ 2021-10",
			 type:"text",
			 default:"2019-12 ~ 2021-10",
			 required:true,
		 },
		 {
			 key:"job",
			 name:"岗位",
			 placeholder:"请输入岗位名称",
			 type:'text',
			 default:'岗位名称',
			 required:true,
		 },
		 {
			 key:"company",
			 name:"公司",
			 placeholder:"请输入公司名称",
			 type:"text",
			 default:"XX有限公司",
			 required:true,
		 },
		 {
			 key:"description",
			 name:"内容",
			 placeholder:"请输入工作内容",
			 type:"textarea",
			 default:"简单阐述工作内容或日常工作中作出的贡献",
			 required:true,
		 },
		 {
			 key:"projects",
			 name:"项目",
			 placeholder:"简单阐述参与了哪些项目，可不填写，不填写则不显示",
			 type:"textarea",
			 default:"",
			 required:false,
		 },
	],
	edu:[
		{
			 key:"date",
			 name:"时间",
			 placeholder:"起止时间，例如：2019-12 ~ 2021-10",
			 type:"text",
			 default:"2019-06 ~ 2022-06",
			 required:true,
		},
		{
			 key:"school",
			 name:"学校",
			 placeholder:"请输入学校名称",
			 type:"text",
			 default:"学校名称",
			 required:true,
		},
		{
			key:"degree",
			name:"学历",
			placeholder:"请选择学历水平",
			type:"picker",
			default:"本科",
			required:true,
			pickerData:[
				{
					text:'其它',
					value:"其它"
				},
				{
					text:'小学',
					value:"小学"
				},
				{
					text:'初中',
					value:"初中"
				},
				{
					text:'高中',
					value:"高中"
				},
				{
					text:'中专',
					value:"中专"
				},
				{
					text:'大专',
					value:"大专"
				},
				{
					text:'本科',
					value:"本科"
				},
				{
					text:'硕士',
					value:"硕士"
				},
				{
					text:'博士',
					value:"博士"
				},
			]
		},
		{
			key:"major",
			name:"专业",
			placeholder:"请输入专业，不填写则不显示，高中及以下学校此处就可以不填写了",
			type:"text",
			default:"专业名称",
			required:false,
		},
		{
			key:"description",
			name:"描述",
			placeholder:"例如在校成绩如何、是否参加过社团、学生会等，建议应届生适当填写，有利于应聘公司的简历筛选",
			type:"textarea",
			default:"在校期间参与了XX活动项目，毕业成绩XXX",
			required:false,
		},
		{
			key:"lessons",
			name:"课程",
			placeholder:"请输入在校期间学习了哪些课程，不填写则不显示，高中及以下学校此处就可以不填写了",
			type:"textarea",
			default:"学习课程：课程1，课程2，课程3",
			required:false,
		}, 
	],
	hope:[
		{
			key:"job",
			name:"岗位",
			placeholder:"请输入期望岗位",
			type:"text",
			default:"岗位名称",
			required:true,
		},
		{
			key:"type",
			name:"性质",
			placeholder:"请选择工作性质",
			type:"picker",
			default:"全职",
			required:false,
			pickerData:[
				{
					text:'全职',
					value:"全职"
				},
				{
					text:'兼职',
					value:"兼职"
				},
				{
					text:'其它',
					value:"其它"
				},
			]
		}, 
		{
			key:"location",
			name:"地点",
			placeholder:"请输入期望工作地点，不填写则不显示",
			type:"text",
			default:"期望地点",
			required:false,
		},
		{
			key:"salary",
			name:"薪资",
			placeholder:"请输入期望薪资，不填写则不显示",
			type:"text",
			default:"5K",
			required:false, 
		},
	],
	project:[
		{
			key:"date",
			name:"时间",
			placeholder:"起止时间，例如：2019-12 ~ 2021-10",
			type:"text",
			default:"2088-01~2088-03",
			required:true, 
		},
		{
			key:"project",
			name:"项目",
			placeholder:"请输入项目名称",
			type:"text",
			default:"项目名称",
			required:true, 
		},
		{
			key:"company",
			name:"公司",
			placeholder:"请输入公司名称，不填写则不显示",
			type:"text",
			default:"公司名称",
			required:false, 
		},
		{
			key:"description",
			name:"介绍",
			placeholder:"请输入项目背景、介绍，以及取得的成就",
			type:"textarea",
			default:"项目内容介绍以及在此项目中承担的工作任务、获得的成就",
			required:false, 
		}, 
	],
	skill:[
		{
			key:"skill",
			name:"技能",
			placeholder:"请输入技能名称",
			type:"text",
			default:"技能名称",
			required:true, 
		}, 
		{
			key:"level",
			name:"程度",
			placeholder:"请选择熟练程度",
			type:"picker",
			default:"精通",
			required:true,
			pickerData:[
				 {
					 text:'了解',
					 value:"了解"
				 },
				 {
					text:'掌握',
					value:"掌握"
				 },
				 {
					text:'熟练',
					value:"熟练"
				 },
				 {
				 	text:'精通',
				 	value:"精通"
				 },
			 ]
		}, 
	],
	self:[
		{
			key:"content",
			name:"内容",
			placeholder:"请输入自我评价内容",
			type:"textarea",
			default:"自我评价内容",
			required:true, 
		}, 
	],
	hobby:[
		{
			key:"content",
			name:"内容",
			placeholder:"请输入内容",
			type:"textarea",
			default:"内容",
			required:true, 
		}, 
	],
	prize:[
		{
			key:"prize",
			name:"名称",
			placeholder:"请输入证书/奖项名称",
			type:"text",
			default:"奖项名称/证书名称",
			required:true, 
		}, 
		{
			key:"date",
			name:"时间",
			placeholder:"请输入获取奖项/证书的时间",
			type:"text",
			default:"2020-09",
			required:true, 
		}, 
	],
	single:[
		{
			key:"content",
			name:"内容",
			placeholder:"请输入内容",
			type:"textarea",
			default:"此处填写新增单项的内容",
			required:true, 
		}, 
	],
}
const DEFAULTCV = { 
					config:{
						theme:'default'
					}, 
					pages:[ 
							{
								type:"cover",
								show:false, 
								image:"https://cdn.dayujianli.com/static/template/cover/1.png",
								position:{
									left:490,
									top:820,
								},
								data:[
									{
										name:"姓名",
										value:"简小通"
									},
									{
										name:"求职意向",
										value:"销售经理"
									},
									{
										name:"毕业院校",
										value:"北京大学"
									}
								]
							},
							{
								type:'page',
								sections:[
									{
										type:'baseinfo',
										title:'个人资料',
										titleShow:true,
										avatarShow:true,
										data:{
											name:"简小通",
											avatar:'https://cdn.dayujianli.com/static/template/images/avatar.jpg', 
											avatar_origin:'https://cdn.dayujianli.com/static/template/images/avatar.jpg', 
											sex:"女",
											experience:"3年工作经验",
											age:"23",
											degree:"本科",
											mobile:"18888888888",
											email:"88888@qq.com",
											status:"已离职，可立即上岗", 
											birthLocation:'广东省-深圳市',
											liveLocation:"广东省-深圳市-罗湖区", 
										}
									},
								]
							},
							{
								type:'letter',
								image:"https://cdn.dayujianli.com/static/template/letter/2.png",
								show:false,
								data: {
									title:"求职信",
									subTitle:"MY   COVER   LETTER",
									rise:'尊敬的领导',
									contents:[
										'您好，首先，向您辛勤的工作致以深深的敬意！同时也真诚的感谢您在百忙之中抽空垂阅我的自荐书。',
										"刚踏入校门的我谈不上十分的成熟和足够的经验，但我有过人的胆识和自信心，正所谓：是龙，就应该去大海搏击，是鹰，就应该去长空翱翔。面临人生的重大抉择之际，我希望能加盟于贵单位，展示自己的才能，实现个人的理想与价值。因此，我经过慎重考虑决定竞选培训部门副经理。",
										"我认为自己还颇有管理才华，做人诚恳，能把握分寸，可以最大限度的团结部门，做大做强品牌。年轻没有失败！不论结果如何我都会不离不弃，一如既往，坚守岗位。希望部门能给我一个证明自己的机会，给我一个战斗的战场。四年大学生活，对我的帮助不仅仅是为了取得优异的学习成绩和多次获得奖学金，更重要的是要让它培养自己很强的自学能力和分析问题、解决问题的能力。",
										"在多次参加部门内训，让我学到了很多课堂上所学不到的东西，让我更加增加了我的责任心以及与人合作的能力，做主持人则锻炼了我的组织、语言表达能力，和我的协调能力，基于此我认为自己符合培训副经理职位的要求。",
										"如果我有幸得到您的赏识，担任副经理一职，我将保持奋发向上的精神，谦虚地向前辈们学习，并尽我所学，与公司一起开拓进取，奔向更加辉煌美好的明天！"
									],
									sign:"简小通", 
								}
							}, 
					]
	}
let defaultSections = [
	{
		type:'hope',
		title:"求职意向"
	},
	{
		type:'job',
		title:"工作经验"
	},
	{
		type:'edu',
		title:"教育背景"
	},
	{
		type:'skill',
		title:"专业技能"
	}
];
for(var i in defaultSections){
	let option = OPTIONS[defaultSections[i].type];
	let data = {};
	for(var j in option){
		data[option[j].key] = option[j].default;
	}
	DEFAULTCV.pages[1].sections.push({
		type:defaultSections[i].type,
		titleShow:true,
		title:defaultSections[i].title,
		data:[data]
	}); 
}
 module.exports={
	DEFAULTCV,
	OPTIONS,
	THEMES
 }			