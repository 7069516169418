<template>
	<div class="cvname"> 
		<div class="title"> 
			设置简历名称
			<div class="close" @click="close()" style="font-weight:bold;color:#000;">
				跳过
			</div> 
			<div class="sure" @click="sure()" style="font-weight:bold;color:#1261ff;">
				确定
			</div> 
		</div>
		 <div class="main">
			<div class="input">
				<div class="text">
					<Field type="text" style="border:1px solid #f5f5f5;" :value="name" @input="inputChange" placeholder="例如:XXX的简历"/>
				</div>
			</div>
		 </div>
	</div>
</template> 
<script> 
	 
	import {Field} from "vant"
	export default {
		components:{Field},
		name: 'templates',
		props:{
			name:{
				type:String,
				default:'新建简历'
			} 
		}, 
		data() {
			return { 
				origin:'',
			};
		},
		mounted:function(){
			this.Init();
		},
		methods: {
			async Init(){
				 this.origin = this.name;
			}, 
			close(){ 
				this.$emit('close');
			}, 
			async inputChange(e){  
				this.origin = e;  
			}, 
			async sure(){ 
				this.$emit('sure',this.origin); 
			},
		}
	}
</script> 
<style lang="less" scoped> 
 .cvname{
		float:left; 
		background:#fff;
		width:280px;
		height:120px; 
		position:relative;
		border-radius:10px;
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-top:1px solid #F5F5F5;
			position:relative; 
			background:#fff; 
			 border-radius:10px 10px 0px 0px;
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 40px); 
			.input{
				float:left;
				width:100%;
				height:100%;
				display: flex;
				align-items: center;
				justify-content: center;
				.text{
					float:left;
					width:90%;
					height:50px;
				}
			}
			 
			 
		}
	}
</style>
