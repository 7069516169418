<template>
	<div class="container">
		<div class="header">
			<div class="user" v-if="loginStatus">
				<div class="avatar">
					<img :src="userInfo.headimg" class="img"/>
				</div>
				<div class="nickname">
					{{userInfo.nickname}}（编号:{{userInfo.userId}}）
				</div>
				<div class="vip" v-if="!userInfo.vip_time">
					尚未开通VIP
				</div>
				<div class="vip" v-if="userInfo.vip_time">
					VIP到期:{{vipLimit()}}
				</div>
			</div>
			<div class="login" v-if="!loginStatus">
				<div class="avatar">
					<img src="@/assets/icon/defaultuser.png" class="img"/>
				</div>
				<div class="actions">
					<div class="btn" @click="login()">点此登陆</div>
				</div> 
			</div>
		</div> 
		 <div   class="items" v-if="items">
			 <div :class="index==i?'item active':'item'" :key="i" v-for="item,i in items" @click="selectIndex(i)">
				<div class="name">{{item.itemName}}</div>
				<div class="price">￥{{item.price}}</div> 
				<div class="icon">
					<Icon name="checked" color="#d8a553" size="36" v-if="index==i"></Icon> 
				</div>
			 </div> 
		 </div>
		 <div class="actions" >
			 <div class="btn" @click="wx_jsapi_pay()">立即开通VIP服务</div>
		 </div> 
		 <div class="tips">
		 	<div class="title">
		 		VIP服务说明：
		 	</div> 
		 	<div class="lines">
		 		<div class="line">1、VIP服务为本软件的付费服务，开通后享有VIP权限。</div>
		 		<div class="line">2、所有简历模板、素材等资源全免费使用，不限制生成次数。</div> 
				<div class="line">3、VIP用户支持导出PDF版简历。</div>
				<div class="line">4、由于VIP服务产品的特殊性，开通成功后不支持退款。</div>
		 		<div class="line">5、您在新购/续费VIP服务时，表明您已经同意上述条款</div>
		 	</div>
		 </div> 
		<Login :show="loginShow" @success="loginSuccess" @close="loginClose"></Login>
		<div style="float:left;width:100%;height:70px;"></div>
		<Bottom active="vip"></Bottom>  
	</div>
</template>

<script>
	import Bottom from '@/components/bottom/bottom.vue';
	import Login from '@/components/login/login.vue';
	import User from '@/service/user.js'; 
	import Api from "@/service/api.js"
	import {Icon,Toast} from "vant"
	export default {
		components:{Bottom,Login,Icon},
		data() {
			return { 
				loginStatus:false,
				userInfo:false,
				vip:false, 
				loginShow:false,
				items:false,
				index:0,
				orderCheckTimer:null,
				orderId:false,
				order:false,
				thirdPlatform:'normal'  
			}
		},
		computed:{
			 vipLimit:function(){
				if(!this.userInfo||!this.userInfo.vip_time){
					return 
				}else{
					date = new Date(this.userInfo.vip_time);
					var year = date.getFullYear(),
					month = date.getMonth() + 1,//月份是从0开始的
					day = date.getDate(),
					hour = date.getHours(),
					min = date.getMinutes(),
					sec = date.getSeconds();
					var newTime = year + '-' +
					month + '-' +
					day + ' ' +
					hour + ':' +
					min + ':' +
					sec;
					return newTime;         
				}
			}
		}, 
		created:function(){
			this.Init();
		},
		methods: {
			
			async Init(){ 
				this.vipitems();
				this.getLoginStatus(); 
				var ua = window.navigator.userAgent.toLowerCase();
				if (ua.match(/micromessenger/i) == 'micromessenger') {
					this.thirdPlatform = 'wx';
				}  
				if (/alipayclient/.test(ua)) {
					this.thirdPlatform = 'alipay';
				} 
			}, 
			async login(){
				this.loginShow =true;
			},
			async vipitems(){
				let vipitems = await Api.query('/open/vip/items',{});
				if(vipitems){
					this.items = vipitems;
				} 
			},
			async selectIndex(i){
				this.index = i;
			},
			async createOrder(){ 
				let l = await User.checkLogin();
				if(!l){
					this.loginShow = true;
					return false;
				}
				this.orderId = false;
				this.order = false;
				let itemId = this.items[this.index].itemId;
				this.order = await Api.query('/open/order/create',{miniappAppId:Api.APPID,platform:'miniapp',orderType:'vip',itemId:itemId}); 
			
			},
			async wx_jsapi_pay(){
				//判断是否有openId
				let openId = localStorage.getItem('USER_OPENID');
				if(!openId){
					let uri = encodeURIComponent(window.location.href);
					window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+Api.APPID+"&redirect_uri="+uri+"&response_type=code&scope=snsapi_userinfo&state=GET_WX_OPENID#wechat_redirect"
			 		return false;
				}
				var that =this; 
				await that.createOrder(); 
				let r = await   Api.query("/open/order/pay",{type:'wx_jsapi',orderId:this.order.orderId,miniappAppId:Api.APPID,openId:openId});
				if(r){
					WeixinJSBridge.invoke(
						'getBrandWCPayRequest', {
						"appId":Api.APPID,     //公众号ID，由商户传入     
						"timeStamp":r.timeStamp,         //时间戳，自1970年以来的秒数     
						"nonceStr":r.nonceStr, //随机串     
						"package":r.package,     
						"signType":r.signType,         //微信签名方式：     
						"paySign":r.paySign //微信签名 
					});  
					this.timer = that.orderCheck();
				}  
			}, 
			async orderCheck(){
				var that = this;
				clearTimeout(that.timer);
				if(this.order){
					let r = await Api.query("/open/order/detail",{orderId:this.order.orderId}); 
					if(r.state==1){
						Toast.success('支付成功');
						that.getLoginStatus();
						this.$router.go(-1);
						return false;
					} 
					that.timer =setTimeout(function(){
						that.orderCheck();
					},3000);
				}
			}, 
			async getLoginStatus(){
				let l = await User.checkLogin();
				if(l){
					this.loginStatus=true;
					this.userInfo = JSON.parse(localStorage.getItem('USER_INFO')); 
				}else{
					this.loginStatus=false;
				}
			},  
			loginSuccess(){
				this.loginShow= false; 
				this.getLoginStatus();
			},
			loginClose(){
				this.loginShow=false;
			},
		}
	}
</script>

<style lang="less" scoped>
	.ios{
		float:left;
		width:100%;
		height:240px;
		display: flex;
		align-items: center;
		justify-content: center;
		.btn{
			float:left;
			width:160px;
			height:30px;
			line-height:30px;
			border-radius:15px;
			background:#1261ff;
			color:#fff;
			text-align:center;
		}
	}
	.items{
		float:left;
		width:100%; 
		height:140px;
		padding:10px;
		background:#fff;
		white-space: nowrap;
		box-sizing: border-box;
		overflow-x: scroll;
		.item{ 
			display: inline-block;
			width:140px;
			height:120px;
			background:#fff; 
			margin-right:10px;
			border-radius:5px;
			padding: 10px 5px 10px 5px;
			border:1px solid #f5f5f5;
			 .name{
				float: left;
				width: 100%;
				height: 30px;
				line-height: 30px;
				font-size: 14px;
				font-weight: bold;
				text-align: center;
				color: #333;
			 }
			 .price{
				 float: left;
				 width: 100%;
				 height: 30px;
				 line-height: 30px;
				 font-size: 14px;
				 font-weight: bold;
				 color: #d8a553;
				 text-align: center;
			 }
			 .icon{
				 float: left;
				 width: 100%;
				 height: 40px;
				 line-height: 40px;
				 text-align: center;
				 align-items: center;
				 justify-content: center;
				 color: #d8a553;
				 font-size: 24px; 
				 display: none;
			 }
		}
		.active{ 
			background: #fef9f3;
			border-color: #d8a553;
			.icon{
				display: flex;
			}
		}
	}
	.actions{
		float:left;
		width:100%;
		padding:10px; 
		background:#fff;
		height:60px;
		margin-top:1px;
		display: flex;
		align-items: center;
		justify-content: center;
		.btn{
			float:left;
			width:100%;
			background-color:#d8a553;
			color:#fff;
			height:40px;
			line-height:40px;
			text-align:center;
			border-radius:20px;
		}
	}
	.header{
		float:left;
		height: 240px; 
		width:100%;    
		background:url(https://cdn.dayujianli.com/static/images/vipbg.jpg);  
		background-size: 100% 100%; 
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		
		.user{
			float:left;
			width:280px;
			height:80px;
			.avatar{
				float:left;
				width:80px;
				height:80px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius:50%;
				background:#fff;
				margin-right:10px;
				.img{
					width:78px;
					height:78px;
					border-radius:50%;
				}
			}
			.nickname{
				float:left;
				width:calc(100% - 90px);
				height:40px;
				line-height:40px;
				color:#fff;
				font-size:14px;
				line-height:40px;
				font-weight:bold;
				
			}
			.vip{
				float:left;
				width:calc(100% - 90px);
				height:40px;
				line-height:40px;
				color:#fff;
				font-size:12px;
				line-height:40px;
				font-weight:bold;
				
			} 
		}
		.login{
			float:left;
			width:280px;
			height:120px;
			.avatar{
				float:left;
				width:100%;
				height:70px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius:50%; 
				margin-right:20px;
				.img{
					width:64px;
					height:64px;
					border-radius:50%;
					border:1px solid #fff;
				}
			}
			.actions{
				float:left;
				width:100%;
				height:50px;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn{
					float:left;
					height:28px;
					line-height:28px;
					padding:0px 30px 0px 30px;
					color:#DAA520;
					background:#fff;
					border-radius:5px;
					font-size:14px;
				}
			}
		}
	}
	.tips{
		float:left;
		width:calc(100% - 20px);
		margin:10px;
		border-radius:5px; 
		margin-top:10px;
		.title{
			float:left;
			width:100%;
			height:30px;
			line-height:30px;
			font-size:14px;
			color:#A5A4A4;
		}
		.lines{
			float:left;
			width:100%;
			background:#fff;
			border-radius:5px;
			padding:10px;
			.line{
				float:left;
				width:100%;
				line-height:24px;
				font-size:12px;
			}
		}
	} 
</style>
