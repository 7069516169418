<template>
	<div class="templates"> 
		<div class="title">
			切换封面模板
			<div class="close" @click="close()">
				取消
			</div> 
			<div class="sure" @click="sure()" style="color:#1261ff;font-weight:bold;">
				确定
			</div> 
		</div>
		<div  style="box-sizing: border-box;" class="main">
			<div class="template" v-for="letter,si in letters" :key="si" @click="select(letter.image)"> 
				<div class="c">
					<div class="select" v-if="page.image==letter.image">
						<Icon name="checked" size="24" color="#1261ff"></Icon>
					</div>
					<div class="cover">
						<img :src="letter.image" mode="aspectFill" class="img"/>
					</div>
					<div class="name">
						{{letter.name}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script> 
	import Api from '@/service/api.js' 
	import {Icon} from "vant"
	export default {
		components:{Icon},
		name: 'templates',
		props:{
			page:{
				type:Object,
				default:{}
			}
		}, 
		data() {
			return {
				letters:[],
				origin:''
			};
		},
		mounted:function(){
			this.Init();
		},
		methods: {
			async Init(){
				this.letters =await Api.query("/open/letter/images",{});
				this.origin = this.page.image; 
			},
			close(){
				this.$emit('sure',this.origin);
				this.$emit('close');
			}, 
			sure(){ 
				this.$emit('close');
			},
			async select(image){ 
				this.$emit('sure',image);
			}, 
		}
	}
</script> 
<style lang="less" scoped> 
 .templates{
		position:fixed;
		left:0px;
		bottom:0px;
		background:#fff;
		width:100%;
		height:100%; position:relative;
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			border-radius:5px 5px 0px 0px;
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		}
		.main{
			float:left;
			width:100%;
			height:calc(100% - 40px);
			white-space: nowrap;
			overflow-y: scroll;
			.template{
				display: inline-block;
				width:118px; 
				height:192px;
				margin:5px; 
				.c{
					float:left;
					width:118px;
					height:192px;
					position:relative;
					margin-right:10px;
					.select{
						position:absolute;
						left:0px;
						top:0px;
						height:100%;
						width:100%;
						background:rgba(0,0,0,0.6);
						display:flex;
						align-items: center;
						justify-content: center;
						z-index:1;
					}
					.cover{
						float:left;
						width:100%;
						height:100%;
						padding:2px;
						display: flex;
						align-items: center;
						justify-content: center;
						background:#f5f5f5;
						.img{
							width:100%;
							height:100%;
						}
					}
					.name{
						position:absolute;
						left:0px;
						bottom:0px;
						height:24px;
						line-height:24px;
						text-align:center;
						background:rgba(0,0,0,0.6);
						color:#fff;
						width:100%;
					}
				}
			}
			 
		}
	}
</style>
