<template>
	<div class="ordercv">
		<div class="title">
			<div class="tabs">
				<div :class="method=='vip'?'tab vip active':'tab vip'" @click="methodChange('vip')"> 
					VIP免费用
					<div class="barc"></div>
				</div>
				<div :class="method=='pay'?'tab active':'tab'" @click="methodChange('pay')" >
					原价购买
					<div class="barc"></div>
				</div>
				<div :class="method=='point'?'tab active':'tab'" @click="methodChange('point')">
					积分购买
					<div class="barc"></div>
				</div>
			</div>
			<div class="sure" @click="close()">
				<Icon name="cross" size="22"></Icon>
			</div>
		</div> 
		<div class="main" v-if="method!='vip'">
			<div class="info">
				<div class="coverImage">
					<img :src="cv.thumbnail" class="img" mode="aspectFill"/>
				</div>
				<div class="name">{{cv.name}}</div>
				<div class="price">
					<div class="n" v-if="method=='pay'">价格：</div> 
					<div class="v" v-if="method=='pay'">￥{{parseFloat(price,2)}}</div>
					<div class="n" v-if="method=='point'">积分：</div>
					<div class="v" v-if="method=='point'">{{parseFloat(price*100,2)}}</div> 
				</div> 
				<div class="tip">
					<div class="n">说明：</div>
					<div class="v">
						付费后支持导出、下载PDF，无次数限制
					</div>
				</div>
			</div>
			<div class="real"  v-if="method=='pay'">
				<div class="left">优惠金额：</div>
				<div class="right">0</div>
			</div>
			<div class="real"  v-if="method=='pay'">
				<div class="left">实际支付：</div>
				<div class="right">{{price}}</div>
			</div>
			<div class="real"  v-if="method=='point'">
				<div class="left">剩余积分：</div>
				<div class="right" :style="userInfo.redeem<(price*100)?'color:red;':''">{{userInfo.redeem}}</div>
			</div>
			<div class="real"  v-if="method=='point'">
				<div class="left">本次扣除：</div>
				<div class="right">{{price*100}}</div>
			</div>
			<div class="pay" v-if="method=='pay'"> 
				<div class="order" @click="wx_jsapi_pay()" v-if="thirdPlatform=='wx'">
					微信支付
				</div> 
			</div>
			<div class="pay" v-if="method=='point'"> 
				<div class="order" @click="pointPay()">
					确认扣除
				</div> 
			</div>
			<div class="tips">
				<div class="tip">1、购买后此简历支持PDF版下载，不限制次数</div>
				<div class="tip">2、支持发送小程序版给好友，不限制次数</div>
				<div class="tip">3、此简历可以支持使用所有简历模板</div>
				<div class="tip">4、此简历支持使用平台所有素材，不限制次数</div>
				<div class="tip">5、购买后无法退款，请您知晓</div>
			</div>
		</div>
		<div class="main" v-if="method=='vip'">
			<div class="info">
				<div class="coverImage">
					<img :src="cv.thumbnail" class="img" mode="aspectFill"/>
				</div>
				<div class="name">{{cv.name}}</div>
				<div class="price">
					<div class="n">费用：</div>
					<div class="v">免费</div> 
				</div>
				<div class="tip">
					<div class="n">说明：</div>
					<div class="v">
						支持导出、下载PDF，无次数限制
					</div>
				</div>
			</div>  
			<div class="real" v-if="isvip">
				<div class="left">VIP权限：</div>
				<div class="right">已开通</div>
			</div>
			<div class="real" v-if="isvip">
				<div class="left">到期时间：</div>
				<div class="right">{{viptime}}</div>
			</div>
			 <div class="real" v-if="!isvip">
			 	<div class="left">VIP权限：</div>
			 	<div class="right">尚未开通</div>
			 </div> 
			<div class="real" v-if="!isvip">
				<div class="left">到期时间：</div>
				<div class="right">已到期</div>
			</div> 
			<div class="pay"> 
				<div class="order" @click="createByVip()" v-if="isvip">
					免费使用此模板
				</div> 
				<div class="order" @click="goVip()" v-if="!isvip">
					去开通VIP
				</div> 
			</div>
			<div class="tips">
				<div class="tip">1、VIP用户所有简历模板</div>
				<div class="tip">2、VIP用户支持导出/下载PDF版简历</div>
				<div class="tip">3、VIP用户不限制生成、导出次数</div>
				<div class="tip">4、VIP用户免费使用平台所有素材</div>
				<div class="tip">5、购买后无法退款，请您知晓</div>
			</div>
		</div>
	</div>
</template> 
<script>  
	import User from '@/service/user.js' 
	import Api from '@/service/api.js' 
	import {Icon,Toast} from "vant" 
	export default {
		components:{Icon},
		name: 'template',
		props:{
			cvId:{	//显示的内容
				type: String,
				default: ''
			}, 
		},
		data() {
			return {
				cv:{}, 
				order:false,
				price:999,
				method:'pay', 
				isvip:false,
				viptime:'',
				userInfo:{},
				thirdPlatform:'normal'
			};
		},
		timer:null,
		mounted:function(){
			this.Init(); 
		},
		methods: {
			async Init(){

				var ua = window.navigator.userAgent.toLowerCase();
				if (ua.match(/micromessenger/i) == 'micromessenger') {
					this.thirdPlatform = 'wx';
				}  
				if (/alipayclient/.test(ua)) {
					this.thirdPlatform = 'alipay';
				} 
				await User.checkLogin();
				this.userInfo =JSON.parse(localStorage.getItem(('USER_INFO')));
				if(this.userInfo.vip_time>(Date.now()/1000)){
					this.isvip = true; 
				}
				this.price = await Api.query("/open/order/price",{type:'cv'});
				let cv = await Api.query("/open/cv/detail",{cvId:this.cvId});
				this.cv = cv;
				if(this.isvip){
					this.method = 'vip';
				}else{
					if(this.userInfo.redeem>(this.price*100)){
						this.method = 'point';
					}else{ 
						this.method = 'pay'; 
					}
				} 
			},
			async methodChange(v){
				this.method = v;
			},
			 async goVip(){
				 uni.navigateTo({
				 	url:"/pages/vip/index"
				 })
			 },
			async orderCheck(){
				var that = this;
				clearTimeout(that.timer);
				if(this.order){
					let r = await Api.query("/open/order/detail",{orderId:this.order.orderId}); 
					if(r.state==1){
						uni.showToast({
							title:'支付成功',
							icon:'none'
						});
						this.$emit('sure'); 
						return false;
					} 
					that.timer = setTimeout(async function(){
						await that.orderCheck();
					},3000);
				}
			}, 
			async pointPay(){
				var that =this;
				//检测怪点是否足够
				if(that.userInfo.redeem<(this.price*100)){
					Toast.fail("积分不足")
					return false;
				}
				await that.createOrder();
				//进行支付
				let r = await  Api.query("/open/order/pointpay",{orderId:this.order.orderId});
				if(r){
					Toast.success("购买成功");
				}
				that.timer = that.orderCheck();
			},
			async  getWxOpenId(){

			},
			async wx_jsapi_pay(){
				//判断是否有openId
				let openId = localStorage.getItem('USER_OPENID');
				if(!openId){
					let uri = encodeURIComponent(window.location.href);
					window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+Api.APPID+"&redirect_uri="+uri+"&response_type=code&scope=snsapi_userinfo&state=GET_WX_OPENID#wechat_redirect"
			 		return false;
				}
				var that =this; 
				await that.createOrder(); 
				let r = await   Api.query("/open/order/pay",{type:'wx_jsapi',orderId:this.order.orderId,miniappAppId:Api.APPID,openId:openId});
				if(r){
					WeixinJSBridge.invoke(
						'getBrandWCPayRequest', {
						"appId":Api.APPID,     //公众号ID，由商户传入     
						"timeStamp":r.timeStamp,         //时间戳，自1970年以来的秒数     
						"nonceStr":r.nonceStr, //随机串     
						"package":r.package,     
						"signType":r.signType,         //微信签名方式：     
						"paySign":r.paySign //微信签名 
					});  
					that.timer = that.orderCheck();
				}  
				
			},
			async createOrder(){
				if(!this.order){ 
					Toast.loading();
					let r = await Api.query("/open/order/create",{miniappAppId:Api.APPID,orderType:'cv',platform:'mobile',cvId:this.cvId});
					Toast.clear();
					if(!r){
						Toast.fail('下单错误');
					}else{ 
						this.order = r;  
					}
				}  
			}, 
			async goVip(){
				this.$router.push({path:"/vip/index"});
			},
			close(){
				this.$emit('close');
			}
		}
	}
</script>

<style lang="less" scoped>
	.ordercv{
		float:left;
		width:100%;
		//padding-bottom:40px;
		position:relative;
		.title{
			float:left;
			width:100%;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:14px;
			color:#A5A4A4;
			border-bottom:1px solid #F5F5F5;
			position:relative; 
			background:#fff;
			border-radius:5px 5px 0px 0px;
			.tabs{
				position:absolute;
				left:0px;
				bottom:0px;
				width:calc(100% - 60px);
				height:40px;
				.tab{
					float:left;
					width:calc(100% /3);
					height:40px;
					line-height:40px; 
					text-align:center;
					 position:relative;
					 .barc{
						 width:20px;
						 height:2px;
						 background:#fff;
						 position:absolute;
						 left:calc(50% - 10px);
						 bottom:0px;
						 
					 }
				}
				.vip{
					color:#ff3a22;
					font-weight: bold;
					font-size:14px;
				}
				.active{
					color:#1261ff;
					.barc{
						background:#1261ff;
					}
				}
				 
			}
			.close{
				position:absolute;
				left:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
			.sure{
				position:absolute;
				right:10px;
				top:0px;
				height:40px;
				width:40px;
				display: flex;
				align-items: center;
				justify-content: center;
			} 
		} 
	}
	.main{
		float:left;
		width:100%;
		.tips{
			float:left;
			width:100%;
			padding:10px;
			background:#fff;
			border-top:1px solid #f5f5f5;
			.tip{
				float:left;
				width:100%;
				line-height:24px;
			}
		}
		.info{
			float:left;
			width:100%;
			padding:5px 10px 5px 10px;
			background:#fff;
			.coverImage{
				float:left;
				width:90px;
				height:90px;
				display: flex;
				align-items: center;
				justify-content: center;
				.img{
					width:100%;
					height:100%;
					border-radius:5px;
				}
			}
			.name{
				float:left;
				width:calc(100% - 90px);
				padding-left:10px;
				height:30px;
				line-height:30px;
				font-size:14px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.price{
				float:left;
				width:calc(100% - 90px);
				padding-left:10px;
				height:30px;
				line-height:30px;
				.n{
					float:left;
					width:70px;
					height:30px;
					line-height:30px;
					color:#808080;
				}
				.v{
					float:left;
					width:calc(100% - 70px);
					height:30px; 
					line-height:30px;
					color:#007aff;
				}
			}
			.tip{
				float:left;
				width:calc(100% - 90px);
				padding-left:10px;
				height:30px;
				line-height:30px;
				color:#808080;
				font-size:12px;
				.n{
					float:left;  
					height:30px; 
					line-height:30px;
				}
				.v{
					float:left;
					width:calc(100% - 40px);
					height:30px;  
				}
			}
		}
		.real{
			float:left;
			width:100%;
			height:40px;
			background:#fff;
			border-top:1px solid #f5f5f5;
			.left{
				float:left;
				width:50%;
				height:40px;
				line-height:40px;
				padding-left:20px;
				font-size:14px;
				font-weight:bold;
				color:#A5A5A5;
			}
			.right{
				float:left;
				width:50%;
				height:40px;
				line-height:40px;
				padding-right:20px;
				text-align:right;
				color:#1261ff;
			}
		}
		.pay{
			float:left;
			width:100%;
			height:60px;
			background:#fff;
			display: flex;
			align-items: center;
			justify-content: center; 
			.order{
				float:left;
				width:240px;
				border-radius:15px;
				height:30px;
				background:#1261ff;
				color:#fff;
				line-height:30px;
				text-align:center;
			} 
		}
		
	}
</style>
